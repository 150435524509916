import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { rootReducer } from "./reducers/rootReducer";


 

export const ConfigureStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(
      //applyMiddleware(thunk)
      applyMiddleware(thunk, logger)
    )
  );
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers/rootReducer", () => store.replaceReducer(rootReducer));
  }

  return store;
};
