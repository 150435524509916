import React from "react";
import { Row, Col } from "reactstrap";
import { PersianToday, PersianDayName } from "../shared/persianToday";
const footer = (props) => {
  return (
    <div className="footer d-print-none">
      <Row className="dir-rtl text-center">
        <Col md={2}>نام :{props.userInfo && props.userInfo.userName}</Col>
        <Col md={2}>دوره مالی 1401</Col>
        <Col md={1}></Col>
        <Col md={2}>سرور:192.168.50.21</Col>
        <Col md={2}>بانک اطلاعاتی:SnowPark</Col>
        <Col md={1}>
          {new Date().toLocaleTimeString("fa-IR", {
            timeStyle: "short",
          })}
        </Col>
        <Col md={2}>
          {PersianToday} |{PersianDayName}{" "}
        </Col>
      </Row>
    </div>
  );
};
export default footer;
