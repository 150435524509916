import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  Alert,
  FormGroup,
  Input,
  Label,
  Button,
  Container,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { PersianCalender } from "../persianCalender";
import { baseUrl } from "../../shared/appContact";
import { PersianToday, defaultValueToday } from "../../shared/persianToday";
import { Loading } from "../LoadingComponent";
import NumberFormat from "react-number-format";
import { ErrorMsg } from "../../redux/ActionCreators";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import RecursiveTreeView from "../treeView";

function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function CashTurnDetail(props) {
  const branches = useSelector((state) => state.branches);
  const accounts = useSelector((state) => state.accounts);
  const userInfo = useSelector((state) => state.userInfo);
  const cashInfo = useSelector((state) => state.cashInfo);
  const [cashTurnDetail, setCashTurnDetail] = useState({
    CashTurnId: cashInfo === null ? -1 : cashInfo.cashInfo.cashTurnId,
    FarsiTransactionDate: PersianToday,
    PayedValue: 0,
    ObjectId1: null,
    ObjectId2: null,
    ObjectId3: null,
    ClassId1: null,
    ClassId2: null,
    ClassId3: null,
    UserId: userInfo.userInfo.userId,
    RelatedAccountId: -1,
    formtypeid: 205,
    Description: "",
    branchId: branches.lenght === 0 ? null : branches.branches[0].id,
  });
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [objectOne, setObjectOne] = useState([]);
  const [objectTwo, setObjectTwo] = useState([]);
  const [objectThree, setObjectThree] = useState([]);
  const [objectLabel, setObjectLabel] = useState([]);
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const lastCashTurnDetail = useRef({
    cashTurnDetail,
  });
  useEffect(() => {
    if (
      ~~lastCashTurnDetail.current.cashTurnDetail.RelatedAccountId !==
      ~~cashTurnDetail.RelatedAccountId
    ) {
      handleGetObjects(~~cashTurnDetail.RelatedAccountId);
      lastCashTurnDetail.current.cashTurnDetail = cashTurnDetail;
    }
  }, [cashTurnDetail]);
  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setCashTurnDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleSaveCashTrun() {
    if (cashInfo.cashInfo === null) {
      return;
    }
    if (cashInfo.cashInfo.cashTurn === -1) {
      return;
    }
    var payedValue = cashTurnDetail.PayedValue;
    if (payedValue.toString().indexOf(",") > -1) {
      payedValue = payedValue.replace(/,/g, "");
    }
    var Errmsg = [""];
    if (!payedValue) {
      Errmsg.push(<p>مبلغ قابل پرداخت را وارد نمایید!</p>);
      document.getElementById("PayedValue").focus();
    }
    if (cashTurnDetail.RelatedAccountId === -1) {
      Errmsg.push(<p>نوع حساب را مشخص نمایید!</p>);
    }
    for (var i = 0; i < objectLabel.length; i++) {
      var objName = "ObjectId" + (i + 1);
      if (cashTurnDetail[objName] === null) {
        Errmsg.push(<p>نوع {objectLabel[i].name} را مشخص نمایید!</p>);
      }
    }

    // if (cashTurnDetail.ObjectId1 === -1) {
    //     Errmsg.push(<p>نوع تفضیل را مشخص نمایید!</p>);
    //     document.getElementById("ObjectId1").focus();
    // }
    if (Errmsg.length > 1) {
      setNotify({
        color: "error",
        hideDuratuion: 9000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const CashTurnDetail = {
        CashTurnId: ~~cashTurnDetail.CashTurnId,
        FarsiTransactionDate: cashTurnDetail.FarsiTransactionDate,
        PayedValue: parseFloat(payedValue),
        ObjectId1: ~~cashTurnDetail.ObjectId1,
        ObjectId2: ~~cashTurnDetail.ObjectId2,
        ObjectId3: ~~cashTurnDetail.ObjectId3,
        UserId: ~~cashTurnDetail.UserId,
        RelatedAccountId: ~~cashTurnDetail.RelatedAccountId,
        formtypeid: 205,
        Description: cashTurnDetail.Description,
        ClassId1: ~~cashTurnDetail.ClassId1,
        ClassId2: ~~cashTurnDetail.ClassId2,
        ClassId3: ~~cashTurnDetail.ClassId3,
        branchId: ~~cashTurnDetail.branchId,
      };
      const newModel = {
        CashTurnDetail: CashTurnDetail,
        TerminalId: null,
        RRN: "",
      };
      fetch(baseUrl + "Cash/SaveRecPayCashDetail", {
        method: "POST",
        body: JSON.stringify(newModel),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setLoading(false);
          setId(response.id);
          setNotify({
            color: "success",
            hideDuratuion: 9000,
            msg: "عملیات با موفقیت انجام شد!",
            open: true,
            vertical: "top",
            horizontal: "center",
          });
        })
        .catch((error) => {
          setLoading(false);

          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  //دریافت اطلاعات تفضیلی
  function handleGetObjects(accountId) {
    if (accountId === -1) {
      setObjectLabel([]);
      setObjectOne([]);
      setObjectTwo([]);
      setObjectThree([]);
      return;
    }
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      fetch(baseUrl + "AccountDocument/GetObjects/" + accountId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setLoading(false);

          setObjectOne(response.objectOne);
          setObjectTwo(response.objectTwo);
          setObjectThree(response.objectThree);
          setTimeout(() => {
            setObjectLabel(response.objectName);
          }, 500);
        })
        .catch((error) => {
          setLoading(false);

          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  //انتخاب حساب و دریافت تفضیلی های مربوطهselectedAccount
  function selectedAccountId(accountId) {
    //alert(accountId)
    handleGetObjects(accountId);
    setCashTurnDetail((prevState) => ({
      ...prevState,
      RelatedAccountId: ~~accountId,
    }));
  }
  //ست کردن تفضیلی
  function handleSetObjects(objectId, obj) {
    var objectName = "ObjectId" + obj.sequence.toString();
    var className = "ClassId" + obj.sequence.toString();
    var classVal = obj.classId;
    setCashTurnDetail((prevState) => ({
      ...prevState,
      [objectName]: objectId,
      [className]: classVal,
    }));
  }
  return (
    <div className="fgnonmb">
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <NotifyAlert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </NotifyAlert>
      </Snackbar>
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/8">مالی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>پرداخت نقدی</BreadcrumbItem>
      </Breadcrumb>
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <small>فرم </small>
            <strong>پرداخت نقدی</strong>
          </CardHeader>
          <CardBody className="text-right">
            <Alert
              color="danger"
              className={
                cashInfo.cashInfo === null
                  ? ""
                  : cashInfo.cashInfo.cashTurn === -1
                  ? ""
                  : "hidden"
              }
            >
              جهت ثبت فاکتور میبایست نوبت صندوق را باز نمایید
            </Alert>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="Id">شماره رایانه ای</Label>
                  <Input type="text" id="Id" name="Id" value={id} readOnly />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="cashId">نام صندوق </Label>
                  <Input
                    type="text"
                    id="cashId"
                    name="cashId"
                    value={
                      cashInfo.cashInfo === null
                        ? ""
                        : cashInfo.cashInfo.cashName
                    }
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="cashTurn">نوبت صندوق</Label>
                  <Input
                    type="text"
                    id="cashTurn"
                    name="cashTurn"
                    value={
                      cashInfo.cashInfo === null
                        ? -1
                        : cashInfo.cashInfo.cashTurn
                    }
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>تاریخ</Label>
                  <PersianCalender
                    defaultValue={defaultValueToday}
                    onChange={(FromFarsiDate) => {
                      setCashTurnDetail((prevState) => ({
                        ...prevState,
                        FarsiTransactionDate: FromFarsiDate,
                      }));
                    }}
                  ></PersianCalender>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="PayedValue">مبلغ پرداخت</Label>
                  <NumberFormat
                    className="form-control"
                    value={cashTurnDetail.PayedValue}
                    id="PayedValue"
                    name="PayedValue"
                    displayType={"input"}
                    thousandSeparator={true}
                    prefix={""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="RelatedAccountId">حساب</Label>
                  <RecursiveTreeView
                    selectedId={selectedAccountId}
                    data={accounts.accounts}
                  ></RecursiveTreeView>

                  {/* <Input
                                        type="select"
                                        id="RelatedAccountId"
                                        name="RelatedAccountId"
                                        value={cashTurnDetail.obj}
                                        onChange={handleInputChange}
                                    >
                                        <option value="-1">--انتخاب نمایید--</option>
                                        {props.accounts.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.id}>{obj.title}</option>
                                            );
                                        })}
                                    </Input> */}
                </FormGroup>
              </Col>
              {/* <Col md={4}>
                                <FormGroup>
                                    <Label htmlFor="objectId">تفصیل</Label>
                                    <Input type="select" id="ObjectId1" name="ObjectId1" value={cashTurnDetail.ObjectId1} onChange={handleInputChange} >
                                        <option value="-1">--انتخاب نمایید--</option>
                                         {objectList.map((obj, index) => {
                                            return (
                                                <option key={index} value={obj.id}>{obj.name}</option>
                                                // <option value={obj.id}>{obj.bank.description} -کد: {obj.bank.code}</option>
                                            );
                                        })} 
                                    </Input>
                                </FormGroup>
                            </Col> */}

              {objectLabel.map((obj, index) => {
                return (
                  <Col md={4} key={index}>
                    <FormGroup>
                      <Label htmlFor="objectId">{obj.name}</Label>

                      <RecursiveTreeView
                        selectedId={(id) => handleSetObjects(id, obj)}
                        data={
                          obj.sequence === 1
                            ? objectOne
                            : obj.sequence === 2
                            ? objectTwo
                            : objectThree
                        }
                      ></RecursiveTreeView>

                      {/* <Input type="select" id={"ObjectId" + obj.sequence} name={"ObjectId" + obj.sequence} value={cashTurnDetail.ObjectId + obj.sequence} onChange={handleInputChange} >
                                                <option value="-1">--انتخاب نمایید--</option>

                                            </Input> */}
                    </FormGroup>
                  </Col>
                );
              })}

              <Col md={4}>
                <FormGroup>
                  <Label>شعبه</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="branchId"
                    id="branchId"
                    value={cashTurnDetail.branchId}
                    onChange={handleInputChange}
                  >
                    {branches.branches.map((branch, index) => {
                      return (
                        <option key={index} value={branch.id}>
                          {branch.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="Description">توضیحات</Label>
                  <Input
                    type="textarea"
                    id="Description"
                    name="Description"
                    value={cashTurnDetail.Description}
                    onChange={handleInputChange}
                  ></Input>
                </FormGroup>
              </Col>

              <Col md={12} className="my-auto">
                <Button
                  type="button"
                  color="primary"
                  className="m-3"
                  onClick={handleSaveCashTrun}
                >
                  ثبت{" "}
                </Button>
                <Link
                  className="btn btn-info m-3"
                  to="/9/saleInvoice"
                  onClick={() => props.selectedFormP("9/saleInvoice")}
                >
                  فاکتور فروش
                </Link>
                <Link
                  className="btn btn-danger m-3"
                  to="/8/cashTurn"
                  onClick={() => props.selectedFormP("8/cashTurn")}
                >
                  نوبت صندوق
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <LoadingCashTurnDetail loading={loading} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
function LoadingCashTurnDetail({ loading }) {
  if (loading) {
    return (
      <Row className="justify-content-md-center">
        <Col md={4}>
          <Loading />
        </Col>
      </Row>
    );
  }
  return <></>;
}
export default CashTurnDetail;
