import * as ActionTypes from "../ActionTypes";

export const DefaultIsCash = (
  state = {
    isLoading: false,
    errMess: null,
    defaultIsCash: true,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_DEFAULT_IS_CASH:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        defaultIsCash: action.payload,
      };

    case ActionTypes.DEFAULTPROMETERUSER_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        defaultIsCash: false,
      };

    case ActionTypes.DEFAULTPROMETERUSER_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
