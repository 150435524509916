import * as ActionTypes from '../ActionTypes';

export const UserInfo = (state = {
    isLoading: true,
    errMess: null,
    userInfo: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_USERINFO:
            return { ...state, isLoading: false, errMess: null, userInfo: action.payload };

        case ActionTypes.USERINFO_LOADING:
            return { ...state, isLoading: true, errMess: null, userInfo: [] }

        case ActionTypes.USERINFO_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};