import React from 'react';
import { Row, Col, FormGroup, Input, Alert } from 'reactstrap';
import NumberFormat from 'react-number-format';

export function totalretailPrice(goodList) {
  //جمع کل فاکتور=قیمت * تعداد
  const res = goodList.reduce((totalP, m) => totalP + (parseFloat(m.retailPrice) * m.qty), 0)
  return res;
}
export function totalInlineDiscount(goodList) {
  //مجموع تخفیفات سطری
  const res = goodList.reduce((totalP, m) => totalP + (parseFloat(m.discountValue) * m.qty), 0)
  return res;
}
export function totalDiscount(goodList, discountValue) {
  // مجموع تخفیفات سطری + تخفیف کلی
  const res = goodList.reduce((totalP, m) => totalP + (parseFloat(m.discountValue) * m.qty), 0)
  const result = parseFloat(res) + parseFloat(discountValue)
  return result;
}
export function totalPriceWDiscount(goodList) {
  //جمع کل فاکتور=قیمت * تعداد
  const allPrice = goodList.reduce((totalP, m) => totalP + (parseFloat(m.retailPrice) * m.qty), 0)
  //مجموع تخفیفات
  const AlldiscountValue = goodList.reduce((totalP, m) => totalP + (parseFloat(m.discountValue) * m.qty), 0)
  //جمع کل فاکتور-مجموع تخفیفات
  const res = allPrice - AlldiscountValue;
  return res;

}
export function totalTaxAndAdded(goodList) {
  //مجموع مالیات و عوارض
  const res = goodList.reduce((totalP, m) => totalP + (parseFloat(m.totalTaxAddedValue)), 0)

  return res;
}
//مبلغ قابل پرداخت
export function totalPayedValue(goodList, discountValue) {
  console.log({goodList,discountValue,total:totalTaxAndAdded(goodList) + totalPriceWDiscount(goodList)})
  return totalTaxAndAdded(goodList) + totalPriceWDiscount(goodList) - discountValue;
}



const InvoiceFooter = ({
  discountPercent,
  discountValue,
  goodList,
  disabled,
  permOfChangeAllDiscountValue,
  setOnChang }) => {


  return (
    <div>
      <Row>

        <Col md={2} sm={10} lg={2}>

          <div htmlFor="totalretailPrice" className="text-left">جمع کل:</div>
        </Col>
        <Col md={2} sm={10} lg={2}>

          <FormGroup >
            <NumberFormat value={totalretailPrice(goodList)} displayType={'text'} thousandSeparator={true} prefix={''} />
          </FormGroup>
        </Col>

        <Col md={2} sm={10} lg={2}>

          <div htmlFor="totalInlineDiscount" className="text-left">تخفیف فاکتور (سطری):</div>
        </Col>
        <Col md={2} sm={10} lg={2}>

          <FormGroup >
            <NumberFormat value={totalInlineDiscount(goodList)} displayType={'text'} thousandSeparator={true} prefix={''} />
          </FormGroup>
        </Col>

        <Col md={2} sm={10} lg={2}>
          <div htmlFor="totalPriceWDiscount" className="text-left">جمع کل با تخفیف:</div>
        </Col>
        <Col md={2} sm={10} lg={2}>

          <FormGroup >
            <NumberFormat value={totalPriceWDiscount(goodList)} displayType={'text'} thousandSeparator={true} prefix={''} />
          </FormGroup>
        </Col>

      </Row>
      <Row className='justify-content-end mt-2'>
        <Col md={2} sm={10} lg={2}>

          <div htmlFor="discountPercent" className="text-left">درصد تخفیف کلی:</div>
        </Col>
        <Col md={2} sm={10} lg={2}>

          <FormGroup >

            <Input
              disabled={disabled && !permOfChangeAllDiscountValue}
              type="number"
              min="0"
              max="100"
              className={totalPriceWDiscount(goodList) > 0 ? "form-control" : "hidden"}
              value={discountPercent}
              name="discountPercent"
              onChange={(event) => {
                debugger;
                let tpWD = totalPriceWDiscount(goodList);
                let dp = parseFloat(event.target.value);
                let dv = (dp / 100) * tpWD;
                let newDiscountPercent = Math.round(dp * 100) / 100;
                let newDiscountValue = Math.round(~~dv / 1000) * 1000;
                setOnChang(newDiscountPercent, newDiscountValue)
              }}
            />
          </FormGroup>
        </Col>
        <Col md={2} sm={10} lg={2}>

          <div htmlFor="discountValue" className="text-left">مبلغ تخفیف کلی:</div>
        </Col>
        <Col md={2} sm={10} lg={2}>

          <FormGroup >
            <NumberFormat
              disabled={disabled && !permOfChangeAllDiscountValue}
              max={totalPriceWDiscount(goodList)}
              thousandSeparator={true}
              className={totalPriceWDiscount(goodList) > 0 ? "form-control" : "hidden"}
              inputMode="numeric"
              value={discountValue}
              onChange={(e) => {

                let dv = e.target.value;

                if (dv.toString().indexOf(',') > -1) {
                  dv = dv.replace(/,/g, '');
                }
                let tpWD = totalPriceWDiscount(goodList);

                let dp = (~~dv * 100) / tpWD;
                let newDiscountPercent = Math.round(dp * 100) / 100;
                let newDiscountValue = (dv / 1000) * 1000;
                setOnChang(newDiscountPercent, newDiscountValue)

              }} />
          </FormGroup>
        </Col>
        <Col md={2} sm={10} lg={2} >
          <div htmlFor="totalTaxAndAdded" className="text-left">مالیات:</div>
        </Col>
        <Col md={2} sm={10} lg={2} >
          <FormGroup >
            <NumberFormat value={totalTaxAndAdded(goodList)} displayType={'text'} thousandSeparator={true} prefix={''} />
          </FormGroup>
        </Col>
      </Row>

      <Row className='justify-content-end mt-2'>
        <Col md="4">
          <Alert color="info">
            <Row>
              <Col md={6} sm={10} lg={6} >
                <div htmlFor="totalDiscount" className="text-left">جمع تخفیفات:</div>
              </Col>
              <Col md={6} sm={10} lg={6} >
                <FormGroup >
                  <NumberFormat value={totalDiscount(goodList, discountValue)} displayType={'text'} thousandSeparator={true} prefix={''} />
                </FormGroup>
              </Col>

            </Row>
          </Alert>
        </Col>
        <Col md="4">
          <Alert color="info">
            <Row>

              <Col md={6} sm={10} lg={6} >
                <div htmlFor="totalPayedValue" className="text-left">مبلغ قابل پرداخت:</div>
              </Col>
              <Col md={6} sm={10} lg={6} >
                <FormGroup >
                  <NumberFormat value={totalPayedValue(goodList, discountValue)} displayType={'text'} thousandSeparator={true} prefix={''} />
                </FormGroup>
              </Col>
            </Row>
          </Alert>
        </Col>



      </Row>

    </div>
  )
}

export default InvoiceFooter;
