import React from 'react';
import { Container, Row, Col, Table, Card, CardHeader, CardBody } from 'reactstrap';

export default function LastEvents() {
  return (
    <div className="fgnonmb">
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <strong>گزارش آخرین رویدادها</strong>
          </CardHeader>
          <CardBody className="text-right">
            <Row>
              <Col md={12}>
                <Table hover>
                  <thead>
                    <tr>
                      <th scope="row">شرح</th>
                      <th>تاریخ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td >گزارش فاکتور فروش</td>
                      <td>1399/08/05 11:20 AM</td>
                    </tr>
                    <tr>
                      <td >گزارش فاکتور فروش</td>
                      <td>1399/08/05 11:10 AM</td>
                    </tr>
                    <tr>
                      <td >گزارش فاکتور فروش</td>
                      <td>1399/08/05 11:06 AM</td>
                    </tr>
                    <tr>
                      <td> گزارش فاکتور فروش  </td>
                      <td>1399/08/05 10:56 AM</td>
                    </tr>
                    <tr>
                      <td >ورود به سیستم مالی</td>
                      <td>1399/08/05 10:50 AM</td>
                    </tr>
                    <tr>
                      <td >ایجاد فاکتور فروش 109455</td>
                      <td>1399/08/05 01:36 AM</td>
                    </tr>
                    <tr>
                      <td >جستجوی کالا </td>
                      <td>1399/08/05 01:27 AM</td>
                    </tr>
                    <tr>
                      <td >ورود به سیستم بازرگانی</td>
                      <td>1399/08/05 01:47 AM</td>
                    </tr>
                    <tr>
                      <td >سیستم اطلاعات پایه فعال میباشد</td>
                      <td>1399/08/05 11:50 AM</td>
                    </tr>
                    <tr>
                      <td >سیستم اداری فعال میباشد</td>
                      <td>1399/08/05 11:50 AM</td>
                    </tr>
                    <tr>
                      <td >سیستم مدیریت گردش کار فعال میباشد</td>
                      <td>1399/08/05 11:50 AM</td>
                    </tr>
                    <tr>
                      <td >سیستم مالی فعال میباشد</td>
                      <td>1399/08/05 11:50 AM</td>
                    </tr>
                    <tr>
                      <td >سیستم بازرگانی فعال میباشد</td>
                      <td>1399/08/05 11:50 AM</td>
                    </tr>
                    <tr>
                      <td >سیستم منابع انسانی فعال میباشد</td>
                      <td>1399/08/05 11:50 AM</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>

        </Card>
      </Container>
    </div >
  );
}
