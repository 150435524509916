import React, { useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Home from "./Home";
import Header from "./Header";
import Footer from "./footer";
import SideBar from "./SideBar";
import CashTurn from "./cash/cashTurn";
import SaleInvoice from "./sale/SaleInvoice";
import SaleInvoiceSearch from "./sale/SaleInvoiceSearch";
import SaleReturnSearch from "./sale/SaleReturnSearch";
import SaleReturn from "./sale/SaleReturn";
import SystemHome from "./system-Home";
import Login from "./login1";
import CashTurnDetail from "./cash/cashTurnDetail";
import CashTurnFlow from "./cash/cashTurnFlow";
import Organization from "./Basic/organization";
import Emploee from "./Basic/emploee";
import EmploeeSearch from "./Basic/emploeeSearch";
import OrganizationSearch from "./Basic/organizationSearch";
import GoodType from "./Basic/goodTypes";
import Rolepage from "./Basic/rolepage";
import AccountDetail from "./Account/accountDetail";

import { postLogin, postAuth } from "../redux/ActionCreators";
import { ActiveMenuContext } from "./activeMenuContext";

export default function Main() {
  const [, setActiveMenu] = useContext(ActiveMenuContext);

  const auth = useSelector((state) => state.auth);
  const login = useSelector((state) => state.login);
  const userInfo = useSelector((state) => state.userInfo);
  const roles = useSelector((state) => state.roles);
  const systemGroups = useSelector((state) => state.systemGroups);
  const inventoryUser = useSelector((state) => state.inventoryUser);
  const pricingmethod = useSelector((state) => state.pricingmethod);
  const salePrecondition = useSelector((state) => state.salePrecondition);
  const branches = useSelector((state) => state.branches);
  const goodTypes = useSelector((state) => state.goodTypes);
  const cashInfo = useSelector((state) => state.cashInfo);
  const defaultOrgUser = useSelector((state) => state.defaultOrgUser);
  const defaultPoromterUser = useSelector((state) => state.defaultPoromterUser);
  const saleInvoiceSrchRes = useSelector((state) => state.saleInvoiceSrchRes);
  const companyInfo = useSelector((state) => state.companyInfo);
  const systemSetting = useSelector((state) => state.systemSetting);
  const invoiceType = useSelector((state) => state.invoiceType);
  const poses = useSelector((state) => state.poses);
  const { defaultIsFormal } = useSelector((state) => state.defaultIsFormal);
  const { defaultIsCash } = useSelector((state) => state.defaultIsCash);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (auth.auth.length === 0 && auth.errMess === null && !auth.isLoading) {
      dispatch(postAuth());
    }
  }, [auth.auth, auth.errMess, auth.isLoading]);

  //وقتی از یک صفحه لینک میدیم به صفحه دیگه منوی بغل ست بشه با صفحه ی جدید
  function selectedFormP(component) {
    let systemG = systemGroups.systemGroups;
    for (var i = 0; i < systemG.length; i++) {
      if (systemG[i].systems) {
        let systems = systemG[i].systems;
        for (var j = 0; j < systems.length; j++) {
          if (systems[j].rolePermissions) {
            let rolePermissions = systems[j].rolePermissions;
            for (var z = 0; z < rolePermissions.length; z++) {
              if (rolePermissions[z].formPermissions) {
                let formPermissions = rolePermissions[z].formPermissions;
                for (var k = 0; k < formPermissions.length; k++) {
                  if (formPermissions[k].component === component) {
                    setActiveMenu({
                      systemGId: systemG[i].id,
                      systemId: systems[j].id,
                      rolePermId: rolePermissions[z].id,
                      formPermId: formPermissions[k].id,
                    });
                    return;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //لیست فرم پرمیشن هایی که قراره به عنوان تب استفاده بشن
  function getFormpermistionList(sg, systemGId, rpId) {
    const rp = sg.systems.filter((m) => m.id === systemGId)[0];
    return rp.rolePermissions.filter((m) => m.id === rpId)[0].formPermissions;
  }

  //#region login successful
  const token = localStorage.token;

  if (login.errMess === null && roles.roles.length > 0 && token) {
    const HomePage = () => {
      return <Home roles={roles} userInfo={userInfo.userInfo} />;
    };
    return (
      <Container fluid={true} className="h-100">
        <Header />

        <div className="h-100">
          <div className="row dir-rtl brdertop h-100">
            <div className="col-12 main">
              <SideBar selectedFormP={selectedFormP} />
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="page"
                  timeout={300}
                >
                  <Switch location={location}>
                    <Route path="/home" component={HomePage} />
                    <Route exact path="/1" component={() => <SystemHome />} />
                    <Route
                      exact
                      path="/1/organization"
                      component={() => <Organization />}
                    />
                    <Route
                      exact
                      path="/1/orgSearch"
                      component={() => <OrganizationSearch />}
                    />
                    <Route
                      exact
                      path="/1/emploee"
                      component={() => <Emploee />}
                    />
                    <Route
                      exact
                      path="/3/accountDetail"
                      component={() => (
                        <AccountDetail
                          defaultOrgUser={defaultOrgUser.defaultOrgUser}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/1/emploeeSearch"
                      component={() => <EmploeeSearch />}
                    />
                    <Route
                      exact
                      path="/1/goodtype"
                      component={() => (
                        <GoodType goodTypes={goodTypes.goodtypes} />
                      )}
                    />
                    <Route
                      exact
                      path="/1/rolepage"
                      component={() => <Rolepage />}
                    />

                    <Route exact path="/3" component={() => <SystemHome />} />

                    <Route exact path="/7" component={() => <SystemHome />} />
                    <Route exact path="/8" component={() => <SystemHome />} />
                    <Route
                      exact
                      path="/8/cashTurnDetail"
                      component={() => (
                        <CashTurnDetail selectedFormP={selectedFormP} />
                      )}
                    />

                    <Route
                      exact
                      path="/8/cashTurn"
                      component={() => (
                        <CashTurn selectedFormP={selectedFormP} />
                      )}
                    />
                    <Route
                      exact
                      path="/8/cashTurnFlow"
                      component={() => <CashTurnFlow />}
                    />

                    <Route exact path="/9" component={() => <SystemHome />} />

                    <Route
                      exact
                      path="/9/saleInvoice"
                      component={() => (
                        <SaleInvoice
                          selectedFormP={selectedFormP}
                          pricingmethod={pricingmethod.pricingmethod}
                          inventoryUser={inventoryUser.inventoryUser}
                          salePrecondition={salePrecondition.salePrecondition}
                          formPerms={getFormpermistionList(
                            systemGroups.systemGroups.filter(
                              (systemG) => systemG.id === 9
                            )[0],
                            9,
                            901
                          )}
                          invoiceType={invoiceType.invoiceType}
                          systemSetting={systemSetting.systemSetting}
                          branches={branches.branches}
                          companyInfo={companyInfo.companyInfo}
                          goodTypes={goodTypes.goodtypes}
                          role={roles.roles[0]}
                          userInfo={userInfo.userInfo}
                          cashInfo={cashInfo.cashInfo}
                          defaultOrgUser={defaultOrgUser.defaultOrgUser}
                          defaultPoromterUser={
                            defaultPoromterUser.defaultPoromterUser
                          }
                          defaultIsFormal={defaultIsFormal}
                          defaultIsCash={defaultIsCash}
                          poses={poses}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/9/saleInvoiceSearch"
                      component={() => (
                        <SaleInvoiceSearch
                          selectedFormP={selectedFormP}
                          pricingmethod={pricingmethod.pricingmethod}
                          saleInvoiceSrchRes={
                            saleInvoiceSrchRes.saleInvoiceSrchRes
                          }
                          userInfo={userInfo.userInfo}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/9/saleReturn"
                      component={() => (
                        <SaleReturn
                          selectedFormP={selectedFormP}
                          pricingmethod={pricingmethod.pricingmethod}
                          inventoryUser={inventoryUser.inventoryUser}
                          salePrecondition={salePrecondition.salePrecondition}
                          formPerms={getFormpermistionList(
                            systemGroups.systemGroups.filter(
                              (systemG) => systemG.id === 9
                            )[0],
                            9,
                            903
                          )}
                          invoiceType={invoiceType.invoiceType}
                          systemSetting={systemSetting.systemSetting}
                          branches={branches.branches}
                          companyInfo={companyInfo.companyInfo}
                          goodTypes={goodTypes.goodtypes}
                          role={roles.roles[0]}
                          userInfo={userInfo.userInfo}
                          cashInfo={cashInfo.cashInfo}
                          defaultOrgUser={defaultOrgUser.defaultOrgUser}
                          defaultPoromterUser={
                            defaultPoromterUser.defaultPoromterUser
                          }
                          poses={poses}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/9/saleReturnSearch"
                      component={() => (
                        <SaleReturnSearch
                          selectedFormP={selectedFormP}
                          pricingmethod={pricingmethod.pricingmethod}
                          saleInvoiceSrchRes={
                            saleInvoiceSrchRes.saleInvoiceSrchRes
                          }
                          userInfo={userInfo.userInfo}
                        />
                      )}
                    />

                    <Route exact path="/10" component={() => <SystemHome />} />
                    <Redirect to="/home" />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </div>
        </div>
        <Footer userInfo={userInfo.userInfo} />
      </Container>
    );
  }
  //#endregion
  //#region login page
  else {
    return (
      <Container fluid={true} className="logintem h-100">
        <Row className="h-100">
          <Col md={12} lg={12}>
            <Container className="h-100 ">
              <Row className="h-100 ">
                <Col md={12} lg={12} className="loginbg my-15">
                  <Login
                    postLogin={(Username, Password) =>
                      dispatch(postLogin(Username, Password))
                    }
                    errMess={login.errMess}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}
