import React from 'react';
import { Progress } from 'reactstrap';

const ProgressBars = (props) => {
  return (
    <div>
      <div className="text-center"> اطلاعات پایه 45%</div>
      <Progress value="45" color="success" />

      <div className="text-center mt-3"> اداری 25%</div>
      <Progress value="25" />

      <div className="text-center mt-3"> مدیریت گردش کار 50%</div>
      <Progress color="info" value={50} />

      <div className="text-center mt-3">مالی 75%</div>
      <Progress color="warning" value={75} />

      <div className="text-center mt-3">بازرگانی 100%</div>
      <Progress color="danger" value="100" />

      <div className="text-center mt-3">مجموع فعالیت ها </div>
      <Progress multi>
        <Progress bar color="info" value="25" />
        <Progress bar color="warning" value="40" />
        <Progress bar color="danger" value="65" />
      </Progress>
    </div>
  );
};

export default ProgressBars;