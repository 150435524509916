import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Button,
  Label,
  Input,
  FormGroup,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { PersianCalender } from "../persianCalender";
import { PersianToday, defaultValueToday } from "../../shared/persianToday";
import axios from "axios";
import { baseUrl } from "../../shared/appContact";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import NumberFormat from "react-number-format";
import { Loading } from "../LoadingComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ErrorMsg } from "../../redux/ActionCreators";

function Miulert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function SaleReturnRef(props) {
  const [disabled, setDisabled] = useState(false);
  const [oldCashTurn, setOldCashTurn] = useState(null);
  const [tblSaleReturn, setTblSaleReturn] = useState({
    id: 0,
    code: 0,
    farsiCreatDate: PersianToday,
    isPaidByCash: true,
    notes: "",
  });
  const [tblSaleInvoice, setTblSaleInvoice] = useState({
    id: 0,
    code: 0,
    farsiCreatDate: "",
    promoterId: -1,
    promoterName: "",
    customerId: -1,
    customerName: "",
    branchId: -1,
    branchName: "",
    inventoryId: -1,
    inventoryName: "",
    typeId: 1,
    isFormal: false,
    discountPercent: 0,
    discountValue: 0,
  });
  const [inventoryInfoShow, setInventoryInfoShow] = useState(true);
  const [goodList, setGoodList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialogReturn, setOpenDialogReturn] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [defaultValuePerCal, setDefaultValuePerCal] =
    useState(defaultValueToday);
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const lastTblSaleReturn = useRef({ tblSaleReturn });
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      const id = localStorage.saleReturnId;
      if (id) {
        hanleFetchSaleReturn(~~id, (response) => {
          var resDate = response.split("/");
          var defualtValDate = {
            year: ~~resDate[0],
            month: ~~resDate[1],
            day: ~~resDate[2],
          };
          setDefaultValuePerCal(defualtValDate);
        });
      }
      localStorage.removeItem("saleReturnId");
    }
    if (lastTblSaleReturn.current.code !== tblSaleReturn.code) {
      if (tblSaleReturn.code > 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      lastTblSaleReturn.current = tblSaleReturn;
      isFirstRun.current = false;
    }
  }, [tblSaleReturn, setDisabled]);
  //handle Change tblSaleInvoice
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTblSaleInvoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //handle Change tblSaleReturn
  const handleInputChangeTblSaleReturn = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTblSaleReturn((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //لود اطلاعات جهت ویرایش
  function handleFetchSaleInvoice(id, code) {
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      axios({
        method: "get",
        url: baseUrl + "Sale/GetSaleInvoiceInfo/" + id + "/" + code + "/903",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setLoading(false);

          response = response.data;

          //برای این فاکتور فروش برگشت از فروش خورده شده بنابر این  اطلاعات برگشت از فروش لود میشود
          if (response.statusCode === 204) {
            hanleFetchSaleReturn(response.SaleReturnId, (response) => {
              var resDate = response.split("/");
              var defualtValDate = {
                year: ~~resDate[0],
                month: ~~resDate[1],
                day: ~~resDate[2],
              };
              setDefaultValuePerCal(defualtValDate);
            });
            return true;
          }

          var invoice = {
            id: response.invoice.id,
            code: response.invoice.code === -1 ? 0 : response.invoice.code,
            farsiCreatDate: response.invoice.farsiCreateDate,
            promoterId: response.invoice.promoterId,
            promoterName: response.invoice.promoterName,
            customerId: response.invoice.customerId,
            customerName: response.invoice.customerName,
            branchId: response.invoice.branchId,
            branchName: response.invoice.branchName,
            inventoryId: response.invoice.inventoryId,
            inventoryName: response.invoice.inventoryName,
            typeId: response.invoice.typeId,
            isFormal: response.invoice.isFormal,
            discountValue: response.invoice.discountValue,
            discountPercent: response.invoice.discountPercent,
          };
          setTblSaleInvoice(invoice);

          var invoiceDetail = [];
          response.invoiceDetail.forEach((item) => {
            invoiceDetail.push({
              barcode: item.barCode,
              code: item.code,
              name: item.name,
              retailPrice: item.retailPrice,
              qty: item.qty,
              unit: item.scaleName,
              remained: item.remained,
              discountPercent: item.discountPercent,
              discountValue: item.discountValue,
              totalTaxAddedValue: item.totalTaxAddedValue,
              noneOfPrice: item.retailPrice === 0,
            });
          });
          setGoodList(invoiceDetail);
        })
        .catch((error) => {
          setLoading(false);
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: "خطا در بارگذاری اطلاعات",
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  function totalretailPrice(List) {
    //جمع کل فاکتور=قیمت * تعداد
    const res = List.reduce(
      (totalP, m) => totalP + parseFloat(m.retailPrice) * m.qty,
      0
    );
    return res;
  }
  function totalInlineDiscount(List) {
    //مجموع تخفیفات سطری
    const res = List.reduce(
      (totalP, m) => totalP + parseFloat(m.discountValue) * m.qty,
      0
    );
    return res;
  }
  function totalDiscount(List) {
    // مجموع تخفیفات سطری + تخفیف کلی
    const res = List.reduce(
      (totalP, m) => totalP + parseFloat(m.discountValue) * m.qty,
      0
    );
    const result = parseFloat(res) + parseFloat(tblSaleInvoice.discountValue);
    return result;
  }
  function totalPriceWDiscount(List) {
    //جمع کل فاکتور=قیمت * تعداد
    const allPrice = List.reduce(
      (totalP, m) => totalP + parseFloat(m.retailPrice) * m.qty,
      0
    );
    //مجموع تخفیفات
    const AlldiscountValue = List.reduce(
      (totalP, m) => totalP + parseFloat(m.discountValue) * m.qty,
      0
    );
    //جمع کل فاکتور-مجموع تخفیفات
    var res = allPrice - AlldiscountValue;
    return res;
  }
  function totalTaxAndAdded(List) {
    //مجموع مالیات و عوارض
    const res = List.reduce(
      (totalP, m) => totalP + parseFloat(m.totalTaxAddedValue),
      0
    );

    return res;
  }
  //مبلغ قابل پرداخت
  function totalPayedValue(List) {
    return (
      totalTaxAndAdded(List) +
      totalPriceWDiscount(List) -
      tblSaleInvoice.discountValue
    );
  }
  //ثبت موقت و ثبت نهایی بر گشت از فروش
  function handleFinalizeReturn(IsTemporary, IsEdit) {
    if (tblSaleReturn.isPaidByCash) {
      if (props.cashInfo === null) {
        setNotify({
          color: "error",
          hideDuratuion: 9000,
          msg: "جهت ثبت برگشت از فروش بصورت نقدی میبایست نوبت صندوق را باز نمایید",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (props.cashInfo.cashTurn === -1) {
        setNotify({
          color: "error",
          hideDuratuion: 9000,
          msg: "جهت ثبت برگشت از فروش بصورت نقدی میبایست نوبت صندوق را باز نمایید",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    }
    var Errmsg = [""];
    goodList.forEach((item) => {
      if (item.returnQty > item.qty) {
        Errmsg.push(
          <p>
            تعداد برگشت شده برای کالای {item.name} بیشتر از تعداد خریداری شده
            است
          </p>
        );
      }
      if (!item.returnQty) {
        Errmsg.push(
          <p>تعداد برگشت شده برای کالای {item.name} مشخص نشده است</p>
        );
      }
    });

    if (Errmsg.length > 1) {
      setNotify({
        color: "error",
        hideDuratuion: 9000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const TblSaleReturn = {
        Id: tblSaleReturn.id,
        Code: tblSaleReturn.code,
        CustomerId: ~~tblSaleInvoice.customerId,
        CreatorId: ~~props.userInfo.userId,
        FarsiCreateDate: PersianToday,
        TotalValue: parseFloat(totalPayedValue(goodList)),
        Notes: tblSaleReturn.notes,
        SaleInvoiceId: ~~tblSaleInvoice.id,
        InventoryId: ~~tblSaleInvoice.inventoryId,
        TypeId: ~~tblSaleInvoice.typeId,
        IsPaidByCash: tblSaleReturn.isPaidByCash,
        PromoterId: ~~tblSaleInvoice.promoterId,
        DiscountPercent: tblSaleInvoice.discountPercent,
        DiscountValue: ~~tblSaleInvoice.discountValue,
        BranchId: ~~tblSaleInvoice.branchId,
        ObjectId1: ~~tblSaleInvoice.customerId,
        IsFormal: tblSaleInvoice.isFormal,
      };
      const TblSaleReturnDetail = [
        {
          Qty: parseFloat(goodList[0].returnQty),
          BarCode: goodList[0].barcode,
          DiscountPercent: parseFloat(goodList[0].discountPercent),
          DiscountValue: parseFloat(goodList[0].discountValue),
          RetailPrice: parseFloat(goodList[0].retailPrice),
          RowNumber: 1,
        },
      ];
      for (var i = 1; i < goodList.length; i++) {
        TblSaleReturnDetail.push({
          Qty: parseFloat(goodList[i].returnQty),
          BarCode: goodList[i].barcode,
          DiscountPercent: parseFloat(goodList[i].discountPercent),
          DiscountValue: parseFloat(goodList[i].discountValue),
          RetailPrice: parseFloat(goodList[i].retailPrice),
          RowNumber: i + 1,
        });
      }
      const newModel = {
        TblSaleReturn: TblSaleReturn,
        TblSaleReturnDetail: TblSaleReturnDetail,
        CashTunId: tblSaleReturn.isPaidByCash
          ? ~~props.cashInfo.cashTurnId
          : -1,
        CashId: tblSaleReturn.isPaidByCash ? ~~props.cashInfo.cashId : -1,
        IsTemporary: IsTemporary,
        IsEdit: IsEdit,
      };
      axios({
        method: "post",
        url: baseUrl + "Sale/FinalizeSaleReturn",
        data: newModel,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setLoading(false);

          if (response.data.statusCode === 204) {
            setNotify({
              color: "error",
              hideDuratuion: 10000,
              msg: response.data.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            setLoading(false);
            return;
          } else {
            //بروزرسانی ای دی و کد
            setTblSaleReturn({
              ...tblSaleReturn,
              id: response.data.id,
              code: response.data.code,
            });
            setLoading(false);
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          var errorMsg = ErrorMsg(
            error.message.replace("Request failed with status code 404", "404")
          );

          setNotify({
            color: "warning",
            hideDuratuion: 3000,
            msg: errorMsg,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  //نوتیفای حذف: کنسل
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  //نوتیفای حذف:تایید حذف
  const handleAcceptDialogDelete = () => {
    setOpenDialogDelete(false);
    handleDeleteFinalizeInvoice();
  };

  //حذف فاکتور
  function handleDeleteFinalizeInvoice() {
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      fetch(
        baseUrl +
          "Sale/DeleteSaleRetrun/" +
          tblSaleReturn.id +
          "/" +
          props.userInfo.userId,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        }
      )
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setLoading(false);

          if (response.statusCode === 200) {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          } else {
            setNotify({
              color: "error",
              hideDuratuion: 10000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //Scroll to Top Page
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        })
        .catch((error) => {
          setLoading(false);
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: ErrorMsg(error.message.replace("Error ", "")),
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  //نوتیفای برگشت : کنسل
  const handleCloseDialogReturn = () => {
    setOpenDialogReturn(false);
  };
  //نوتیفای برگشت:تایید  بازگشت به حالتهای موقت
  const handleAcceptDialogReturn = () => {
    setOpenDialogReturn(false);
    handleReturnToTemperoryReturn();
    // handleFinalizeInvoice(isTemp, isEdit);
  };
  //بازگشت از برگشت
  function handleReturnToTemperoryReturn() {
    if (oldCashTurn) {
      if (props.cashInfo === null) {
        setNotify({
          color: "error",
          hideDuratuion: 10000,
          msg: "نوبت صندوق بسته شده است .بازگشت امکان پذیر نمیباشد !",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      } else if (props.cashInfo.cashTurnId !== oldCashTurn) {
        setNotify({
          color: "error",
          hideDuratuion: 10000,
          msg: "نوبت صندوق بسته شده است .بازگشت امکان پذیر نمیباشد !",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    }
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      axios(
        baseUrl +
          "Sale/ReturnToTemperorySaleReturn/" +
          tblSaleReturn.id +
          "/" +
          props.userInfo.userId,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        }
      )
        .then((response) => {
          setLoading(false);

          if (response.data.message) {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            setTblSaleReturn((prevState) => ({
              ...prevState,
              code: 0,
            }));
            setIsEdit(true);
            return;
          } else {
            setNotify({
              color: "error",
              hideDuratuion: 10000,
              msg: "خطا در انجام عملیات",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //Scroll to Top Page
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        })
        .catch((error) => {
          setLoading(false);
          var msgText = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: msgText,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  function hanleFetchSaleReturn(id, callback) {
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      axios({
        method: "get",
        url: baseUrl + "Sale/GetSaleReturnInfo/" + id,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setLoading(false);
          response = response.data;
          var saleInvoice = {
            id: response.saleReturn.invoiceId,
            code:
              response.saleReturn.saleInvoiceCode === -1
                ? 0
                : response.saleReturn.saleInvoiceCode,
            promoterId: response.saleReturn.promoterId,
            promoterName: response.saleReturn.promoterName,
            customerId: response.saleReturn.customerId,
            customerName: response.saleReturn.customerName,
            branchId: response.saleReturn.branchId,
            branchName: response.saleReturn.branchName,
            inventoryId: response.saleReturn.inventoryId,
            inventoryName: response.saleReturn.inventoryName,
            typeId: response.saleReturn.typeId,
            isFormal: response.saleReturn.isFormal,
            discountValue: response.saleReturn.discountValue,
            discountPercent: response.saleReturn.discountPercent,
          };
          setTblSaleInvoice(saleInvoice);
          var saleReturn = {
            id: response.saleReturn.id,
            code: response.saleReturn.code,
            farsiCreatDate: response.saleReturn.farsiCreateDate,
            isPaidByCash: response.saleReturn.isPaidByCash,
            note: response.saleReturn.note,
          };
          setTblSaleReturn(saleReturn);

          var returnDetail = [];
          response.saleReturnDetail.forEach((item) => {
            returnDetail.push({
              barcode: item.barCode,
              code: item.code,
              name: item.name,
              retailPrice: item.retailPrice,
              qty: item.qty,
              returnQty: item.returnQty,
              unit: item.scaleName,
              remained: item.remained,
              discountPercent: item.discountPercent,
              discountValue: item.discountValue,
              totalTaxAddedValue: item.totalTaxAddedValue,
              noneOfPrice: item.retailPrice === 0,
            });
          });
          setGoodList(returnDetail);
          setOldCashTurn(response.cashTurnId);
          callback(response.saleReturn.farsiCreateDate);
        })
        .catch((error) => {
          setLoading(false);
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: "خطا در بارگذاری اطلاعات",
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  return (
    <div className="fgnonmb">
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <Miulert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </Miulert>
      </Snackbar>

      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/9">بازرگانی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>برگشت از فروش</BreadcrumbItem>
      </Breadcrumb>

      <Card>
        <CardHeader>
          <strong>برگشت از فروش با عطف</strong>
        </CardHeader>
        <CardBody className="dir-rtl text-right">
          <Alert
            color="danger"
            className={
              props.cashInfo === null && tblSaleReturn.isPaidByCash
                ? "me-4 ms-4"
                : props.cashInfo === null && !tblSaleReturn.isPaidByCash
                ? "hidden"
                : props.cashInfo.cashTurn === -1 && tblSaleReturn.isPaidByCash
                ? "me-4 ms-4"
                : "hidden"
            }
          >
            جهت ثبت برگشت از فروش بصورت نقدی میبایست نوبت صندوق را باز نمایید
          </Alert>
          <Row>
            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>شماره رایانه ای فاکتور</Label>
                <Input
                  bsSize="sm"
                  type="number"
                  name="id"
                  id="id"
                  value={tblSaleInvoice.id === 0 ? "" : tblSaleInvoice.id}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleFetchSaleInvoice(event.target.value, -1);
                    }
                    return true;
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>شماره تایید شده فاکتور</Label>
                <Input
                  bsSize="sm"
                  type="number"
                  name="code"
                  id="code"
                  value={tblSaleInvoice.code === 0 ? "" : tblSaleInvoice.code}
                  onChange={handleInputChange}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleFetchSaleInvoice(-1, event.target.value);
                    }
                    return true;
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>تهیه کننده</Label>
                <Input
                  bsSize="sm"
                  value={props.userInfo.userName}
                  disabled
                ></Input>
              </FormGroup>
            </Col>

            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>شماره رایانه ای</Label>
                <Input
                  bsSize="sm"
                  type="number"
                  name="saleReturnId"
                  id="saleReturnId"
                  value={tblSaleReturn.id === 0 ? "" : tblSaleReturn.id}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>شماره تایید شده</Label>
                <Input
                  bsSize="sm"
                  type="number"
                  value={tblSaleReturn.code === 0 ? "" : tblSaleReturn.code}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={2} sm={10} lg={2}>
              <FormGroup>
                <Label>تاریخ</Label>
                <PersianCalender
                  defaultValue={defaultValuePerCal}
                  minimumDate={
                    props.formPerms.filter((m) => m.id === 90314).length === 0
                      ? defaultValuePerCal
                      : null
                  }
                  maximumDate={
                    props.formPerms.filter((m) => m.id === 90314).length === 0
                      ? defaultValuePerCal
                      : null
                  }
                  onChange={(FromFarsiDate) => {
                    setTblSaleReturn((prevState) => ({
                      ...prevState,
                      farsiCreatDate: FromFarsiDate,
                    }));
                  }}
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>بازاریاب </Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="promoterName"
                  id="promoterName"
                  value={tblSaleInvoice.promoterName}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col md={8} sm={10} lg={4}>
              <FormGroup>
                <Label> مشتریان</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  name="customerName"
                  id="customerName"
                  value={tblSaleInvoice.customerName}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label for="exampleCheckbox">نحوه محاسبه فاکتور</Label>
                <div>
                  <FormGroup check className="pull-right">
                    <Label check>
                      رسمی{" "}
                      <Input
                        bsSize="sm"
                        disabled
                        type="checkbox"
                        name="isFormal"
                        id="isFormal"
                        value={tblSaleInvoice.isFormal}
                        checked={tblSaleInvoice.isFormal}
                      />
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </Col>

            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label>شعبه</Label>
                <Input
                  disabled
                  bsSize="sm"
                  type="text"
                  name="branchName"
                  id="branchName"
                  value={tblSaleInvoice.branchName}
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={10} lg={2}>
              <FormGroup>
                <Label for="exampleCheckbox">نحوه پرداخت</Label>
                <div>
                  <FormGroup check className="pull-right">
                    <Label check>
                      نقدی{" "}
                      <Input
                        disabled={disabled}
                        bsSize="sm"
                        type="radio"
                        name="isPaidByCash"
                        checked={tblSaleReturn.isPaidByCash === true}
                        onChange={() =>
                          setTblSaleReturn((prevState) => ({
                            ...prevState,
                            isPaidByCash: true,
                          }))
                        }
                      />
                    </Label>
                  </FormGroup>
                  <FormGroup className="pull-right pe-3">
                    <Label>
                      غیر نقدی{" "}
                      <Input
                        disabled={disabled}
                        bsSize="sm"
                        type="radio"
                        name="isPaidByCash"
                        checked={tblSaleReturn.isPaidByCash === false}
                        onChange={() =>
                          setTblSaleReturn((prevState) => ({
                            ...prevState,
                            isPaidByCash: false,
                          }))
                        }
                      />
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </Col>

            <Col md={6} sm={10} lg={6}>
              <Label>ملاحظات:</Label>
              <FormGroup>
                <Input
                  disabled={disabled}
                  bsSize="sm"
                  type="text"
                  name="notes"
                  id="notes"
                  value={tblSaleReturn.notes}
                  onChange={handleInputChangeTblSaleReturn}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} lg={12} className="order-1">
              <div
                className="ui horizontal divider pointer "
                onClick={() => setInventoryInfoShow(!inventoryInfoShow)}
              >
                <span
                  className={
                    inventoryInfoShow
                      ? "fa fa-chevron-circle-down"
                      : "fa fa-chevron-circle-up"
                  }
                ></span>{" "}
                اطلاعات حسابداری و انبار
              </div>
            </Col>
          </Row>
          <Row className={inventoryInfoShow ? "" : " hidden"}>
            <Col md={2} sm={10} lg={1} className="order-2">
              <FormGroup>
                <Label>انبار</Label>
                <Input
                  disabled
                  bsSize="sm"
                  type="text"
                  name="inventoryName"
                  id="inventoryName"
                  value={tblSaleInvoice.inventoryName}
                />
              </FormGroup>
            </Col>
            <Col md={3} sm={10} lg={3} className="order-3">
              <FormGroup>
                <Label>نوبت صندوق</Label>
                <Input
                  bsSize="sm"
                  id="cashTrun"
                  name="cashTrun"
                  value={
                    props.cashInfo === null
                      ? ""
                      : props.cashInfo.cashTurn === -1
                      ? ""
                      : props.cashInfo.cashName +
                        "-" +
                        props.cashInfo.farsiOpenDate +
                        "- نوبت " +
                        props.cashInfo.cashTurn
                  }
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={7} sm={12} lg={8} className="order-4">
              <Row>
                <Col md={2} sm={6} lg={2} className="mt-4">
                  <Link
                    id="btnCashTurn"
                    className="btn  btn-warning btn-sm w-100"
                    to="/8/cashTurn"
                    onClick={() => props.selectedFormP("8/cashTurn")}
                  >
                    نوبت صندوق
                  </Link>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  lg={2}
                  className={tblSaleInvoice.id > 0 ? "mt-4" : "hidden mt-4"}
                >
                  <Link
                    className="btn w-100 btn-primary btn-sm"
                    to="/9/saleInvoice"
                    onClick={() => {
                      localStorage.setItem("saleInvoiceId", tblSaleInvoice.id);
                      props.selectedFormP("9/saleInvoice");
                    }}
                  >
                    مشاهده فاکتور{" "}
                  </Link>
                </Col>
                <Col md={2} sm={6} lg={2} className="mt-4">
                  <div className="btn w-100 btn-warning btn-sm">برگه رسید</div>
                </Col>
                <Col md={2} sm={6} lg={3} className="mt-4">
                  <Button color="primary" size="sm" className="w-100">
                    ثبت مجدد سند
                  </Button>
                </Col>
                <Col md={2} sm={6} lg={3} className="mt-4">
                  <Button color="warning" size="sm" className="w-100">
                    {" "}
                    سند حسابداری
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="dir-rtl text-right ">
            <Col lg={12} className="mx-auto mt-3">
              <Table hover>
                <thead>
                  <tr>
                    <th className="w60">ردیف</th>
                    <th className="w100"> بارکد کالا </th>
                    <th className="w60">کد کالا</th>
                    <th>عنوان</th>
                    <th className="w60">تعداد</th>
                    <th className="w60">تعداد مرجوعی</th>
                    <th className="w60">واحد</th>
                    <th className="w60">قیمت واحد</th>
                    <th className="w60">درصد تخفیف</th>
                    <th className="w100"> مالیات و عوارض</th>
                    <th className="w100">مبلغ کل با تخفیف</th>
                    <th className="w100"> مبلغ کل با مالیات و عوارض</th>
                    <th className="w100">مبلغ کل</th>
                    <th className="w60">موجودی</th>
                  </tr>
                </thead>
                <tbody>
                  {goodList.map((prod, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}</th>

                        <td>{prod.barcode}</td>
                        <td>{prod.code}</td>
                        <td>{prod.name}</td>
                        <td>{prod.qty}</td>
                        <td>
                          <Input
                            type="number"
                            disabled={disabled}
                            min="0"
                            max={prod.qty}
                            value={prod.returnQty}
                            name="returnQty"
                            index={index}
                            onChange={(event) => {
                              const index =
                                ~~event.target.getAttribute("index");
                              const tmpgoodList = [...goodList];
                              tmpgoodList[index]["returnQty"] =
                                event.target.value;
                              setGoodList(tmpgoodList);
                            }}
                          />
                        </td>
                        <td>تعداد</td>
                        <td>
                          <NumberFormat
                            value={prod.retailPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={prod.discountPercent}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={prod.totalTaxAddedValue}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              prod.retailPrice * prod.qty -
                              prod.discountValue * prod.qty
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              prod.retailPrice * prod.qty +
                              prod.totalTaxAddedValue
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={
                              prod.retailPrice * prod.qty +
                              prod.totalTaxAddedValue -
                              prod.discountValue * prod.qty
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <span className="text-danger">{prod.remained}</span>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md={2} sm={10} lg={2}>
              <div htmlFor="totalretailPrice" className="text-left">
                جمع کل:
              </div>
            </Col>
            <Col md={2} sm={10} lg={2}>
              <FormGroup>
                <NumberFormat
                  value={totalretailPrice(goodList)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </FormGroup>
            </Col>

            <Col md={2} sm={10} lg={2}>
              <div htmlFor="totalInlineDiscount" className="text-left">
                تخفیف فاکتور (سطری):
              </div>
            </Col>
            <Col md={2} sm={10} lg={2}>
              <FormGroup>
                <NumberFormat
                  value={totalInlineDiscount(goodList)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </FormGroup>
            </Col>

            <Col md={2} sm={10} lg={2}>
              <div htmlFor="totalPriceWDiscount" className="text-left">
                جمع کل با تخفیف:
              </div>
            </Col>
            <Col md={2} sm={10} lg={2}>
              <FormGroup>
                <NumberFormat
                  value={totalPriceWDiscount(goodList)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-end mt-2">
            <Col md={2} sm={10} lg={2}>
              <div htmlFor="totalTaxAndAdded" className="text-left">
                مالیات:
              </div>
            </Col>
            <Col md={2} sm={10} lg={2}>
              <FormGroup>
                <NumberFormat
                  value={totalTaxAndAdded(goodList)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="justify-content-end mt-2">
            <Col md="4">
              <Alert color="info">
                <Row>
                  <Col md={6} sm={10} lg={6}>
                    <div htmlFor="totalDiscount" className="text-left">
                      جمع تخفیفات:
                    </div>
                  </Col>
                  <Col md={6} sm={10} lg={6}>
                    <FormGroup>
                      <NumberFormat
                        value={totalDiscount(goodList)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Alert>
            </Col>
            <Col md="4">
              <Alert color="info">
                <Row>
                  <Col md={6} sm={10} lg={6}>
                    <div htmlFor="totalPayedValue" className="text-left">
                      مبلغ قابل پرداخت:
                    </div>
                  </Col>
                  <Col md={6} sm={10} lg={6}>
                    <FormGroup>
                      <NumberFormat
                        value={totalPayedValue(goodList)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} lg={12}>
              <LoadingSaleReturn loading={loading} />
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} lg={12} className="my-auto">
              <Button
                type="button"
                color="primary"
                size="sm"
                className={!disabled ? "m-3" : "hidden m-3"}
                disabled={
                  props.formPerms.filter((m) => m.id === 90305).length === 0
                }
                onClick={() => {
                  //یعنی در حالت ویرایش ثبت موقت هست
                  if (tblSaleReturn.id > 0) {
                    handleFinalizeReturn(true, true);
                  } else {
                    handleFinalizeReturn(true, false);
                  }
                }}
              >
                ثبت موقت
              </Button>
              <Button
                color="success"
                size="sm"
                className={!disabled ? "m-3" : "hidden m-3"}
                disabled={
                  props.formPerms.filter((m) => m.id === 90306).length === 0
                }
                onClick={() => handleFinalizeReturn(false, isEdit)}
              >
                ثبت نهایی
              </Button>

              <Button
                color="warning"
                size="sm"
                className={disabled ? "m-3" : "hidden"}
                onClick={() => setOpenDialogReturn(true)}
                //  disabled={props.formPerms.filter(m => m.id === 90107).length === 0}
              >
                {" "}
                بازگشت
              </Button>
              <Link
                className="btn btn-warning  m-3 btn-sm"
                to="/9/saleReturn"
                onClick={() => {
                  props.selectedFormP("9/saleReturn");
                }}
              >
                جدید
              </Link>
              <Button
                color="danger"
                size="sm"
                className="m-3"
                // disabled={props.formPerms.filter(m => m.id === 90103).length === 0}
                onClick={() => setOpenDialogDelete(true)}
              >
                حذف
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* //#region  dialog for delete saleInvoice */}
      <Dialog
        open={openDialogDelete}
        onClose={handleCloseDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-right">
          تذکر
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-right"
          >
            {tblSaleInvoice.code
              ? "برگشت از فروش  مورد نظر ،نهایی شده است. آیا برای حذف (بازگشت به حالت موقت و حذف)اطمینان دارید؟"
              : "آیا برای حذف برگشت از فروش موقت اطمینان دارید؟"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDelete} color="primary">
            انصراف
          </Button>
          <Button
            onClick={() => handleAcceptDialogDelete()}
            color="primary"
            autoFocus
          >
            تایید
          </Button>
        </DialogActions>
      </Dialog>

      {/* //#region  */}

      {/* //#region  dialog for return to temparory */}
      <Dialog
        open={openDialogReturn}
        onClose={handleCloseDialogReturn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-right">
          تذکر
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-right"
          >
            برگشت از فروش مورد نظر، نهایی شده است . آیا برای بازگشت به حالت ثبت
            موقت اطمینان دارید؟!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogReturn} color="primary">
            انصراف
          </Button>
          <Button
            onClick={() => handleAcceptDialogReturn()}
            color="primary"
            autoFocus
          >
            تایید
          </Button>
        </DialogActions>
      </Dialog>

      {/* //#region  */}
    </div>
  );
}

function LoadingSaleReturn({ loading }) {
  if (loading) {
    return (
      <Row className="justify-content-md-center">
        <Col md={3}>
          <Loading />
        </Col>
      </Row>
    );
  }
  return <></>;
}

export default SaleReturnRef;
