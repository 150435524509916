import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  Input,
  Label,
  FormGroup,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import SelectUser from "../select/SelectUser";
import { baseUrl } from "../../shared/appContact";
import { Loading } from "../LoadingComponent";
import NumberFormat from "react-number-format";
function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}
function CashTurnFlow() {
  //debugger;
  const cashPerm = useSelector((state) => state.cashPerm);
  const [cashes] = useState(cashPerm.cashPerm);
  const [isLoading, setIsLoading] = useState(false);

  const [model, setModel] = useState({
    cashId: -1,
    userId: -1,
    cashTurnId: -1,
    username: "",
  });

  const [turnList, setTurnList] = useState([]);
  const [cashFlow, setCashFlow] = useState([]);
  const [totalCreditVal, setTotalCreditVal] = useState(0);
  const [totalDebitVal, setTotalDebitVal] = useState(0);
  const [errors, setErrors] = useState("");

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const lastModel = useRef({
    model,
  });

  const removeUser = () => {
    setModel((model) => ({
      ...model,
      userId: -1,
    }));
  };
  const getCashTurn = useCallback(() => {
    const token = localStorage.token;
    if (token) {
      setIsLoading(true);
      fetch(baseUrl + "Cash/GetCashTurn/" + model.cashId + "/" + model.userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setTurnList(response);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          return alert(error.message);
        });
    }
  }, [model.cashId, model.userId]);

  const getCashFlow = useCallback(() => {
    const token = localStorage.token;
    let errors = "";

    if (token) {
      setErrors("");
      setIsLoading(true);

      fetch(baseUrl + "Cash/GetCashFlow/" + model.cashTurnId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response.status;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          var total = 0;
          var list = [];
          var previous = 0;
          var current = 0;
          var temp = 0;

          //calc debitRemained/creditRemained
          for (var i = 0; i < response.result.length; i++) {
            previous =
              i > 0
                ? response.result[i - 1].payedValue -
                  response.result[i - 1].receivedValue
                : 0;
            current =
              response.result[i].payedValue - response.result[i].receivedValue;
            temp = temp + previous;
            total = temp + current;
            //calc debitRemained/creditRemained

            list.push({
              code: response.result[i].code,
              description: response.result[i].description,
              payedValue: response.result[i].payedValue,
              receivedValue: response.result[i].receivedValue,
              id: response.result[i].id,
              farsiTransactionDate: response.result[i].farsiTransactionDate,
              secondAccountTitle: response.result[i].secondAccountTitle,
              objectName1: response.result[i].objectName1,
              debitRemained: total < 0 ? total * -1 : 0,
              creditRemained: total > 0 ? total : 0,
            });
          }
          setCashFlow(list);
          setTotalDebitVal(response.sumReceivedValue);
          setTotalCreditVal(response.sumPayedValue);
          setIsLoading(false);
        })

        .catch((error) => {
          errors = "موردی یافت نشد";
          setErrors(errors);
          setIsLoading(false);
          setCashFlow([]);
        });
    }
  }, [model.cashTurnId]);

  useEffect(() => {
    if (lastModel.current.model.userId !== model.userId) {
      setModel((prevState) => ({
        ...prevState,
        cashId: -1,
        cashTurnId: -1,
      }));
      lastModel.current.model = model;
    }
    if (lastModel.current.model.cashId !== model.cashId) {
      if (~~model.cashId !== -1) {
        getCashTurn();
      } else {
        setTurnList([]);
      }
      lastModel.current.model = model;
    }
    if (lastModel.current.model.cashTurnId !== model.cashTurnId) {
      if (~~model.cashTurnId !== -1) getCashFlow();
      lastModel.current.model = model;
    }
  }, [model, getCashTurn, getCashFlow]);

  return (
    <div className="fgnonmb">
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/8">مالی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>گردش نقدی</BreadcrumbItem>
      </Breadcrumb>
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <small> فرم</small>
            <strong>گردش نقدی </strong>
          </CardHeader>
          <CardBody className="text-right">
            <Row>
              <Col md={12}>
                <Alert color="danger" className={errors === "" ? "hidden" : ""}>
                  {" "}
                  {errors}
                </Alert>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="creatorId">صندوق دار</Label>
                  <button
                    type="button"
                    className="btn btn-link text-danger"
                    onClick={removeUser}
                  >
                    <span className="fa fa-trash"></span>
                  </button>
                  <SelectUser
                    value={{ value: model.userId }}
                    defaultOnchange={{
                      value: model.userId,
                      label: model.username,
                    }}
                    onChange={(CreatorId) => {
                      setModel((model) => ({
                        ...model,
                        userId: CreatorId,
                        // cashId: -1,
                      }));
                      setTurnList([]);
                      setCashFlow([]);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="cashId">صندوق</Label>
                  <Input
                    type="select"
                    id="cashId"
                    name="cashId"
                    value={model.cashId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید</option>
                    {cashes.map((obj) => {
                      return <option value={obj.id}>{obj.name}</option>;
                    })}{" "}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label htmlFor="Id">نوبت</Label>
                  <Input
                    type="select"
                    id="cashTurnId"
                    name="cashTurnId"
                    value={model.cashTurnId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید</option>
                    {turnList.map((cashTurn) => {
                      return (
                        <option value={cashTurn.id}>
                          {cashTurn.turn}_{cashTurn.farsiOpenDate}_
                          {cashTurn.farsiCloseDate}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12}>
                <Table
                  striped
                  className={cashFlow.length === 0 ? "hidden" : ""}
                >
                  <thead>
                    <tr>
                      <th>ردیف</th>
                      <th>ش تایید شده</th>
                      <th>تاریخ</th>
                      <th>شرح</th>
                      <th>نام حساب</th>
                      <th>تفضیل 1</th>
                      <th>دریافت</th>
                      <th>پرداخت</th>
                      <th>مانده بدهکار</th>
                      <th>مانده بستانکار</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashFlow.map((item, index) => {
                      return (
                        <tr className="mb-2">
                          <td>{index + 1}</td>
                          <td>{item.code}</td>
                          <td>{item.farsiTransactionDate}</td>
                          <td>{item.description}</td>
                          <td>{item.secondAccountTitle}</td>
                          <td>{item.objectName1}</td>
                          <td>{item.receivedValue}</td>
                          <td>{item.payedValue}</td>
                          <td>{item.debitRemained}</td>
                          <td>{item.creditRemained}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <RenderLoading isLoading={isLoading} />
            </Row>
            <Row className={cashFlow.length === 0 ? "hidden" : ""}>
              <Col md={2} sm={10} lg={2}>
                <div className="text-left pt-3">جمع دریافتها:</div>
              </Col>
              <Col md={2} sm={10} lg={2}>
                <Alert color="info">
                  <NumberFormat
                    value={totalDebitVal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </Alert>
              </Col>
              <Col md={2} sm={10} lg={2}>
                <div className="text-left pt-3">جمع پرداختی ها :</div>
              </Col>
              <Col md={2} sm={10} lg={2}>
                <Alert>
                  <NumberFormat
                    value={totalCreditVal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </Alert>
              </Col>
              <Col md={2} sm={10} lg={2}>
                <div className="text-left pt-3">مانده صندوق:</div>
              </Col>
              <Col md={2} sm={10} lg={2}>
                <Alert color="info">
                  <NumberFormat
                    value={totalCreditVal - totalDebitVal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </Alert>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default CashTurnFlow;
