import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { ErrorMsg } from "../../redux/ActionCreators";
import { baseUrl } from "../../shared/appContact";
import { searchState } from "../../shared/constent";
import { PersianToday, defaultValueToday } from "../../shared/persianToday";
import InvoiceFooter, {
  totalPayedValue,
  totalretailPrice
} from "../Invoice/InvoiceFooter";
import { Loading } from "../LoadingComponent";
import ModalCustomerCreate from "../modal/modalCustomerCreate";
import ModalRecPayPos from "../modal/modalRecPayPos";
import ModalSearchGoods from "../modal/modalSearchGoods";
import { PersianCalender } from "../persianCalender";
import SelectObjects from "../select/SelectObjects";
import SelectPromoter from "../select/SelectPromoter";

import "./printingStyles.css";

function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SaleInvoice(props) {

  const [customerModalIsOpen, setCustomerModalIsOpen] = useState(false)
  const [customerCellPhone, setCustomerCellPhone] = useState('')
  const [inventoryInfoShow, setInventoryInfoShow] = useState(true);
  const [promoterLabel, setPromoterLabel] = useState(
    props.defaultPoromterUser === null
      ? "متفرقه"
      : props.defaultPoromterUser.code +
      " - " +
      props.defaultPoromterUser.firstName +
      " - " +
      props.defaultPoromterUser.sureName
  );
  const [openDialogReturn, setOpenDialogReturn] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [oldCashTurn, setOldCashTurn] = useState(null);
  const [saveFinlize, setSaveFinlize] = useState(false);
  const [defaultValuePerCal, setDefaultValuePerCal] =
    useState(defaultValueToday);
  const searchProductFilter = {};


  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [loadingListResSrch, setLoadingListResSrch] = useState(false);
  const [loadingFinalizeInvoice, setLoadingFinalizeInvoice] = useState(false);
  const [touched, setTouched] = useState({ qty: false });
  const [isEdit, setIsEdit] = useState(false);
  const [goodListResSrch, setGoodListResSrch] = useState([
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
    },
  ]);

  const [tblSaleInvoice, setTblSaleInvoice] = useState({
    id: 0,
    code: 0,
    farsiCreatDate: PersianToday,
    promoterId:
      props.defaultPoromterUser === null ? 0 : props.defaultPoromterUser.id,
    customerId: props.defaultOrgUser === null ? -1 : props.defaultOrgUser.id,
    customerLabel:
      props.defaultOrgUser === null
        ? ""
        : props.defaultOrgUser.name +
        " - " +
        props.defaultOrgUser.cellPhone +
        " - " +
        props.defaultOrgUser.address,
    branchId: props.branches.lenght === 0 ? -1 : props.branches[0].id,
    isPaidByCash: props.defaultIsCash,
    pricingMethodId: -1,
    notes: "",
    inventoryId:
      props.inventoryUser.length === 0 ? -1 : props.inventoryUser[0].id,
    typeId: props.invoiceType.length === 0 ? 1 : props.invoiceType[0].id,
    isFormal: !!props.defaultIsFormal,
    discountPercent: 0,
    discountValue: 0,
  });

  console.log({ tblSaleInvoice });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goodSample = {
    barcode: "",
    code: "",
    name: "",
    retailPrice: 0,
    qty: 0,
    unit: "عدد",
    remained: 0,
    discountPercent: 0,
    discountValue: 0,
    totalTaxAddedValue: 0,
  };

  const [goodList, setGoodList] = useState([
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      unit: "عدد",
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
      noneOfPrice: false,
    },
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      unit: "عدد",
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
      noneOfPrice: false,
    },
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      unit: "عدد",
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
      noneOfPrice: false,
    },
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      unit: "عدد",
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
      noneOfPrice: false,
    },
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      unit: "عدد",
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
      noneOfPrice: false,
    },
  ]);
  const lastTblSaleInnvoice = useRef({ tblSaleInvoice });
  const lastGoodList = useRef({ goodList });
  const isFirstRun = useRef(true);
  const removeGood = useCallback(
    (index) => {
      if (index > -1) {
        setGoodList(goodList.filter((e) => e !== goodList[index]));
      }
    },
    [goodList]
  );

  const newGood = useCallback(() => {
    // debugger;
    setGoodList([...goodList, goodSample]);
    setTimeout(() => {
      var len = goodList.length;
      document.getElementById("barcode" + len).focus();
    }, 50);
  }, [goodList, goodSample]);
  ///index = -1  :  from modal
  ///index = -2 : from changeBaseinfo
  ///index > 0   : from inline serch barcode
  const addGood = useCallback(
    (good, index) => {
      var goodListTepm = [...goodList];

      //from ChangeBaseInfo
      if (index === -2) {
        //goodlist is empty
        if (goodListTepm.length === 0) {
          goodList.push({
            barcode: good.barcode,
            code: good.code,
            name: good.name,
            retailPrice: good.retailPrice,
            qty: good.qty,
            unit: "عدد",
            remained: good.remained,
            discountPercent: good.discountPercent,
            discountValue: good.discountValue,
            totalTaxAddedValue: good.totalTaxAddedValue,
            noneOfPrice: good.retailPrice === 0,
            isServise: good.property10 === "3376",
          });
          setGoodList(goodList);

          return;
        }
        //IF GOODLIST NOT EMPTY
        for (var i = 0; i < goodListTepm.length; i++) {
          if (goodListTepm[i].barcode === "") {
            goodListTepm[i] = {
              barcode: good.barcode,
              code: good.code,
              name: good.name,
              retailPrice: good.retailPrice,
              qty: good.qty,
              unit: "عدد",
              remained: good.remained,
              discountPercent: good.discountPercent,
              discountValue: good.discountValue,
              totalTaxAddedValue: good.totalTaxAddedValue,
              noneOfPrice: good.retailPrice === 0,
              isServise: good.property10 === "3376",
            };
            setGoodList(goodListTepm);
            return;
          }
        }

        goodListTepm.push({
          barcode: good.barcode,
          code: good.code,
          name: good.name,
          retailPrice: good.retailPrice,
          qty: good.qty,
          unit: "عدد",
          remained: good.remained,
          discountPercent: good.discountPercent,
          discountValue: good.discountValue,
          totalTaxAddedValue: good.totalTaxAddedValue,
          noneOfPrice: good.retailPrice === 0,
          isServise: good.property10 === "3376",
        });
        setGoodList(goodListTepm);
        return;
      }

      //FROM MODAL
      else if (index === -1) {
        // toggleModalSeachProd();
        //تعداد کالاهای وارد شده با این بارکد جهت بررسی تکراری نبودن کالای انتخاب شده
        var IsExit = goodListTepm.filter((m) => m.barcode === good.barcode);
        if (IsExit.length > 0) {
          for (var j = 0; j < goodListTepm.length; j++) {
            if (goodListTepm[j].barcode === good.barcode) {
              goodListTepm[j].qty++;
            }
          }
          setGoodList(goodListTepm);
          return;
        }

        //goodlist is empty
        if (goodListTepm.length === 0) {
          goodListTepm.push({
            barcode: good.barcode,
            code: good.code,
            name: good.name,
            retailPrice: good.retailPrice,
            qty: good.qty,
            unit: "عدد",
            remained: good.remained,
            discountPercent: good.discountPercent,
            discountValue: good.discountValue,
            totalTaxAddedValue: good.totalTaxAddedValue,
            noneOfPrice: good.retailPrice === 0,
            isServise: good.property10 === "3376",
          });
          setGoodList(goodListTepm);
          return;
        }
        //IF GOODLIST NOT EMPTY
        for (var z = 0; z < goodListTepm.length; z++) {
          if (goodListTepm[z].barcode === "") {
            goodListTepm[z] = {
              barcode: good.barcode,
              code: good.code,
              name: good.name,
              retailPrice: good.retailPrice,
              qty: good.qty,
              unit: "عدد",
              remained: good.remained,
              discountPercent: good.discountPercent,
              discountValue: good.discountValue,
              totalTaxAddedValue: good.totalTaxAddedValue,
              noneOfPrice: good.retailPrice === 0,
              isServise: good.property10 === "3376",
            };
            setGoodList(goodListTepm);

            return;
          }
        }

        goodListTepm.push({
          barcode: good.barcode,
          code: good.code,
          name: good.name,
          retailPrice: good.retailPrice,
          qty: good.qty,
          unit: "عدد",
          remained: good.remained,
          discountPercent: good.discountPercent,
          discountValue: good.discountValue,
          totalTaxAddedValue: good.totalTaxAddedValue,
          noneOfPrice: good.retailPrice === 0,
          isServise: good.property10 === "3376",
        });
        setGoodList(goodListTepm);

        return;
      }
      //form inline
      else {
        // debugger;
        //تعداد کالاهای وارد شده با این بارکد جهت بررسی تکراری نبودن کالای انتخاب شده
        const IsExit = goodListTepm.filter((m) => m.barcode === good.barcode);
        if (IsExit.length > 1) {
          for (var ii = 0; ii < goodListTepm.length; ii++) {
            if (goodListTepm[ii].barcode === good.barcode) {
              goodListTepm[ii].qty++;
            }
          }
          //ردیف جدید که کاربر بارکد تکراری وارد کرده . فیلد بارکدش را خالی میکنه روی همون فیلد focus  میکنه
          // debugger;
          setGoodList(goodListTepm);
          const tmpGoodList2 = [...goodList];
          tmpGoodList2[index].barcode = "";
          tmpGoodList2[index].qty = 0;
          setGoodList(tmpGoodList2);
          document.getElementById("barcode" + index).focus();
          return;
        }

        goodListTepm[index] = {
          barcode: good.barcode,
          code: good.code,
          name: good.name,
          retailPrice: good.retailPrice,
          qty: good.qty,
          unit: "عدد",
          remained: good.remained,
          discountPercent: good.discountPercent,
          discountValue: good.discountValue,
          totalTaxAddedValue: good.totalTaxAddedValue,
          noneOfPrice: good.retailPrice === 0,
          isServise: good.property10 === "3376",
        };
      }

      //نیاز هست یک ردیف جدید اضافه شود و روی فیلد بارکد تمرکز کند
      if (goodList.length === index + 1) {
        setGoodList([...goodListTepm, goodSample]);

        setTimeout(() => {
          document.getElementById("barcode" + (index + 1)).focus();
        }, 50);
      } else {
        // debugger;
        setGoodList(goodListTepm);
        document.getElementById("barcode" + (index + 1)).focus();
      }
    },
    [goodList, goodSample]
    // , toggleModalSeachProd],
  );

  const handleProductSearch = useCallback(
    (srchState, barcode, qty, index, callback) => {
      const token = localStorage.token;
      if (token) {
        const GModel = [
          {
            Name: "",
            Code: "",
            InventoryId: ~~tblSaleInvoice.inventoryId,
            GoodTypeId: ~~searchProductFilter.goodTypeId,
            IsStock: searchProductFilter.isStock,
            PricingMethodId: ~~tblSaleInvoice.pricingMethodId,
            barcode: "",
            Qty: 0,
            DiscountPercent: 0,
          },
        ];

        const GmodelchangeBaseInfo = [];

        if (srchState === searchState.inline && !barcode) {
          return;
        } else if (srchState === searchState.inline && barcode) {
          GModel[0].barcode = barcode;
          GModel[0].Qty = ~~qty;
          GModel[0].DiscountPercent = goodList[index].discountPercent;
        } else if (srchState === searchState.modalSrch) {
          GModel[0].Name = searchProductFilter.name;
          GModel[0].Code = searchProductFilter.code;
          GModel[0].InventoryId = ~~tblSaleInvoice.inventoryId;
          GModel[0].GoodTypeId = ~~searchProductFilter.goodTypeId;
          GModel[0].IsStock = searchProductFilter.isStock;
          GModel[0].PricingMethodId = ~~tblSaleInvoice.pricingMethodId;
          GModel[0].barcode = searchProductFilter.barcode;
        } else if (
          srchState === searchState.changeBaseInfo &&
          goodList[0].barcode !== ""
        ) {
          goodList.forEach((item) => {
            if (item.barcode !== "") {
              GmodelchangeBaseInfo.push({
                Name: item.name,
                Code: item.code,
                InventoryId: ~~tblSaleInvoice.inventoryId,
                GoodTypeId: ~~searchProductFilter.goodTypeId,
                IsStock: searchProductFilter.isStock,
                PricingMethodId: ~~tblSaleInvoice.pricingMethodId,
                barcode: item.barcode,
                Qty: item.qty,
                DiscountPercent: 0,
              });
            }
          });
        }
        const newModel = {
          ProductSearch: srchState === 3 ? GmodelchangeBaseInfo : GModel,
          IsFormal: tblSaleInvoice.isFormal,
        };
        setLoadingListResSrch(true);

        axios
          .post(baseUrl + "Basic/ProductSearch", newModel, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            credentials: "same-origin",
          })

          .then((response) => {
            setLoadingListResSrch(false);
            if (response.data.length === 0) {
              setNotify({
                color: "warning",
                hideDuratuion: 10000,
                msg: "هیچ موردی یافت نشد",
                open: true,
                vertical: "top",
                horizontal: "center",
              });
              return;
            }
            if (srchState === searchState.inline) {
              //اگر کالا قیمت نداشت کاربر میتواند براش قیمت بزاره
              //حالا اگر اول قیمت بزاره بعد تعداد کالا را تغییر بده اینجا دوباره قیمت صفر ست نشه قیمتی که خودش احتمالا قبلا ست کرده نمایش داده بشه

              if (
                response.data.result[0]["retailPrice"] === 0 &&
                goodList[index]["retailPrice"] !== 0
              ) {
                response.data.result[0]["retailPrice"] =
                  goodList[index]["retailPrice"];
              }
              // debugger;
              addGood(response.data.result[0], index);
            }
            if (srchState === searchState.modalSrch) {
              setGoodListResSrch(response.data.result);
            }
            if (srchState === 3) {
              var resultRes = response.data.result;
              const tmpgoodList = [...resultRes];
              for (var i = 0; i < resultRes.length; i++) {
                tmpgoodList[i]["noneOfPrice"] = resultRes[i].retailPrice === 0;
              }
              callback(tmpgoodList);
            }
          })
          .catch((error) => {
            setLoadingListResSrch(false);

            if (axios.isCancel(error)) {
              console.log("post Request canceled");
              return;
            }
            var errMsg = ErrorMsg(error.message.replace("Error ", ""));
            errMsg =
              errMsg.indexOf("404") > -1
                ? "کالای مورد نظر ناموجود میباشد"
                : errMsg;
            if (srchState === searchState.inline) {
              // debugger;
              const tmpGoodList2 = [...goodList];
              tmpGoodList2[index].barcode = "";
              setGoodList(tmpGoodList2);
            }
            if (srchState === searchState.modalSrch) {
              setGoodListResSrch([]);
            }
            setNotify({
              color: "error",
              hideDuratuion: 5000,
              msg: errMsg,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            return;
          });
      }
    },
    [
      addGood,
      goodList,
      searchProductFilter.barcode,
      searchProductFilter.code,
      searchProductFilter.goodTypeId,
      searchProductFilter.isStock,
      searchProductFilter.name,
      tblSaleInvoice.inventoryId,
      tblSaleInvoice.isFormal,
      tblSaleInvoice.pricingMethodId,
    ]
  );

  useEffect(() => {
    //fetch data for first load
    if (isFirstRun.current) {
      document.getElementById("barcode0").focus();
      if (props.inventoryUser.length === 0) {
        setNotify({
          color: "error",
          hideDuratuion: 9000,
          msg: "شما به هیج انباری دسترسی ندارید!",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
      }
      const id = localStorage.saleInvoiceId;
      if (id) {
        handleFetchSaleInvoice(id, -1, (response) => {
          setSaveFinlize(true);
          var resDate = response.split("/");
          var defualtValDate = {
            year: ~~resDate[0],
            month: ~~resDate[1],
            day: ~~resDate[2],
          };
          setDefaultValuePerCal(defualtValDate);
        });
        localStorage.removeItem("saleInvoiceId");
      }
      localStorage.setItem("TypeId", tblSaleInvoice.typeId);
      isFirstRun.current = false;
      lastGoodList.current = goodList;
      lastTblSaleInnvoice.current = tblSaleInvoice;

      return;
    }

    //if goodlist changed ,first must saveFinalize than paycashPoses active
    if (lastGoodList.current !== goodList) {
      setSaveFinlize(false);
      lastGoodList.current = goodList;
    }

    if (lastTblSaleInnvoice.current.id !== tblSaleInvoice.id) {
      setSaveFinlize(true);
      if (tblSaleInvoice.code > 0) {
        setDisabled(true);
      }
      lastTblSaleInnvoice.current = tblSaleInvoice;
    }
    if (lastTblSaleInnvoice.current.code !== tblSaleInvoice.code) {
      if (tblSaleInvoice.code > 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      setSaveFinlize(true);
      lastTblSaleInnvoice.current = tblSaleInvoice;
    }
    //when PricingMethod and Inventory changed then Method ProductSearch should Call
    if (
      lastTblSaleInnvoice.current.pricingMethodId !==
      tblSaleInvoice.pricingMethodId ||
      lastTblSaleInnvoice.current.inventoryId !== tblSaleInvoice.inventoryId ||
      lastTblSaleInnvoice.current.isFormal !== tblSaleInvoice.isFormal
    ) {
      setGoodListResSrch([
        {
          barcode: "",
          code: "",
          name: "",
          retailPrice: 0,
          qty: 0,
          remained: 0,
          discountPercent: 0,
          discountValue: 0,
          totalTaxAddedValue: 0,
        },
      ]);
      setTblSaleInvoice({
        ...tblSaleInvoice,
        discountPercent: 0,
        discountValue: 0,
      });
      if (goodList[0].barcode !== "") {
        handleProductSearch(
          searchState.changeBaseInfo,
          "",
          1,
          -1,
          (callback) => {
            setGoodList(callback);
          }
        );
      }
      lastTblSaleInnvoice.current = tblSaleInvoice;
    }

    if (lastTblSaleInnvoice.current.typeId !== tblSaleInvoice.typeId) {
      localStorage.setItem("TypeId", tblSaleInvoice.typeId);
      //در صورتی که نوع فاکتور فروشگاهی نیست مشتری پیش فرض خالی شود
      if (tblSaleInvoice.typeId !== props.invoiceType[0].id) {
        setTblSaleInvoice((prev) => ({
          ...prev,
          customerId: -1,
          customerLabel: "",
        }));
      }
      //در صورتی که نوع فاکتور فروشگاهی بود مشتری پیفرض با مقدار پیشفرض پر شود
      else {
        setTblSaleInvoice((prev) => ({
          ...prev,
          customerId:
            props.defaultOrgUser === null ? -1 : props.defaultOrgUser.id,
          customerLabel:
            props.defaultOrgUser === null
              ? ""
              : props.defaultOrgUser.name +
              " - " +
              props.defaultOrgUser.cellPhone +
              " - " +
              props.defaultOrgUser.address,
        }));
      }
      lastTblSaleInnvoice.current = tblSaleInvoice;
    }
  }, [
    goodList,
    tblSaleInvoice,
    props.inventoryUser.length,
    handleProductSearch,
    props.invoiceType,
    props.defaultOrgUser,
  ]);

  //handle Change tblSaleInvoice
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setTblSaleInvoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //handle change goodList
  const handleListChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const index = ~~event.target.getAttribute("index");

    const tmpgoodList = [...goodList];
    tmpgoodList[index][name] = value;
    setGoodList(tmpgoodList);
  };

  const handleBlur = (field) => (evt) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };
  function validate(qty, remained) {
    const errors = {
      qty: "",
    };

    if (touched.qty && qty > remained)
      errors.qty = "تعداد وارد شده بیشتر از موجودی کالا می باشد";

    return errors;
  }

  //لود اطلاعات جهت ویرایش
  function handleFetchSaleInvoice(id, code, callback) {
    const token = localStorage.token;
    if (token) {
      setLoadingFinalizeInvoice(true);
      fetch(baseUrl + "Sale/GetSaleInvoiceInfo/" + id + "/" + code + "/901", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            console.log("testttt in then");

            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setLoadingFinalizeInvoice(false);

          setPromoterLabel(response.invoice.promoterName);
          setOldCashTurn(response.cashTurnId);
          var invoice = {
            id: response.invoice.id,
            code: response.invoice.code === -1 ? 0 : response.invoice.code,
            farsiCreatDate: response.invoice.farsiCreateDate,
            promoterId: response.invoice.promoterId,
            customerId: response.invoice.customerId,
            customerLabel: response.invoice.customerName,
            branchId: response.invoice.branchId,
            isPaidByCash: response.invoice.isPaidByCash,
            pricingMethodId:
              response.invoice.pricingMethodId === null
                ? -1
                : response.invoice.pricingMethodId,
            notes: response.invoice.notes,
            inventoryId: response.invoice.inventoryId,
            typeId: response.invoice.typeId,
            isFormal: response.invoice.isFormal,
            discountValue: response.invoice.discountValue,
            discountPercent: response.invoice.discountPercent,
          };
          setTblSaleInvoice(invoice);

          var invoiceDetail = [];
          response.invoiceDetail.forEach((item) => {
            invoiceDetail.push({
              barcode: item.barCode,
              code: item.code,
              name: item.name,
              retailPrice: item.retailPrice,
              qty: item.qty,
              unit: item.scaleName,
              remained: item.remained,
              discountPercent: item.discountPercent,
              discountValue: item.discountValue,
              totalTaxAddedValue: item.totalTaxAddedValue,
              noneOfPrice: item.retailPrice === 0,
            });
          });
          setGoodList(invoiceDetail);

          //Scroll to Top Page
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
          callback(response.invoice.farsiCreateDate);
          setSaveFinlize(true);
          if (response.invoice.code > 0) {
            setDisabled(true);
          }
        })
        .catch((error) => {
          setLoadingFinalizeInvoice(false);
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: "خطا در بارگذاری اطلاعات",
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  //   ثبت موقت و نهایی
  function handleFinalizeInvoice(IsTemporary, IsEdit) {
    var Errmsg = [""];
    if (oldCashTurn) {
      if (props.cashInfo === null) {
        Errmsg.push(
          <p>نوبت صندوق بسته شده است .ویرایش امکان پذیر نمیباشد !</p>
        );
      } else if (props.cashInfo.cashTurnId !== oldCashTurn) {
        Errmsg.push(
          <p>نوبت صندوق بسته شده است .ویرایش امکان پذیر نمیباشد !</p>
        );
      }
    }
    if (tblSaleInvoice.customerId === -1) {
      Errmsg.push(<p>مشتری درج نگردیده است!</p>);
    }
    if (tblSaleInvoice.promoterId === -1) {
      Errmsg.push(<p>بازاریاب درج نگردیده است!</p>);
    }
    if (tblSaleInvoice.inventoryId === -1) {
      Errmsg.push(<p>انبار مشخص نگردیده است!</p>);
    }
    if (tblSaleInvoice.branchId === -1) {
      Errmsg.push(<p>شعبه مشخص نگردیده است!</p>);
    }

    if (goodList.length === 0) {
      Errmsg.push(<p>کالایی درج نگردیده است!</p>);
    } else if (goodList[0].barcode === "") {
      Errmsg.push(<p>کالایی درج نگردیده است!</p>);
      document.getElementById("barcode0").focus();
    }
    goodList.forEach((item) => {
      if (
        item.qty > item.remained &&
        props.systemSetting.isCheckQuantity === "1"
      ) {
        Errmsg.push(
          <p>
            تعداد انتخاب شده برای کالای {item.name} بیشتر از موجودی انبار است
          </p>
        );
      }
      //در صورتی که دسترسی ثبت کالاهای قیمت صفر را نداشته باشه خطا بده
      if (
        item.barcode !== "" &&
        item.retailPrice === 0 &&
        props.formPerms.filter((m) => m.id === 90130).length === 0
      ) {
        Errmsg.push(<p>کالای {item.name} بدون قیمت میباشد!</p>);
      }
    });
    if (Errmsg.length > 1) {
      setNotify({
        color: "error",
        hideDuratuion: 9000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });

      return;
    }
    if (tblSaleInvoice.isPaidByCash) {
      if (props.cashInfo === null) {
        setNotify({
          color: "error",
          hideDuratuion: 9000,
          msg: "جهت ثبت فاکتور بصورت نقدی میبایست نوبت صندوق را باز نمایید",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
      if (props.cashInfo.cashTurn === -1) {
        setNotify({
          color: "error",
          hideDuratuion: 9000,
          msg: "جهت ثبت فاکتور بصورت نقدی میبایست نوبت صندوق را باز نمایید",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    }
    const token = localStorage.token;
    if (token) {
      setLoadingFinalizeInvoice(true);
      const TblSaleInvoice = {
        Id: tblSaleInvoice.id,
        FarsiCreateDate: tblSaleInvoice.farsiCreatDate,
        DiscountPercent: tblSaleInvoice.discountPercent,
        DiscountValue: ~~tblSaleInvoice.discountValue,
        Notes: tblSaleInvoice.notes,
        TotalValue:
          totalPayedValue(goodList, tblSaleInvoice.discountValue) || 0,
        CreatorId: ~~props.userInfo.userId,
        CustomerId: ~~tblSaleInvoice.customerId,
        PromoterId: ~~tblSaleInvoice.promoterId,
        IsPaidByCash: tblSaleInvoice.isPaidByCash,
        InventoryId: ~~tblSaleInvoice.inventoryId,
        PricingMethodId: ~~tblSaleInvoice.pricingMethodId,
        BranchId: ~~tblSaleInvoice.branchId,
        TypeId: ~~tblSaleInvoice.typeId,
        IsFormal: tblSaleInvoice.isFormal,
      };

      const TblSaleInvoiceDetail = [
        {
          Qty: parseFloat(goodList[0].qty),
          BarCode: goodList[0].barcode,
          DiscountPercent: parseFloat(goodList[0].discountPercent),
          DiscountValue: parseFloat(goodList[0].discountValue),
          RetailPrice: parseFloat(goodList[0].retailPrice),
          RowNumber: 1,
        },
      ];
      for (var i = 1; i < goodList.length; i++) {
        if (goodList[i].barcode !== "") {
          TblSaleInvoiceDetail.push({
            Qty: parseFloat(goodList[i].qty),
            BarCode: goodList[i].barcode,
            DiscountPercent: parseFloat(goodList[i].discountPercent),
            DiscountValue: parseFloat(goodList[i].discountValue),
            RetailPrice: parseFloat(goodList[i].retailPrice),
            RowNumber: i + 1,
          });
        }
      }
      const newModel = {
        TblSaleInvoice: TblSaleInvoice,
        TblSaleInvoiceDetail: TblSaleInvoiceDetail,
        CashTurnId: tblSaleInvoice.isPaidByCash
          ? ~~props.cashInfo.cashTurnId
          : -1,
        CashId: tblSaleInvoice.isPaidByCash ? ~~props.cashInfo.cashId : -1,
        IsTemporary: IsTemporary,
        IsEdit: IsEdit,
      };

      fetch(baseUrl + "Sale/FinalizeInvoice", {
        method: "POST",
        body: JSON.stringify(newModel),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          //خطای منطقی داره و قادر به ثبت فاکتور نیست
          if (response.statusCode === 204) {
            setNotify({
              color: "error",
              hideDuratuion: 10000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            setLoadingFinalizeInvoice(false);
            return;
          } else {
            //بروزرسانی ای دی و کد
            setTblSaleInvoice({
              ...tblSaleInvoice,
              id: response.id,
              code: response.code,
            });
            setSaveFinlize(true);
            setLoadingFinalizeInvoice(false);
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });

            //Scroll to Top Page
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
          }
        })
        .catch((error) => {
          setLoadingFinalizeInvoice(false);
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: ErrorMsg(error.message.replace("Error ", "")),
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  //حذف فاکتور
  function handleDeleteFinalizeInvoice() {
    const token = localStorage.token;
    if (token) {
      setLoadingFinalizeInvoice(true);
      fetch(
        baseUrl +
        "Sale/DeleteSaleInvoice/" +
        tblSaleInvoice.id +
        "/" +
        props.userInfo.userId,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        }
      )
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setLoadingFinalizeInvoice(false);

          if (response.statusCode === 200) {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          } else {
            setNotify({
              color: "error",
              hideDuratuion: 10000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //Scroll to Top Page
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        })
        .catch((error) => {
          setLoadingFinalizeInvoice(false);
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: ErrorMsg(error.message.replace("Error ", "")),
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  //بازگشت از فروش
  function handleReturnToTemperoryInvoice() {
    if (oldCashTurn) {
      if (props.cashInfo === null) {
        setNotify({
          color: "error",
          hideDuratuion: 10000,
          msg: "نوبت صندوق بسته شده است .بازگشت امکان پذیر نمیباشد !",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      } else if (props.cashInfo.cashTurnId !== oldCashTurn) {
        setNotify({
          color: "error",
          hideDuratuion: 10000,
          msg: "نوبت صندوق بسته شده است .بازگشت امکان پذیر نمیباشد !",
          open: true,
          vertical: "top",
          horizontal: "center",
        });
        return;
      }
    }
    const token = localStorage.token;
    if (token) {
      setLoadingFinalizeInvoice(true);
      fetch(
        baseUrl +
        "Sale/ReturnToTemperoryInvoice/" +
        tblSaleInvoice.id +
        "/" +
        props.userInfo.userId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        }
      )
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          setLoadingFinalizeInvoice(false);

          if (response.message) {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            setTblSaleInvoice((prevState) => ({
              ...prevState,
              code: 0,
            }));
            setIsEdit(true);
            //جهت بروزرسانی تعداد کالاها با موجودی جدید
            handleProductSearch(
              searchState.changeBaseInfo,
              "",
              1,
              -1,
              (callback) => {
                setGoodList(callback);
              }
            );
            return;
          } else {
            setNotify({
              color: "error",
              hideDuratuion: 10000,
              msg: "خطا در انجام عملیات",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //Scroll to Top Page
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        })
        .catch((error) => {
          setLoadingFinalizeInvoice(false);
          var msgText = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: msgText,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  //پرینت فاکتور
  function print() {
    // console.log({goodList})
    window.print();
    // setNotify({
    //   color: "info",
    //   hideDuratuion: 10000,
    //   msg: "لطفا منتظر بمانید...",
    //   open: true,
    //   vertical: "top",
    //   horizontal: "center",
    // });
    // var items = [];

    // console.log({ goodList });
    // goodList.forEach((item) => {
    //   if (item.barcode) {
    //     items.push({
    //       productTitle: item.name || "",
    //       qty: item.qty,
    //       retailPrice: item.retailPrice,
    //     });
    //   }
    // });

    // var data = {
    //   companyName:
    //     props.companyInfo === null ? "" : props.companyInfo.companyName?.name,
    //   branch: tblSaleInvoice.branchId
    //     ? props.branches.filter((m) => m.id === tblSaleInvoice.branchId)[0]
    //         ?.name
    //     : null,
    //   id: tblSaleInvoice.id,
    //   user: props.userInfo.userName,
    //   farsiCreatDate: tblSaleInvoice.farsiCreatDate,
    //   items: items,
    //   instagram: props.companyInfo === null ? "" : props.companyInfo.instagram,
    //   website: props.companyInfo === null ? "" : props.companyInfo.webSite,
    //   branches: props.companyInfo === null ? "" : props.companyInfo.branches,
    //   totalValue: totalPayedValue(goodList, tblSaleInvoice.discountValue),
    //   totalDiscount: totalDiscount(goodList, tblSaleInvoice.discountValue),
    //   totalTaxAndAdded: totalTaxAndAdded(goodList),
    // };

    // console.log({ data });

    // var newModel = {
    //   template: {
    //     //name: "/hamyaranReport/saleInvoice/helium"
    //     name: "/hamyaranReport/saleInvoice/sale-invoice",
    //   },
    //   data: data,
    //   staticPdf: {
    //     rawContent: "<...base64 PDF...>",
    //   },
    //   language: "en",
    // };

    // console.log({ data });

    // fetch(reportUrl, {
    //     method: 'POST',
    //     body: JSON.stringify(newModel),
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     credentials: 'same-origin'
    // }).then(response => {

    //     if (response.ok) {
    //         return response;
    //     }

    //     else {
    //         var error = new Error('Error ' + response.status + ': ' + response.statusText);
    //         error.response = response;
    //         throw error;
    //     }
    // },
    //     error => {
    //         var errmess = new Error(error.message);
    //         throw errmess;
    //     })
    //     .then(function (response) {
    //         return response.blob();
    //     }).then(function (myBlob) {
    //         var objectURL = URL.createObjectURL(myBlob);
    //         document.querySelector('#pdf-frame').src = objectURL;
    //         objectURL = URL.revokeObjectURL(myBlob);
    //     }).then(
    //         function () {
    //             window.setTimeout(function () {
    //                 document.querySelector('#pdf-frame').contentWindow.print();
    //             }, 1000)
    //         })
    //     .catch(error => {
    //         var msgTxt = ErrorMsg(error.message.replace("Error ", ""));

    //         setNotify({
    //             color: "error",
    //             hideDuratuion: 10000,
    //             msg: msgTxt,
    //             open: true,
    //             vertical: 'top',
    //             horizontal: 'center'
    //         })
    //         return;
    //     });
  }

  //پرینت برای سفارش آشپزخونه
  // function printKictchen() {
  //     var items = [];
  //     goodList.forEach(item => {
  //         if (item.barcode) {
  //             items.push({
  //                 productTitle: item.name,
  //                 qty: item.qty,
  //             })
  //         }
  //     });

  //     var data = {
  //         companyName: props.companyInfo === null ? "" : props.companyInfo.companyName.name,
  //         branch: props.branches.filter(m => m.id === tblSaleInvoice.branchId)[0].name,
  //         id: tblSaleInvoice.id,
  //         code: tblSaleInvoice.code,
  //         farsiCreatDate: tblSaleInvoice.farsiCreatDate,
  //         items: items,
  //     }
  //     var newModel = {
  //         template: {
  //             name: "/hamyaranReport/saleInvoice/sale-invoice-forResturan"
  //         },
  //         data: data,
  //         staticPdf: {
  //             rawContent: "<...base64 PDF...>",
  //         },
  //         language: "en"
  //     }

  //     fetch(reportUrl, {
  //         method: 'POST',
  //         body: JSON.stringify(newModel),
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         credentials: 'same-origin'
  //     }).then(response => {

  //         if (response.ok) {
  //             return response;
  //         }

  //         else {
  //             var error = new Error('Error ' + response.status + ': ' + response.statusText);
  //             error.response = response;
  //             throw error;
  //         }
  //     },
  //         error => {
  //             var errmess = new Error(error.message);
  //             throw errmess;
  //         })
  //         .then(function (response) {
  //             return response.blob();
  //         }).then(function (myBlob) {
  //             var objectURL = URL.createObjectURL(myBlob);
  //             document.querySelector('#pdf-frame-secend').src = objectURL;
  //             objectURL = URL.revokeObjectURL(myBlob);
  //         }).then(
  //             function () {
  //                 window.setTimeout(function () {
  //                     document.querySelector('#pdf-frame-secend').contentWindow.print();
  //                 }, 1000)
  //             })

  //         .catch(error => {
  //             var msgTxt = ErrorMsg(error.message.replace("Error ", ""));

  //             setNotify({
  //                 color: "error",
  //                 hideDuratuion: 10000,
  //                 msg: msgTxt,
  //                 open: true,
  //                 vertical: 'top',
  //                 horizontal: 'center'
  //             })
  //             return;
  //         });

  // }

  //نوتیفای برگشت : کنسل
  const handleCloseDialogReturn = () => {
    setOpenDialogReturn(false);
  };
  //نوتیفای برگشت:تایید  بازگشت به حالتهای موقت
  const handleAcceptDialogReturn = () => {
    setOpenDialogReturn(false);
    handleReturnToTemperoryInvoice();
    // handleFinalizeInvoice(isTemp, isEdit);
  };
  //نوتیفای حذف: کنسل
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  //نوتیفای حذف:تایید حذف
  const handleAcceptDialogDelete = () => {
    var d = props.formPerms.filter((m) => m.id === 90103);
    if (d.length === 0) {
      setNotify({
        color: "error",
        hideDuratuion: 10000,
        msg: "شما مجاز به انجام این عملیات نمیباشید!",
        open: true,
        vertical: "top",
        horizontal: "center",
      });
      return;
    } else {
      setOpenDialogDelete(false);
      handleDeleteFinalizeInvoice();
    }
  };

  return (
    <>
      <div className="fgnonmb d-print-none">
        <iframe title="pdf-frame" id="pdf-frame" className="hidden"></iframe>
        <iframe
          title="pdf-frame-secend"
          id="pdf-frame-secend"
          className="hidden"
        ></iframe>
        <Snackbar
          anchorOrigin={{
            vertical: notify.vertical,
            horizontal: notify.horizontal,
          }}
          open={notify.open}
          autoHideDuration={notify.hideDuratuion}
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
        >
          <NotifyAlert
            onClose={() =>
              setNotify((prevState) => ({ ...prevState, open: false }))
            }
            severity={notify.color}
          >
            {notify.msg}
          </NotifyAlert>
        </Snackbar>

        <Breadcrumb>
          {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
          <BreadcrumbItem></BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/home">صفحه اصلی</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/9">بازرگانی</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>فاکتور فروش</BreadcrumbItem>
        </Breadcrumb>

        <Container fluid={true}>
          <Card>
            <CardHeader>
              <strong>فاکتور فروش</strong>
            </CardHeader>
            <CardBody className="dir-rtl text-right">
              <Alert
                color="danger"
                className={
                  props.cashInfo === null && tblSaleInvoice.isPaidByCash
                    ? "me-4 ms-4"
                    : props.cashInfo === null && !tblSaleInvoice.isPaidByCash
                      ? "hidden"
                      : props.cashInfo.cashTurn === -1 &&
                        tblSaleInvoice.isPaidByCash
                        ? "me-4 ms-4"
                        : "hidden"
                }
              >
                جهت ثبت فاکتور بصورت نقدی میبایست نوبت صندوق را باز نمایید
              </Alert>
              <Row>
                <Col md={4} sm={10} lg={2} className="order-1  order-lg-1">
                  <FormGroup>
                    <Label>تهیه کننده</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="creator"
                      id="creator"
                      value={props.userInfo.userName}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={10} lg={2} className="order-2 order-lg-2">
                  <FormGroup>
                    <Label>شماره رایانه ای</Label>
                    <Input
                      bsSize="sm"
                      type="number"
                      name="saleInvoiceId"
                      id="saleInvoiceId"
                      value={tblSaleInvoice.id === 0 ? "" : tblSaleInvoice.id}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={10} lg={2} className="order-3 order-lg-3">
                  <FormGroup>
                    <Label>شماره تایید شده</Label>
                    <Input
                      bsSize="sm"
                      type="number"
                      name="FinalizeInvoiceCode "
                      id="FinalizeInvoiceCode"
                      value={
                        tblSaleInvoice.code === 0 ? "" : tblSaleInvoice.code
                      }
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={2} sm={10} lg={2} className="order-5 order-lg-4">
                  <FormGroup>
                    <Label>نوع فاکتور</Label>
                    <Input
                      disabled={disabled}
                      bsSize="sm"
                      type="select"
                      name="typeId"
                      id="typeId"
                      value={tblSaleInvoice.typeId}
                      onChange={handleInputChange}
                    >
                      {props.invoiceType.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={2} sm={10} lg={2} className="order-6 order-lg-5">
                  <FormGroup>
                    <Label>تاریخ</Label>
                    <PersianCalender
                      defaultValue={defaultValuePerCal}
                      minimumDate={
                        props.formPerms.filter((m) => m.id === 90118).length ===
                          0
                          ? defaultValuePerCal
                          : null
                      }
                      maximumDate={
                        props.formPerms.filter((m) => m.id === 90118).length ===
                          0
                          ? defaultValuePerCal
                          : null
                      }
                      onChange={(FromFarsiDate) => {
                        setTblSaleInvoice((prevState) => ({
                          ...prevState,
                          farsiCreatDate: FromFarsiDate,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md={4} sm={10} lg={2} className="order-8 order-lg-6">
                  <FormGroup>
                    <Label>بازاریاب </Label>
                    <SelectPromoter
                      defaultOnchange={{
                        value: tblSaleInvoice.promoterId,
                        label: promoterLabel,
                      }}
                      value={{
                        value: tblSaleInvoice.promoterId,
                        label: promoterLabel,
                      }}
                      onChange={(PromoterId) => {
                        setTblSaleInvoice((prevState) => ({
                          ...prevState,
                          promoterId: PromoterId,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md={8} sm={10} lg={4} className="order-4 order-lg-7">
                  <FormGroup>
                    <Label>
                      مشتریان
                      <ModalCustomerCreate
                        userId={props.userInfo.userId}
                        customerCearated={(items) => {
                          setTblSaleInvoice((prevState) => ({
                            ...prevState,
                            customerId: items.id,
                            customerLabel: items.name,
                          }));
                        }}
                        buttonLabel={"ایجاد مشتری"}
                        className={"dir-rtl"}
                        isOpen={customerModalIsOpen}
                        cellPhone={customerCellPhone}
                      />
                    </Label>
                    <SelectObjects
                      onKeyDown={(event) => {
                        console.log({ event })
                        if (event.ctrlKey
                          && event.key === "Enter") {
                          setCustomerModalIsOpen(true)
                        }
                      }
                      }
                      onInputChange={(value) => {
                        if (value.length === 11 && !Number.isNaN(+value))
                          setCustomerCellPhone(value)
                      }}
                      defaultOnchange={{
                        value: tblSaleInvoice.customerId,
                        label: tblSaleInvoice.customerLabel,
                      }}
                      value={{
                        value: tblSaleInvoice.customerId,
                        label: tblSaleInvoice.customerLabel,
                      }}
                      onChange={(CustomerId) => {
                        setTblSaleInvoice((prevState) => ({
                          ...prevState,
                          customerId: CustomerId,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={10} lg={2} className="order-10  order-lg-9">
                  <FormGroup>
                    <Label for="exampleCheckbox">نحوه محاسبه فاکتور</Label>
                    <div>
                      <FormGroup className="pull-right">
                        <Label>
                          رسمی{" "}
                          <Input
                            bsSize="sm"
                            disabled={disabled}
                            type="checkbox"
                            name="isFormal"
                            id="isFormal"
                            value={tblSaleInvoice.isFormal}
                            onChange={handleInputChange}
                            checked={tblSaleInvoice.isFormal}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={4} sm={10} lg={2} className="order-9 order-lg-8">
                  <FormGroup>
                    <Label>شعبه</Label>
                    <Input
                      disabled={disabled}
                      bsSize="sm"
                      type="select"
                      name="branchId"
                      id="branchId"
                      value={tblSaleInvoice.branchId}
                      onChange={handleInputChange}
                    >
                      {props.branches.map((branch) => {
                        return (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={4} sm={10} lg={2} className="order-10  order-lg-9">
                  <FormGroup>
                    <Label for="exampleCheckbox">نحوه پرداخت</Label>
                    <div>
                      <FormGroup check className="pull-right m-1">
                        <Label check>
                          نقدی{" "}
                          <Input
                            disabled={disabled}
                            bsSize="sm"
                            type="radio"
                            name="isPaidByCash"
                            checked={tblSaleInvoice.isPaidByCash}
                            onChange={() =>
                              setTblSaleInvoice((prevState) => ({
                                ...prevState,
                                isPaidByCash: true,
                              }))
                            }
                          />
                        </Label>
                      </FormGroup>
                      <FormGroup className="pull-right pe-3 m-1">
                        <Label>
                          غیر نقدی{" "}
                          <Input
                            disabled={disabled}
                            bsSize="sm"
                            type="radio"
                            name="isPaidByCash"
                            checked={!tblSaleInvoice.isPaidByCash}
                            onChange={() =>
                              setTblSaleInvoice((prevState) => ({
                                ...prevState,
                                isPaidByCash: false,
                              }))
                            }
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </Col>
                <Col md={4} sm={10} lg={2} className="order-11  order-lg-10">
                  <FormGroup>
                    <Label>نوع قیمت گذاری</Label>
                    <Input
                      disabled={disabled}
                      bsSize="sm"
                      type="select"
                      name="pricingMethodId"
                      id="pricingMethodId"
                      value={tblSaleInvoice.pricingMethodId}
                      onChange={handleInputChange}
                    >
                      <option value="-1">قیمت گذاری پیش فرض</option>

                      {props.pricingmethod.map((pricemethod) => {
                        return (
                          <option key={pricemethod.id} value={pricemethod.id}>
                            {pricemethod.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={1} className="order-7  order-lg-11 pt-4">
                  <Label>ملاحظات:</Label>
                </Col>
                <Col
                  md={8}
                  sm={10}
                  lg={11}
                  className="order-8  order-lg-12 pt-4"
                >
                  <FormGroup>
                    <Input
                      disabled={disabled}
                      bsSize="sm"
                      type="text"
                      name="notes"
                      id="notes"
                      value={tblSaleInvoice.notes}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} lg={12} className="order-1">
                  <div
                    className="ui horizontal divider pointer "
                    onClick={() => setInventoryInfoShow(!inventoryInfoShow)}
                  >
                    <span
                      className={
                        inventoryInfoShow
                          ? "fa fa-chevron-circle-down"
                          : "fa fa-chevron-circle-up"
                      }
                    ></span>{" "}
                    اطلاعات حسابداری و انبار
                  </div>
                </Col>
              </Row>
              <Row className={inventoryInfoShow ? "" : " hidden"}>
                <Col md={2} sm={10} lg={2} className="order-2">
                  <FormGroup>
                    <Label>انبار</Label>
                    <Input
                      disabled={disabled}
                      bsSize="sm"
                      type="select"
                      name="inventoryId"
                      id="inventoryId"
                      value={tblSaleInvoice.inventoryId}
                      onChange={handleInputChange}
                    >
                      {props.inventoryUser.map((inventory) => {
                        return (
                          <option key={inventory.id} value={inventory.id}>
                            {inventory.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3} sm={10} lg={3} className="order-3">
                  <FormGroup>
                    <Label>نوبت صندوق</Label>
                    <Input
                      bsSize="sm"
                      id="cashTrun"
                      name="cashTrun"
                      value={
                        props.cashInfo === null
                          ? ""
                          : props.cashInfo.cashTurn === -1
                            ? ""
                            : props.cashInfo.cashName +
                            "-" +
                            props.cashInfo.farsiOpenDate +
                            "- نوبت " +
                            props.cashInfo.cashTurn
                      }
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md={7} sm={12} lg={7} className="order-4">
                  <Row>
                    <Col md={2} sm={6} lg={2} className="mt-4">
                      <Link
                        id="btnCashTurn"
                        className="btn  btn-warning btn-sm w-100"
                        to="/8/cashTurn"
                        onClick={() => props.selectedFormP("8/cashTurn")}
                      >
                        نوبت صندوق
                      </Link>
                    </Col>
                    <Col md={3} sm={6} lg={3} className="mt-4">
                      <Link
                        className="btn w-100 btn-primary btn-sm"
                        to="/9/saleInvoiceSearch"
                        onClick={() => {
                          props.selectedFormP("9/saleInvoiceSearch");
                        }}
                      >
                        جستجو فاکتور فروش
                      </Link>
                    </Col>
                    <Col md={2} sm={6} lg={2} className="mt-4">
                      <div className="btn w-100 btn-warning btn-sm">
                        برگ خروج
                      </div>
                    </Col>
                    <Col md={2} sm={6} lg={2} className="mt-4">
                      <Button color="primary" size="sm" className="w-100">
                        مشاهده
                      </Button>
                    </Col>
                    <Col md={2} sm={6} lg={2} className="mt-4">
                      {/* <div className={disabled ? "hidden" : "btn btn-info modalsearchProdBtn btn-sm w-100"} onClick={toggleModalSeachProd}>
                                            جستجوی کالا
                                    </div> */}
                      {/* <ModalSearchPreoduct
                                            disabled={disabled}
                                            goodListResSrch={goodListResSrch}
                                            searchProductFilter={searchProductFilter}
                                            addGood={(good) => addGood(good, -1)}
                                            loadingListResSrch={loadingListResSrch}
                                            onClick={() => handleProductSearch(searchState.modalSrch, '', 1, -1)} /> */}
                      <ModalSearchGoods
                        disabled={disabled}
                        inventoryID={tblSaleInvoice.inventoryId}
                        pricingMethodId={tblSaleInvoice.pricingMethodId}
                        IsFormal={tblSaleInvoice.isFormal}
                        addGood={addGood}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="dir-rtl text-right ">
                <Col lg={12} className="mx-auto mt-3">
                  <Table hover>
                    <thead>
                      <tr>
                        <th className="w100">
                          <span
                            onClick={() => newGood()}
                            className="fa fa-plus-circle ms-1 btn"
                          ></span>
                          ردیف
                        </th>

                        <th className="w100"> بارکد کالا </th>
                        <th>نام کالا</th>
                        <th className="w100">تعداد</th>
                        <th className="w100">قیمت فروش</th>
                        <th className="w100">درصد تخفیف</th>
                        <th className="w100">تخفیف</th>
                        <th className="w100"> مالیات و عوارض</th>
                        <th className="w100">جمع کل با تخفیف</th>
                        <th className="w100"> جمع کل با مالیات و عوارض</th>
                        <th className="w100">جمع کل</th>
                        <th className="w100">موجودی</th>
                        <th className="w60">عملیات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {goodList.map((prod, index) => {
                        const errors = validate(prod.qty, prod.remained, index);
                        return (
                          <tr className="table-row" key={index}>
                            <th className="text-center">{index + 1}</th>

                            <td>
                              <div>
                                <FormGroup>
                                  <Input
                                    disabled={disabled}
                                    bsSize="sm"
                                    value={prod.barcode}
                                    name="barcode"
                                    id={"barcode" + index}
                                    index={index}
                                    type="text"
                                    onChange={handleListChange}
                                    onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                        index =
                                          ~~event.target.getAttribute("index");
                                        handleProductSearch(
                                          searchState.inline,
                                          event.target.value,
                                          1,
                                          index
                                        );
                                      }
                                      return true;
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            </td>
                            <td>{prod.name}</td>
                            <td>
                              <div>
                                <FormGroup>
                                  <Input
                                    disabled={disabled}
                                    bsSize="sm"
                                    value={prod.qty}
                                    name="qty"
                                    id={"qty" + index}
                                    index={index}
                                    max={
                                      prod.isServise
                                        ? Number.MAX_SAFE_INTEGER
                                        : prod.remained
                                    }
                                    min="0"
                                    valid={prod.isServise || errors.qty === ""}
                                    invalid={
                                      !prod.isServise && errors.qty !== ""
                                    }
                                    onBlur={handleBlur("qty")}
                                    onChange={(event) => {
                                      index =
                                        ~~event.target.getAttribute("index");
                                      handleListChange(event);
                                      handleProductSearch(
                                        searchState.inline,
                                        goodList[index].barcode,
                                        event.target.value,
                                        index
                                      );
                                    }}
                                    type="number"
                                  />
                                  {!prod.isServise && (
                                    <FormFeedback>{errors.qty}</FormFeedback>
                                  )}
                                </FormGroup>
                              </div>
                            </td>
                            <td>
                              <div className={prod.noneOfPrice ? "" : "hidden"}>
                                <NumberFormat
                                  className="form-control"
                                  thousandSeparator={true}
                                  disabled={disabled}
                                  value={prod.retailPrice}
                                  name="retailPrice"
                                  index={index}
                                  onChange={(event) => {
                                    const index =
                                      ~~event.target.getAttribute("index");
                                    const tmpgoodList = [...goodList];
                                    tmpgoodList[index]["retailPrice"] =
                                      event.target.value.replace(/,/g, "");
                                    setGoodList(tmpgoodList);
                                  }}
                                />
                                <Badge
                                  className={
                                    prod.retailPrice === 0 ? "" : "hidden"
                                  }
                                  color="danger"
                                >
                                  بدون قیمت
                                </Badge>
                              </div>

                              <NumberFormat
                                value={prod.noneOfPrice ? "" : prod.retailPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td>
                              <Input
                                disabled={
                                  disabled &&
                                  props.formPerms.filter((m) => m.id === 90109)
                                    .length === 0
                                }
                                type="number"
                                min="0"
                                max="100"
                                value={prod.discountPercent}
                                name="discountPercent"
                                index={index}
                                onChange={(event) => {
                                  const index =
                                    ~~event.target.getAttribute("index");

                                  const tmpgoodList = [...goodList];
                                  var dp = parseFloat(
                                    event.target.value === ""
                                      ? 0
                                      : event.target.value
                                  );
                                  var dv = (dp / 100) * prod.retailPrice;
                                  tmpgoodList[index]["discountPercent"] =
                                    Math.round(dp * 100) / 100;
                                  tmpgoodList[index]["discountValue"] =
                                    Math.round(~~dv / 1000) * 1000;

                                  setGoodList(tmpgoodList);
                                }}
                              />

                              {/* {prod.discountPercent} %*/}
                            </td>
                            <td>
                              <NumberFormat
                                disabled={
                                  disabled &&
                                  props.formPerms.filter((m) => m.id === 90109)
                                    .length === 0
                                }
                                max={prod.retailPrice * prod.qty}
                                thousandSeparator={true}
                                index={index}
                                className="form-control"
                                inputMode="numeric"
                                value={prod.discountValue * prod.qty}
                                onChange={(e) => {
                                  var dv = e.target.value;

                                  if (dv.toString().indexOf(",") > -1) {
                                    dv = dv.replace(/,/g, "");
                                  }
                                  //تعداد  را از مبلغ تخفیف کم میکنیم
                                  dv = ~~dv / prod.qty;

                                  var dp = (dv * 100) / prod.retailPrice;

                                  const index =
                                    ~~e.target.getAttribute("index");

                                  const tmpgoodList = [...goodList];
                                  tmpgoodList[index]["discountPercent"] =
                                    Math.round(dp * 100) / 100;
                                  tmpgoodList[index]["discountValue"] = dv;

                                  setGoodList(tmpgoodList);
                                }}
                              />
                              {/* <NumberFormat value={prod.discountValue * prod.qty} displayType={'text'} thousandSeparator={true} prefix={''} /> */}
                            </td>
                            <td>
                              <NumberFormat
                                value={prod.totalTaxAddedValue}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  prod.retailPrice * prod.qty -
                                  prod.discountValue * prod.qty
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  prod.retailPrice * prod.qty +
                                  prod.totalTaxAddedValue
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={
                                  prod.retailPrice * prod.qty +
                                  prod.totalTaxAddedValue -
                                  prod.discountValue * prod.qty
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </td>
                            <td>
                              <span className="text-danger">
                                {prod.remained}
                              </span>{" "}
                            </td>
                            <td className="d-flex justify-content-center">
                              <button
                                disabled={disabled}
                                type="button"
                                className=" m-1 btn w-100 btn-link text-danger"
                                index={index}
                                onClick={() => {
                                  removeGood(index);
                                }}
                              >
                                <span className="fa fa-trash"></span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <InvoiceFooter
                goodList={goodList}
                discountPercent={tblSaleInvoice.discountPercent}
                discountValue={tblSaleInvoice.discountValue}
                disabled={disabled}
                permOfChangeAllDiscountValue={
                  props.formPerms.filter((m) => m.id === 90110).length !== 0
                }
                setOnChang={(newDiscountPercent, newDiscountValue) => {
                  setTblSaleInvoice({
                    ...tblSaleInvoice,
                    discountPercent: newDiscountPercent,
                    discountValue: newDiscountValue,
                  });
                }}
              />
              <Row>
                <Col md={12} sm={12} lg={12}>
                  <LoadingFinalizeInvoice loading={loadingFinalizeInvoice} />
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12} lg={12} className="my-auto">
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    className={!disabled ? "m-3" : "hidden m-3"}
                    disabled={
                      props.formPerms.filter((m) => m.id === 90105).length === 0
                    }
                    onClick={() => {
                      //یعنی در حالت ویرایش ثبت موقت هست
                      if (tblSaleInvoice.id > 0) {
                        handleFinalizeInvoice(true, true);
                      } else {
                        handleFinalizeInvoice(true, false);
                      }
                    }}
                  >
                    ثبت موقت
                  </Button>
                  <Button
                    color="success"
                    size="sm"
                    className={!disabled ? "m-3" : "hidden m-3"}
                    disabled={
                      props.formPerms.filter((m) => m.id === 90106).length === 0
                    }
                    onClick={() => handleFinalizeInvoice(false, isEdit)}
                  >
                    ثبت نهایی
                  </Button>
                  <Button
                    color="warning"
                    size="sm"
                    className={
                      tblSaleInvoice.code && saveFinlize ? "m-3" : "hidden"
                    }
                    onClick={() => setOpenDialogReturn(true)}
                    disabled={
                      props.formPerms.filter((m) => m.id === 90107).length === 0
                    }
                  >
                    {" "}
                    بازگشت
                  </Button>
                  <span
                    className={
                      tblSaleInvoice.id &&
                        saveFinlize &&
                        tblSaleInvoice.isPaidByCash &&
                        props.cashInfo
                        ? ""
                        : "hidden"
                    }
                  >
                    <ModalRecPayPos
                      saleInvoiceId={tblSaleInvoice.id}
                      branchId={tblSaleInvoice.branchId}
                      userId={props.userInfo.userId}
                      cashTurnId={
                        props.cashInfo === null
                          ? null
                          : props.cashInfo.cashTurnId
                      }
                      // payedValue={totalPayedValue(goodList)}
                      poses={props.poses}
                      buttonLabel={"پرداخت از طریق کارتخوان"}
                      className={"dir-rtl"}
                      payedValue={totalPayedValue(
                        goodList,
                        tblSaleInvoice.discountValue
                      )}
                    />
                  </span>
                  <Link
                    className="btn btn-warning  m-3 btn-sm"
                    to="/9/saleInvoice"
                    onClick={() => {
                      props.selectedFormP("9/saleInvoice");
                    }}
                  >
                    جدید
                  </Link>
                  <Button
                    color="success"
                    onClick={() => {
                      print();
                      //printKictchen()
                    }}
                    className={disabled ? "m-3" : "hidden m-3"}
                    size="sm"
                    // className="m-3"
                    disabled={!saveFinlize && !tblSaleInvoice.isPaidByCash}
                  >
                    پرینت
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    className="m-3"
                    disabled={
                      props.formPerms.filter((m) => m.id === 90103).length === 0
                    }
                    onClick={() => setOpenDialogDelete(true)}
                  >
                    حذف
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>

        {/* //#region  dialog for return to temparory */}
        <Dialog
          className="d-print-none"
          open={openDialogReturn}
          onClose={handleCloseDialogReturn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="text-right">
            تذکر
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="text-right"
            >
              فاکتور مورد نظر، نهایی شده است . آیا برای بازگشت به حالت ثبت موقت
              اطمینان دارید؟!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogReturn} color="primary">
              انصراف
            </Button>
            <Button
              onClick={() => handleAcceptDialogReturn()}
              color="primary"
              autoFocus
            >
              تایید
            </Button>
          </DialogActions>
        </Dialog>

        {/* //#region  */}

        {/* //#region  dialog for delete saleInvoice */}
        <Dialog
          className="d-print-none"
          open={openDialogDelete}
          onClose={handleCloseDialogDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="text-right">
            تذکر
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="text-right"
            >
              {tblSaleInvoice.code
                ? "فاکتور مورد نظر ،نهایی شده است. آیا برای حذف (بازگشت به حالت موقت و حذف)اطمینان دارید؟"
                : "آیا برای حذف فاکتور موقت اطمینان دارید؟"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogDelete} color="primary">
              انصراف
            </Button>
            <Button
              onClick={() => handleAcceptDialogDelete()}
              color="primary"
              autoFocus
            >
              تایید
            </Button>
          </DialogActions>
        </Dialog>

        {/* //#region  */}
      </div>

      <div className="d-none d-print-block printing-area">
        <Card className="border-0 w-100 h-100 ">
          <CardHeader>
            <Row>
              <Col className="text-center d-flex align-items-center justify-content-center">
                <strong>فاکتور فروش</strong>
              </Col>
              <Col className="text-center">
                <span>
                  {/* برج برفی */}
                  اسنولند
                </span>
                <img
                  className="mx-3"
                  src="/assets/images/olefpng.png"
                  height="50"
                  width="50"
                  alt="snow-tower"
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody
            className="dir-rtl text-right "
            style={{ border: "2px solid black", padding: "0 !important" }}
          >
            <Row className="my-2">
              {/* <Col
                lg={12}
                // className=" d-flex align-items-center justify-content-center"
              > */}
              <Col className="col-8 text-right">
                <strong>شماره:</strong>
                <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                  {" " + tblSaleInvoice.code}
                </span>
              </Col>
              <Col className="col-4  text-right">
                <strong>ساعت:</strong>
                <span style={{ fontSize: "13px" }}>
                  {" " + new Date().toLocaleTimeString("fa-IR")}
                </span>
              </Col>
              {/* </Col> */}
              <Col className="col-8  text-right">
                {" "}
                <strong>نام مشتری:</strong>
                <span style={{ fontSize: "13px" }}>
                  {" " +
                    tblSaleInvoice.customerLabel.split(" ")[0] +
                    "-" +
                    tblSaleInvoice.id}
                </span>
              </Col>
              <Col className="col-4 text-right">
                <strong>تاریخ:</strong>
                <span style={{ fontSize: "13px" }}>{" " + PersianToday}</span>
              </Col>
            </Row>
            <Row>
              <Table className="w-100">
                {/* <thead style={{borderBottom:".3px solid black"}}>
                </thead> */}
                <tbody>
                  <tr
                    className="borders text-center "
                    style={{ fontSize: "10px" }}
                  >
                    <th
                      className="borders"
                      style={{ padding: ".3rem", width: "30%" }}
                    >
                      نام خدمات
                    </th>
                    <th
                      className="borders "
                      style={{ padding: ".3rem", width: "8%", fontSize: "8px" }}
                    >
                      تعداد
                    </th>
                    <th
                      className="borders"
                      style={{ padding: ".3rem", width: "20%" }}
                    >
                      فی
                    </th>
                    {/* <th className="borders" style={{padding:'.3rem',width:"20%"}}> تخفیف</th> */}
                    <th
                      className="borders"
                      style={{ padding: ".3rem", width: "22%" }}
                    >
                      جمع
                    </th>
                  </tr>
                  {goodList.map((g) => {
                    return (
                      <tr>
                        <td
                          className="borders"
                          style={{ padding: ".3rem", width: "30%" }}
                        >
                          {g.name}
                        </td>
                        <td
                          className="borders"
                          style={{
                            padding: ".3rem",
                            width: "10%",
                            fontWeight: "bold",
                          }}
                        >
                          {g.qty}
                        </td>
                        <td
                          className="borders"
                          style={{
                            padding: ".3rem",
                            width: "20%",
                            fontWeight: "bold",
                          }}
                        >
                          {g.retailPrice}
                        </td>
                        {/* <td className="borders" style={{padding:'.3rem',width:"20%" ,fontWeight:"bold"}}>{g.discountValue*g.qty}</td> */}
                        <td
                          className="borders"
                          style={{
                            padding: ".3rem",
                            width: "20%",
                            fontWeight: "bold",
                          }}
                        >
                          {g.retailPrice * g.qty - g.discountValue * g.qty}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col className="col-6 borders text-center p-1">
                <strong>جمع فاکتور:</strong>
              </Col>
              <Col className="col-6 borders text-center p-1">
                {totalretailPrice(goodList)}
              </Col>
              {/* <Col className="col-6 borders text-center p-1">
                <strong>مبلغ تخفیف:</strong>
              </Col> */}
              {/* <Col className="col-6 borders text-center p-1">{totalDiscount(goodList, tblSaleInvoice.discountValue)}</Col> */}
              <Col className="col-6 borders text-center p-1">
                <strong>مبلغ دریافتی:</strong>
              </Col>
              <Col className="col-6 borders text-center p-1">
                {totalPayedValue(goodList, tblSaleInvoice.discountValue)}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <p className="text-center m-2">
          {/* ما بهترین نیستیم،اما بهترین ها از ما خرید می کنند */}
          حضور شما در اسنولند، مایه مباهات و افتخار ماست
        </p>
      </div>
    </>
  );
}

function LoadingFinalizeInvoice({ loading }) {
  if (loading) {
    return (
      <Row className="justify-content-md-center">
        <Col md={3}>
          <Loading />
        </Col>
      </Row>
    );
  }
  return <></>;
}

export default SaleInvoice;
