import * as ActionTypes from '../ActionTypes';

export const Branches = (state = { 
                                isLoading: true,
                                errMess: null,
                                branches:[]},
                             action) => {
    switch (action.type) {
        case ActionTypes.ADD_BRANCHES:
            return {...state, isLoading: false, errMess: null, branches: action.payload};

        case ActionTypes.BRANCHES_LOADING:
            return {...state, isLoading: true, errMess: null,  branches: []}

        case ActionTypes.BRANCHES_FAILED:
            return {...state, isLoading: false, errMess: action.payload};

        default:
            return state;
    }
};