import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PutlogOut } from "../redux/ActionCreators";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Col,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { Loading } from "./LoadingComponent";
import { ActiveMenuContext } from "./activeMenuContext";
import { version } from "../shared/appContact";

const Menu = () => {
  const [activeMenu, setActiveMenu] = useContext(ActiveMenuContext);
  const systemGroups = useSelector((state) => state.systemGroups);

  console.log({ systemGroups });
  const menu = systemGroups.systemGroups.map((system) => {
    return (
      <React.Fragment key={system.id}>
        <NavItem>
          <NavLink
            to={"/" + system.id}
            key={system.id}
            className="nav-link"
            onClick={() => {
              setActiveMenu({ ...activeMenu, systemGId: system.id });
            }}
          >
            {system.description}
          </NavLink>
        </NavItem>
      </React.Fragment>
    );
  });

  if (systemGroups.isLoading) {
    return (
      <div className="continer">
        <div className="row">
          <Loading></Loading>
        </div>
      </div>
    );
  } else if (systemGroups.errMess) {
    return (
      <div className="continer">
        <div className="row">
          <h4>{systemGroups.errMess}</h4>
        </div>
      </div>
    );
  } else return <>{menu}</>;
};

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handleLogOut = () => {
    dispatch(PutlogOut(userInfo.userInfo.sessionId));
    window.location.replace("/");
  };
  const handleReload = () => {
    // this.props.fetchInvoiceType(this.props.userInfo.userId)
    // this.props.fetchDefaultSetting(this.props.userInfo.userId)
    // this.props.fetchCashPerm(this.props.userInfo.userId)
    // this.props.fetchInventories(this.props.userInfo.userId)
    // this.props.fetchPricingMethod(this.props.userInfo.userId)
    // this.props.fetchBranches(this.props.userInfo.userId)
  };
  return (
    <Container fluid={true} className="dir-rtl d-print-none">
      <div className="row dir-rtl">
        <Col lg={10} md={10}>
          <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
              <NavbarBrand to="/">
                <img
                  alt="hamyaranSystem"
                  src="assets/images/logo.PNG"
                  height="50"
                  width="120"
                />
              </NavbarBrand>
              <NavbarToggler onClick={toggle} className="me-2" />
              <Collapse isOpen={isOpen} navbar>
                <Nav>
                  <NavItem>
                    <NavLink className="nav-link" to="/home">
                      داشبورد
                    </NavLink>
                  </NavItem>

                  <Menu />
                </Nav>
              </Collapse>
            </Navbar>
          </header>
        </Col>
        <Col
          lg={2}
          md={2}
          className="d-flex align-items-center justify-content-center"
        >
          <Button
            className="pe-3 ps-3 m-2"
            size="sm"
            color="success"
            id="reload"
            onClick={handleReload}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="pe-3 ps-3 m-2"
            size="sm"
            color="danger"
            id="exitBtn"
            onClick={handleLogOut}
          >
            <i className="fa fa-power-off"></i>
          </Button>
          <span id="version" className="m-2 fs-6">
            {version}
          </span>
        </Col>
      </div>
    </Container>
  );
}
