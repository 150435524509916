import * as ActionTypes from '../ActionTypes';

export const Login = (state = {
    isLoading: true,
    errMess: null,
    roles: []
},
    action) => {
    switch (action.type) {

        case ActionTypes.LOGIN_LOADING:
            return { ...state, isLoading: true, errMess: null, roles: [] }

        case ActionTypes.LOGIN_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};