import React, { Fragment, useContext, useState } from "react";
import { Collapse, Nav, NavItem, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ActiveMenuContext } from "./activeMenuContext";

function SideBar(props) {
  var systemGroups = useSelector((state) => state.systemGroups);
  const [activeMenu, setActiveMenu] = useContext(ActiveMenuContext);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const toggleSideBar = () => {
    setIsSideBarOpen(!isSideBarOpen);
  };
  systemGroups = systemGroups.systemGroups.filter(
    (systemGId) => systemGId.id === activeMenu.systemGId
  )[0];

  if (systemGroups) {
    const systemList = systemGroups.systems.map((system) => {
      return (
        <Fragment key={system.id}>
          <li
            onClick={() => {
              var val = activeMenu.systemId === system.id ? null : system.id;
              setActiveMenu({ ...activeMenu, systemId: val });
            }}
            className="yellow"
          >
            <span className="opl-3 ">
              <i
                className={
                  activeMenu.systemId === system.id
                    ? "fa fa-chevron-circle-down yellow ps-1"
                    : "fa fa-chevron-circle-up yellow ps-1"
                }
              ></i>
            </span>
            {system.name}
          </li>
          <Collapse isOpen={activeMenu.systemId === system.id}>
            <ul className="ul-">
              {system.rolePermissions.map((rolePer) => {
                if (
                  rolePer.formPermissions.filter((m) => !m.isButton).length ===
                  1
                ) {
                  let firstSubmenu = rolePer.formPermissions.filter(
                    (m) => !m.isButton
                  )[0];
                  let firstSubmenuCmp = "/";
                  if (firstSubmenu) {
                    firstSubmenuCmp = firstSubmenu.component;
                  }
                  return (
                    <NavItem
                      key={rolePer.id}
                      className={
                        activeMenu.rolePermId === rolePer.id
                          ? "activeMnuSidbar"
                          : ""
                      }
                    >
                      <Link
                        to={"/" + firstSubmenuCmp}
                        onClick={() => {
                          var val =
                            activeMenu.rolePermId === rolePer.id
                              ? null
                              : rolePer.id;
                          setActiveMenu({ ...activeMenu, rolePermId: val });
                          props.selectedFormP(firstSubmenuCmp);
                          toggleSideBar();
                        }}
                      >
                        <span className="ps-1">
                          <i className={rolePer.iconClass}></i>
                        </span>
                        {rolePer.name}
                      </Link>
                    </NavItem>
                  );
                } else {
                  return (
                    <Fragment key={rolePer.id}>
                      <li
                        key={rolePer.id}
                        onClick={() => {
                          var val =
                            activeMenu.rolePermId === rolePer.id
                              ? null
                              : rolePer.id;
                          setActiveMenu({ ...activeMenu, rolePermId: val });
                        }}
                      >
                        <span className="opl-3 ">
                          <i
                            className={
                              activeMenu.rolePermId === rolePer.id
                                ? "fa fa-chevron-circle-down yellow ps-1"
                                : "fa fa-chevron-circle-up yellow ps-1"
                            }
                          ></i>
                        </span>
                        {rolePer.name}
                      </li>
                      <Collapse isOpen={activeMenu.rolePermId === rolePer.id}>
                        <ul>
                          {rolePer.formPermissions.map((submenu) => {
                            if (!submenu.isButton) {
                              return (
                                <NavItem
                                  key={submenu.id}
                                  onClick={() => {
                                    setActiveMenu({
                                      ...activeMenu,
                                      formPermId: submenu.id,
                                    });
                                    toggleSideBar();
                                  }}
                                  className={
                                    activeMenu.formPermId === submenu.id
                                      ? "activeMnuSidbar"
                                      : ""
                                  }
                                >
                                  <Link to={`/${submenu.component}`}>
                                    <span className="ps-1">
                                      <i className={submenu.iconClass}></i>
                                    </span>
                                    {submenu.description}
                                  </Link>
                                </NavItem>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </ul>
                      </Collapse>
                    </Fragment>
                  );
                }
              })}
            </ul>
          </Collapse>
        </Fragment>
      );
    });

    return (
      <div
        className={
          isSideBarOpen === true
            ? "sibbar-open h-100 d-print-none"
            : "sibbar-close h-100 d-print-none"
        }
      >
        <div className={isSideBarOpen === true ? "sidebar h-100" : "hidden"}>
          <Nav className="pe-0" vertical>
            <ul>{systemList}</ul>
          </Nav>
        </div>
        <Button
          className="btn pull-right collapseSideBar"
          onClick={toggleSideBar}
        >
          <i className="fa fa-list"></i>{" "}
        </Button>
      </div>
    );
  } else {
    return <></>;
  }
}
export default React.memo(SideBar);
