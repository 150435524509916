import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
//import MapOrganization from 'mapOrganization'
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  Button,
  Input,
  Label,
  FormGroup,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Loading } from "../LoadingComponent";
import { Link } from "react-router-dom";
import { baseUrl } from "../../shared/appContact";
import { ErrorMsg } from "../../redux/ActionCreators";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { PersianCalender } from "../persianCalender";
import NumberFormat from "react-number-format";
import RecursiveTreeView from "../treeView";
import SelectObjects from "../select/SelectObjects";

function Miulert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}

export default function AccountDetail(props) {
  const orgInfo = useSelector((state) => state.orgInfo);
  const accounts = useSelector((state) => state.accounts);
  const [showBaseInfo, setShowBaseInfo] = useState(true);
  const [showSearchInfo, setShowSearchInfo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [srchRes, setSrchRes] = useState([]);
  const [sumDebits, setSumDebit] = useState(0);
  const [sumCredits, setSumCredit] = useState(0);
  const [cancelFilter, setCancelFilter] = useState(false);
  const isFirstRun = useRef(true);
  const lastpagesize = useRef({
    pageSize,
  });
  const [filterList, setFilterList] = useState({
    customerId: -1,
    customerLabel: "",
    fromFarsiDate: "",
    toFarsiDate: "",
  });
  const newFilterList = () => {
    setFilterList({
      customerId: -1,
      customerLabel: "",
      fromFarsiDate: "",
      toFarsiDate: "",
    });
  };
  const handleOrgSearch = useCallback(
    (pageNumber) => {
      var Errmsg = [""];
      if (filterList.customerId === -1) {
        Errmsg.push(<p>نام درج نگردیده است!</p>);
      }
      if (Errmsg.length > 1) {
        setNotify({
          color: "error",
          hideDuratuion: 9000,
          msg: Errmsg,
          open: true,
          vertical: "top",
          horizontal: "center",
        });

        return;
      }
      const token = localStorage.token;
      if (token) {
        setIsLoading(true);
        setSrchRes([]);
        setPageNumber(pageNumber);
        var newModel = {
          pageSize: ~~pageSize,
          pageNumber: pageNumber,
          isDefault: filterList.customerId ? false : true,
          fromFarsiDate:
            filterList.fromFarsiDate === -1 ? "" : filterList.fromFarsiDate,
          toFarsiDate:
            filterList.toFarsiDate === -1 ? "" : filterList.toFarsiDate,
          customerId: ~~filterList.customerId,
        };
        axios
          .post(baseUrl + "Basic/GetFundDetail", newModel, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            credentials: "same-origin",
          })

          .then((customers) => {
            setIsLoading(false);
            if (customers.data.length > 0) {
              setTotalRows(customers.data[0].totalRows);
              setSumDebit(customers.data[0].sumdebits);
              setSumCredit(customers.data[0].sumcredits);
              setSrchRes(customers.data);
            } else
              setNotify({
                color: "success",
                hideDuratuion: 9000,
                msg: "لیست در این بازه زمانی خالی است",
                open: true,
                vertical: "top",
                horizontal: "center",
              });
            setSumDebit("0");
            setSumCredit("0");
          })
          .catch((error) => {
            setIsLoading(false);
            var errorMsg = ErrorMsg(
              error.message.replace(
                "Request failed with status code 404",
                "404"
              )
            );
            setNotify({
              color: "warning",
              hideDuratuion: 3000,
              msg: errorMsg,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          });
      }
    },
    [
      filterList.fromFarsiDate,
      filterList.toFarsiDate,
      filterList.customerId,
      pageSize,
    ]
  );

  useEffect(() => {
    //fetch data for first load
    if (isFirstRun.current) {
      isFirstRun.current = false;
      //handleOrgSearch(pageNumber)
      return;
    }
    if (cancelFilter) {
      handleOrgSearch(pageNumber);
      setCancelFilter(false);
    }
    if (lastpagesize.current.pageSize !== pageSize) {
      handleOrgSearch(pageNumber);
      lastpagesize.current.pageSize = pageSize;
    }
  }, [cancelFilter, pageSize, pageNumber, handleOrgSearch]);
  const changePageSize = (event) => {
    setPageSize(event.target.value);
  };
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  return (
    <div className="fgnonmb">
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <Miulert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </Miulert>
      </Snackbar>
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/8">مالی </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>گردش حساب</BreadcrumbItem>
      </Breadcrumb>
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <strong>گردش حساب</strong>
            <Col md={4} sm={10} lg={2} className="order-8 order-lg-6"></Col>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowBaseInfo(!showBaseInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showBaseInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات اصلی
                </div>
              </Col>
            </Row>
            <Row className={showBaseInfo ? "" : "hidden"}>
              <Col md={3} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ از</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(FromFarsiDate) => {
                      setFilterList((prevState) => ({
                        ...prevState,
                        fromFarsiDate: FromFarsiDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3} sm={10} lg={2}>
                <FormGroup>
                  <Label>تا </Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(ToFarsiDate) => {
                      setFilterList((prevState) => ({
                        ...prevState,
                        toFarsiDate: ToFarsiDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> حساب ها</Label>

                  <Input
                    bsSize="sm"
                    type="text"
                    name="account"
                    id="account"
                    value={
                      orgInfo.orgInfo.accountName.code +
                      "-" +
                      orgInfo.orgInfo.accountName.title
                    }
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>موجودیت</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="accountClass"
                    id="accountClass"
                    value={
                      orgInfo.orgInfo.accountClassName.code +
                      "-" +
                      orgInfo.orgInfo.accountClassName.name
                    }
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={5} sm={10} lg={2}>
                <FormGroup>
                  <Label> اسامی </Label>
                  <SelectObjects
                    defaultOnchange={{
                      value: filterList.customerId,
                      label: filterList.customerLabel,
                    }}
                    value={{
                      value: filterList.customerId,
                      label: filterList.customerLabel,
                    }}
                    onChange={(CustomerId) => {
                      setFilterList((prevState) => ({
                        ...prevState,
                        customerId: CustomerId,
                      }));
                      if (!CustomerId) {
                        setCancelFilter(true);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <div
                    className="btn btn-primary w-100 mt-3 mb-3"
                    onClick={() => handleOrgSearch(1)}
                  >
                    جستجو
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowSearchInfo(!showSearchInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showSearchInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  نتایج جستجو
                </div>
              </Col>
            </Row>
            <Row className={showSearchInfo ? "" : "hidden"}>
              <Col md={12} className="table-responsive min-h-100 mt-3">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>شماره</th>
                      <th>تاریح سند</th>
                      <th>شرح</th>
                      <th>بدهکار</th>
                      <th>بستانکار</th>
                    </tr>
                  </thead>
                  <tbody>
                    {srchRes.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.documentID}</td>
                          <td>{item.farsiTransactionDate}</td>
                          <td>{item.description}</td>
                          <td>
                            <NumberFormat
                              value={item.debitValue}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={item.creditValue}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <RenderLoading isLoading={isLoading} />
            </Row>
            <Row>
              <Col md={6}></Col>
              <Col md={6} className="table-responsive min-h-100 mt-3">
                <Table striped bordered>
                  <tbody>
                    <tr>
                      <td>
                        <strong>جمع کلی</strong>
                      </td>
                      <td>
                        <strong>بدهکاری : </strong>
                        <NumberFormat
                          value={sumDebits}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </td>
                      <td>
                        <strong>بستانکاری : </strong>
                        <NumberFormat
                          value={sumCredits}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={""}
                        />
                      </td>
                      <td>
                        <strong>جمع مانده : </strong>

                        <NumberFormat
                          value={sumCredits - sumDebits}
                          allowNegative={true}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <div className="pull-right">
                  <Form inline>
                    <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                      <Label className="ms-sm-2">تعداد رکورد های هر صفحه</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={pageSize}
                        onChange={changePageSize}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
              <Col md={7}>
                <div className="pull-right  pe-5">
                  نمایش صفحه ی {pageNumber} از مجموع{" "}
                  {Math.floor(totalRows / pageSize)} صفحه
                </div>

                <div className="pull-left">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={pageNumber === 1 ? true : false}>
                      <PaginationLink onClick={() => handleOrgSearch(1)}>
                        اولین
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={pageNumber === 1 ? true : false}>
                      <PaginationLink
                        previous
                        onClick={() => handleOrgSearch(pageNumber - 1)}
                      >
                        قبلی
                      </PaginationLink>
                    </PaginationItem>

                    <div className={pageNumber < 3 ? "hidden" : ""}>
                      <PaginationItem
                        className={pageNumber < 3 ? "hidden" : ""}
                      >
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleOrgSearch(pageNumber - 2)}
                        >
                          {pageNumber - 2}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div className={pageNumber < 2 ? "hidden" : ""}>
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleOrgSearch(pageNumber - 1)}
                        >
                          {pageNumber - 1}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <PaginationItem active>
                      <PaginationLink>{pageNumber}</PaginationLink>
                    </PaginationItem>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 1
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem
                        disabled={
                          Math.floor(totalRows / pageSize) < pageNumber + 1
                        }
                      >
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleOrgSearch(pageNumber + 1)}
                        >
                          {pageNumber + 1}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 2
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleOrgSearch(pageNumber + 2)}
                        >
                          {pageNumber + 2}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 3
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleOrgSearch(pageNumber + 3)}
                        >
                          {pageNumber + 3}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 4
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleOrgSearch(pageNumber + 4)}
                        >
                          {pageNumber + 4}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <PaginationItem
                      disabled={
                        Math.floor(totalRows / pageSize) < pageNumber + 1
                      }
                    >
                      <PaginationLink
                        className="btn btn-link"
                        onClick={() => handleOrgSearch(pageNumber + 1)}
                      >
                        بعدی
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      disabled={
                        Math.floor(totalRows / pageSize) === pageNumber
                          ? true
                          : false
                      }
                    >
                      <PaginationLink
                        className="btn btn-link"
                        last="true"
                        onClick={() =>
                          handleOrgSearch(Math.floor(totalRows / pageSize))
                        }
                      >
                        آخرین
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
