import React, { useState } from 'react';
import { Button, Label, Col, Row } from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
//import { withRouter } from "react-router-dom";
import { history } from "react-router";
import { Loading } from './LoadingComponent';
const required = (val) => val && val.length;


export default function Login(props) {
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = (values)  => {
        setIsLoading(true);
        props.postLogin(values.userName, values.password)
        //dispatch(postLogin(values.userName, values.password));
        //     history.push('/')
    }
    const refreshPage = () => {
        window.parent.location = window.parent.location.href;
    }

    if (isLoading && !props.errMess) {
        return (
            <Row className='h-100'>
                <Col md={6} lg={6} >
                </Col>
                <Col md={5} lg={3} className='loginForm'>
                    <h5 className='pt-3 pb-3'>ورود به پرتال همیاران سیستم</h5>
                    <Row className='h-100'>
                        <Loading></Loading>


                    </Row>
                </Col>
            </Row>
        );
    }
    if (props.errMess) {

        return (
            <Row className='h-100'>
                <Col md={6} lg={6} >
                </Col>
                <Col md={5} lg={3} className='loginForm'>
                    <h5 className='pt-3 pb-3'>ورود به پرتال همیاران سیستم</h5>
                    <Row className='h-100'>
                        <h4 className='loginErr pt-5 pb-5 pe-2 ps-2'>{props.errMess.indexOf("400") > -1 ? "نام کاربری یا رمز عبور صحیح نمیباشد" : props.errMess}</h4>
                        <div className='loginBtn btn' onClick={() => refreshPage()}> <span>تلاش مجدد</span> </div>
                    </Row>
                </Col>
            </Row>
        )
    }
    else {
        return (

            <Row className='h-100'>
                <Col md={6} lg={6} >
                </Col>
                <Col md={5} lg={3} className='loginForm'>
                    <h5 className='pt-3 pb-3'>ورود به پرتال همیاران سیستم</h5>
                    <Row className='h-100'>

                        <LocalForm model="login"
                            onSubmit={(values) => handleSubmit(values)}
                            // onSubmit={(values)=>console.log({values})}
                        >
                            <Row className="form-group pt-5">
                                <Label htmlFor="userName" md={4}>نام کاربری</Label>
                                <Col md={6}>
                                    <Control.text
                                        model=".userName"
                                        id="userName"
                                        name="userName"
                                        placeholder="نام کاربری"
                                        className="form-control"
                                        validators={required}
                                    />
                                    <Errors
                                        className="text-danger"
                                        model=".userName"
                                        show="touched"
                                        messages={{
                                            required: 'وارد کردن نام کاربری اجباری میباشد'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group pb-5">
                                <Label htmlFor="password" md={4}>رمز عبور</Label>
                                <Col md={6}>
                                    <Control.password
                                        model=".password" id="password" name="password"
                                        placeholder="رمز عبور"
                                        className="form-control"
                                        validators={required}

                                    />
                                    <Errors
                                        className="text-danger"
                                        model=".password"
                                        show="touched"
                                        messages={{
                                            required: 'وارد کردن رمز عبور اجباری میباشد'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col md={12}>
                                    <Button type="submit" className='loginBtn' >
                                        تایید
                        </Button>
                                </Col>
                            </Row>
                        </LocalForm>

                    </Row>
                </Col>
            </Row>
        );
    }


}