import * as ActionTypes from '../ActionTypes';

export const CashInfo = (state = {
    isLoading: false,
    errMess: null,
    cashInfo: null
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_CASHINFO:
            return { ...state, isLoading: false, errMess: null, cashInfo: action.payload };

        case ActionTypes.CASHINFO_LOADING:
            return { ...state, isLoading: true, errMess: null, cashInfo: [] }

        case ActionTypes.CASHINFO_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};