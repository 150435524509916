import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { ErrorMsg } from "../../redux/ActionCreators";
import { baseUrl } from "../../shared/appContact";
import { Loading } from "../LoadingComponent";
import { PersianCalender } from "../persianCalender";
function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//#region for open create customer modal with  Shift + L
document.addEventListener("keypress", logKey);

function logKey(event) {
  if (event.code === "KeyL" && event.shiftKey) {
    var el = document.getElementsByClassName("modalCustomerCreateBtn")[0];
    if (el) el.click();
  }
}

const ModalCustomerCreate = (props) => {
  const { buttonLabel, className, userId, isOpen, cellPhone } = props;
  console.log({ cellPhone })
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [modal, setModal] = useState(isOpen);
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValuePerCal] = useState(null);

  const [model, setModel] = useState({
    FirstName: "",
    Name: "",
    CellPhone: cellPhone,
    Phone1: "",
    FarsiBirthDate: "",
    Address: "",
    Id: 0,
    CreatorID: userId,
    IsMale: false,
    BirthDate: null,
    FarsiMarriageDate: "",
  });
  const newForm = () => {
    setModel({
      FirstName: "",
      Name: "",
      CellPhone: "",
      Phone1: "",
      FarsiBirthDate: "",
      Address: "",
      Id: 0,
      CreatorID: userId,
      IsMale: false,
      BirthDate: null,
      FarsiMarriageDate: "",
    });
  };

  useEffect(() => {
    setModal(isOpen)
  }, [isOpen])
  useEffect(() => {
    setModel(ps => ({
      ...ps,
      CellPhone: cellPhone
    }))
  }, [cellPhone])

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  function handleSaveCustomer() {
    var Errmsg = [];

    if (model.Name === "") {
      Errmsg.push(<p>نام خانوادگی را وارد نمایید!</p>);
    }
    if (model.FirstName === "") {
      Errmsg.push(<p>نام را وارد نمایید</p>);
    }
    if (model.CellPhone === "") {
      Errmsg.push(<p>تلفن همراه را وارد نمایید</p>);
    } else if (
      !model.CellPhone.match(/^[0-9]+$/) ||
      model.CellPhone.length !== 11
    ) {
      Errmsg.push(<p>تلفن همراه را بدرستی وارد نمایید</p>);
    }

    if (Errmsg.length > 0) {
      setNotify({
        color: "error",
        hideDuratuion: 10000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const token = localStorage.token;
    if (token) {
      setIsLoading(true);
      const newModal = {
        distributionOrganization: {},
        organization: model,
      };

      fetch(baseUrl + "Basic/SaveCustomer", {
        method: "POST",
        body: JSON.stringify(newModal),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          //have user by this cellphone or edit customer
          if (response.message === "") {
            setNotify({
              color: "success",
              hideDuratuion: 8000,
              msg: "مشتری مورد نظر  با موفقیت ایجاد شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            setModel((prevState) => ({
              ...prevState,
              Id: response.newCustomer.id,
            }));

            props.customerCearated({
              name: model.FirstName + " " + model.Name + "-" + model.CellPhone,
              id: response.newCustomer.id,
            });
            toggle();
            newForm();
          } else {
            var error = new Error("Error " + response.message);
            error.response = response;
            throw error;
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          var msgText = ErrorMsg(
            error.statusCode || error.message.replace("Error ", "")
          );
          setNotify({
            color: "error",
            hideDuratuion: 10000,
            msg: msgText,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  function handleFindeCustomer() {
    if (model.CellPhone) {
      const token = localStorage.token;
      if (token) {
        setIsLoading(true);
        fetch(baseUrl + "Basic/GetCustomer/" + model.CellPhone, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        })
          .then(
            (response) => {
              if (response.ok) {
                return response;
              } else {
                var error = new Error(
                  "Error " + response.status + ": " + response.statusText
                );
                error.response = response;
                throw error;
              }
            },
            (error) => {
              var errmess = new Error(error.message);
              throw errmess;
            }
          )

          .then((response) => response.json())
          .then((response) => {
            props.customerCearated({
              name:
                response.firstName +
                " " +
                response.name +
                "-" +
                response.cellPhone,
              id: response.id,
            });
            toggle();
            newForm();
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            return;
          });
      }
    }
  }
  return (
    <div className="pull-left me-2">
      <Button
        size="sm"
        color="danger"
        onClick={toggle}
        className="modalCustomerCreateBtn"
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className} size="lg">
        <ModalHeader toggle={toggle}>فرم ایجاد مشتری</ModalHeader>
        <ModalBody>
          <Snackbar
            anchorOrigin={{
              vertical: notify.vertical,
              horizontal: notify.horizontal,
            }}
            open={notify.open}
            autoHideDuration={notify.hideDuratuion}
            onClose={() =>
              setNotify((prevState) => ({ ...prevState, open: false }))
            }
          >
            <NotifyAlert
              onClose={() =>
                setNotify((prevState) => ({ ...prevState, open: false }))
              }
              severity={notify.color}
            >
              {notify.msg}
            </NotifyAlert>
          </Snackbar>
          <Form inline>
            <FormGroup className="col-lg-5 mb-2 ms-sm-2 mb-sm-2">
              <Label for="CellPhone" className="ms-sm-2">
                تلفن همراه
              </Label>
              <Input
                type="tel"
                length="11"
                name="CellPhone"
                id="CellPhone"
                value={model.CellPhone}
                onBlur={handleFindeCustomer}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-lg-6 mb-2 ms-sm-2 mb-sm-2">
              <Label for="FirstName" className="ms-sm-2">
                نام
              </Label>
              <Input
                type="text"
                name="FirstName"
                id="FirstName"
                value={model.FirstName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-lg-5 mb-2 ms-sm-2 mb-sm-2">
              <Label for="Name" className="ms-sm-2">
                {" "}
                نام خانوادگی
              </Label>
              <Input
                type="text"
                name="Name"
                id="Name"
                value={model.Name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-lg-6 mb-2 ms-sm-2 mb-sm-2">
              <Label for="Phone1" className="ms-sm-2">
                تلفن ثابت
              </Label>
              <Input
                type="tel"
                minlength="11"
                name="Phone1"
                id="Phone1"
                value={model.Phone1}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-lg-5 mb-2 ms-sm-2 mb-sm-2">
              <Label for="FarsiBirthDate" className="ms-sm-2">
                تاریخ تولد
              </Label>
              <PersianCalender
                defaultValue={defaultValuePerCal}
                onChange={(FarsiBirthDate) => {
                  setModel((prevState) => ({
                    ...prevState,
                    FarsiBirthDate: FarsiBirthDate,
                  }));
                }}
              />
            </FormGroup>
            <FormGroup className="col-lg-5 mb-2 ms-sm-2 mb-sm-2">
              <Label for="Id" className="ms-sm-2">
                شماره مشتری
              </Label>
              <Input type="text" name="Id" id="Id" value={model.Id} />
            </FormGroup>
            <FormGroup className="col-lg-5 mb-2 ms-sm-2 mb-sm-2">
              <Label for="Id" className="ms-sm-2">
                جنسیت
              </Label>
              <Input
                type="select"
                name="IsMale"
                id="IsMale"
                className="w-60"
                value={model.IsMale}
                onChange={handleInputChange}
              >
                <option value="0">خانم</option>
                <option value="1">آقا</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-lg-6 mb-2 ms-sm-2 mb-sm-2">
              <Label for="Address" className="ms-sm-2">
                آدرس
              </Label>
              <Input
                type="textarea"
                name="Address"
                id="Address"
                value={model.Address}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Form>
          <RenderLoading loading={isLoading} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveCustomer}>
            ثبت
          </Button>{" "}
          <Button color="warning" onClick={newForm}>
            جدید
          </Button>
          <Button color="secondary" onClick={toggle}>
            انصراف
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function RenderLoading(loading) {
  if (loading.loading) {
    return (
      <Row className="d-flex justify-content-center">
        <Col md={2}>
          <Loading></Loading>
        </Col>
      </Row>
    );
  } else return <></>;
}
export default ModalCustomerCreate;
