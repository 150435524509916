import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input,
  Label,
  CardBody,
  FormGroup,
} from "reactstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { PersianCalender } from "../persianCalender";
import { Link } from "react-router-dom";
import { baseUrl } from "../../shared/appContact";
import { ErrorMsg } from "../../redux/ActionCreators";
import { PersianToday } from "../../shared/persianToday";
import { Loading } from "../LoadingComponent";
function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}
export default function Organization(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const orgInfo = useSelector((state) => state.orgInfo);

  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [showBaseInfo, setShowBaseInfo] = useState(true);
  const [showMoreInfo, setShowMoreInfo] = useState(true);
  const [showLocateInfo, setShowLocateInfo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [distributionOrg, setDistributionOrg] = useState({
    branchID: -1,
  });
  const [org, setOrg] = useState({
    id: 0,
    workActivityID: -1,
    typeID: -1,
    firstName: "",
    name: "",
    nationalCode: "",
    cellPhone: "",
    bankID: -1,
    webAddress: "",
    storeName: null,
    phone1: null,
    phone2: null,
    fax: null,
    email: null,
    bossDescription: "",
    relatedPositionID: "",
    farsiBirthDate: "",
    farsiMarriageDate: "",
    cityId: -1,
    jobID: -1,
    educationID: -1,
    customerDegreeID: -1,
    reservedID: -1,
    purchaseLocationID: -1,
    preConditionID: -1,
    city: "",
    town: "",
    street1: "",
    street2: "",
    no: "",
    floorNo: "",
    unitNo: "",
    postalCode: "",
    stateTaxOrganizationCode: null,
    cityTaxOrganizationCode: null,
    fromMorningTime: null,
    toMorningTime: null,
    fromEveningTime: null,
    toEveningTime: null,
    lng: null,
    lat: null,
    codeBK: "",
    codeBetter: "",
    ringNo: "",
    address: "",
    address2: "",
    address3: "",
    notes: "",
  });
  //handle Change org
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setOrg((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  //handle Change distributionOrg
  const handleInputChangeDisOrg = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setDistributionOrg((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const newOrg = () => {
    setOrg({
      id: 0,
      workActivityID: -1,
      typeID: -1,
      firstName: "",
      name: "",
      nationalCode: "",
      cellPhone: "",
      bankID: -1,
      webAddress: "",
      storeName: null,
      phone1: null,
      phone2: null,
      fax: null,
      email: null,
      bossDescription: "",
      relatedPositionID: "",
      farsiBirthDate: "",
      farsiMarriageDate: "",
      cityId: -1,
      jobID: -1,
      educationID: -1,
      customerDegreeID: -1,
      reservedID: -1,
      purchaseLocationID: -1,
      preConditionID: -1,
      city: "",
      town: "",
      street1: "",
      street2: "",
      no: "",
      floorNo: "",
      unitNo: "",
      postalCode: "",
      stateTaxOrganizationCode: null,
      cityTaxOrganizationCode: null,
      fromMorningTime: null,
      toMorningTime: null,
      fromEveningTime: null,
      toEveningTime: null,
      lng: null,
      lat: null,
      codeBK: "",
      codeBetter: "",
      ringNo: "",
      address: "",
      address2: "",
      address3: "",
      notes: "",
    });
    setDistributionOrg({
      branchID: -1,
    });
  };
  function handleSaveOrganization() {
    var Errmsg = [""];
    if (~~org.workActivityID === -1) {
      Errmsg.push(<p>نوع فعالیت را مشخص نمایید!</p>);
    }
    if (~~org.typeID === -1) {
      Errmsg.push(<p>نوع مشتری را مشخص نمایید!</p>);
    }
    if (!org.firstName) {
      Errmsg.push(<p>نام را وارد نمایید !</p>);
    }
    if (!org.name) {
      Errmsg.push(<p>نام خانوادگی را وارد نمایید !</p>);
    }
    if (!org.nationalCode) {
      Errmsg.push(<p>کد ملی را وارد نمایید !</p>);
    }
    if (!/^\d{10}$/.test(org.nationalCode)) {
      Errmsg.push(<p>کد ملی صحیح نمیباشد !</p>);
    }
    if (org.cellPhone === "") {
      Errmsg.push(<p>تلفن همراه را وارد نمایید</p>);
    } else if (
      !org.cellPhone.match(/^[0-9]+$/) ||
      org.cellPhone.length !== 11
    ) {
      Errmsg.push(<p>تلفن همراه را بدرستی وارد نمایید</p>);
    }
    if (~~distributionOrg.branchID === -1) {
      Errmsg.push(<p>شعبه را انتخاب نمایید !</p>);
    }
    if (~~org.bankID === -1) {
      Errmsg.push(<p>بانک را انتخاب نمایید !</p>);
    }
    if (org.webAddress === "") {
      Errmsg.push(<p>بانک را انتخاب نمایید !</p>);
    }
    if (~~org.purchaseLocationID === -1) {
      Errmsg.push(<p>محل خرید را انتخاب نمایید !</p>);
    }
    if (org.city === "") {
      Errmsg.push(<p>شهر را وارد نمایید !</p>);
    }
    if (org.town === "") {
      Errmsg.push(<p>محله یا روستا را وارد نمایید !</p>);
    }
    if (org.address === "" && org.town === "") {
      Errmsg.push(<p>آدرس را وارد نمایید !</p>);
    }
    if (org.address1 === "") {
      Errmsg.push(<p>آدرس زمین را وارد نمایید !</p>);
    }
    if (org.address2 === "") {
      Errmsg.push(<p>آدرس زمین را وارد نمایید !</p>);
    }

    if (Errmsg.length > 1) {
      setNotify({
        color: "error",
        hideDuratuion: 9000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });

      return;
    }

    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const newModel = {
        distributionOrganization: {
          branchID: ~~distributionOrg.branchID,
        },
        organization: {
          id: ~~org.id,
          workActivityID: ~~org.workActivityID,
          typeID: ~~org.typeID,
          firstName: org.firstName,
          name: org.name,
          nationalCode: org.nationalCode,
          cellPhone: org.cellPhone,
          bankID: ~~org.bankID,
          webAddress: org.webAddress,
          storeName: org.storeName,
          phone1: org.phone1,
          phone2: org.phone2,
          fax: org.fax,
          email: org.email,
          bossDescription: org.bossDescription,
          relatedPositionID: ~~org.relatedPositionID,
          farsiBirthDate: org.farsiBirthDate,
          farsiMarriageDate: org.farsiMarriageDate,
          cityId: ~~org.cityId,
          jobID: ~~org.jobID,
          educationID: ~~org.educationID,
          customerDegreeID: ~~org.customerDegreeID,
          reservedID: ~~org.reservedID,
          purchaseLocationID: ~~org.purchaseLocationID,
          preConditionID: ~~org.preConditionID,
          city: org.city,
          town: org.town,
          street1: org.street1,
          street2: org.street2,
          no: org.no,
          floorNo: org.floorNo,
          unitNo: org.unitNo,
          postalCode: org.postalCode,
          stateTaxOrganizationCode: ~~org.stateTaxOrganizationCode,
          cityTaxOrganizationCode: ~~org.cityTaxOrganizationCode,
          fromMorningTime: ~~org.fromMorningTime,
          toMorningTime: ~~org.toMorningTime,
          fromEveningTime: ~~org.fromMorningTime,
          toEveningTime: ~~org.toEveningTime,
          lng: parseFloat(org.lng),
          lat: parseFloat(org.lat),
          codeBK: org.codeBK,
          codeBetter: org.codeBetter,
          ringNo: org.ringNo,
          address:
            org.address || org.address === null
              ? org.address
              : org.city +
                " " +
                org.town +
                " " +
                org.street1 +
                " " +
                org.street2 +
                " " +
                (org.no ? "پلاک " + org.no : ""),
          address2: org.address2,
          address3: org.address3,
          notes: org.notes,
        },
      };
      fetch(baseUrl + "Basic/SaveCustomer", {
        method: "POST",
        body: JSON.stringify(newModel),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          if (response.message !== "") {
            setNotify({
              color: "error",
              hideDuratuion: 9000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //have user by this cellphone
          else {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            newOrg();
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);

          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <NotifyAlert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </NotifyAlert>
      </Snackbar>

      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/1">اطلاعات پایه</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>افراد حقیقی و حقوقی</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid={true}>
        <Card>
          <CardHeader>
            <strong>افراد و سازمانها</strong>
            <Col md={4} sm={10} lg={2} className="order-8 order-lg-6"></Col>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowBaseInfo(!showBaseInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showBaseInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات اصلی
                </div>
              </Col>
            </Row>
            <Row className={showBaseInfo ? "" : "hidden"}>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تهیه کننده</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="creator"
                    id="creator"
                    value={userInfo.userInfo.userName}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="farsiCreatDate"
                    id="farsiCreatDate"
                    value={PersianToday}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نوع فعالیت *</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="workActivityID"
                    id="workActivityID"
                    value={org.workActivityID}
                    valid={org.workActivityID !== -1}
                    invalid={org.workActivityID === -1}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.workActivity.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نوع مشتری *</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="typeID"
                    id="typeID"
                    value={org.typeID}
                    valid={~~org.typeID !== -1}
                    invalid={~~org.typeID === -1}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.organizationType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="firstName"
                    id="firstName"
                    valid={org.firstName !== ""}
                    invalid={org.firstName === ""}
                    value={org.firstName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام خانوادگی *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="name"
                    id="name"
                    value={org.name}
                    valid={org.name !== ""}
                    invalid={org.name === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد ملی *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="nationalCode"
                    id="nationalCode"
                    invalid={org.nationalCode === ""}
                    value={org.nationalCode}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تلفن همراه *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="cellPhone"
                    id="cellPhone"
                    valid={
                      org.cellPhone.match(/^[0-9]+$/) &&
                      org.cellPhone.length === 11
                    }
                    invalid={
                      !org.cellPhone.match(/^[0-9]+$/) ||
                      org.cellPhone.length !== 11
                    }
                    value={org.cellPhone}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شعبه *</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="branchID"
                    id="branchID"
                    value={distributionOrg.branchID}
                    valid={~~distributionOrg.branchID !== -1}
                    invalid={~~distributionOrg.branchID === -1}
                    onChange={handleInputChangeDisOrg}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.branches.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام بانک *</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="bankID"
                    id="bankID"
                    value={org.bankID}
                    valid={~~org.bankID !== -1}
                    invalid={~~org.bankID === -1}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.hrBank.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شماره شبا/حساب بانکی *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="webAddress"
                    id="webAddress"
                    valid={org.webAddress !== ""}
                    invalid={org.webAddress === ""}
                    value={org.webAddress}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام فروشگاه</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="storeName"
                    id="storeName"
                    value={org.storeName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تلفن 1</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="phone1"
                    id="phone1"
                    value={org.phone1}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تلفن 2</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="phone2"
                    id="phone2"
                    value={org.phone2}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نمابر</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="fax"
                    id="fax"
                    value={org.fax}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>پست الکترونیک</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="email"
                    id="email"
                    value={org.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>مسئول</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="bossDescription"
                    id="bossDescription"
                    value={org.bossDescription}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>مرتبط داخلی</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="relatedPositionID"
                    id="relatedPositionID"
                    value={org.relatedPositionID}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowMoreInfo(!showMoreInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showMoreInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات تکمیلی
                </div>
              </Col>
            </Row>
            <Row className={showMoreInfo ? "" : "hidden"}>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ تولد</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(farsiBirthDate) => {
                      setOrg((prevState) => ({
                        ...prevState,
                        farsiBirthDate: farsiBirthDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ ازدواج</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(farsiMarriageDate) => {
                      setOrg((prevState) => ({
                        ...prevState,
                        farsiMarriageDate: farsiMarriageDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شهر</Label>
                  <Input
                    type="select"
                    name="cityId"
                    value={org.cityId}
                    id="cityId"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.externalLocation.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شغل</Label>
                  <Input
                    type="select"
                    name="jobID"
                    value={org.jobID}
                    id="jobID"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.jobs.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تحصیلات</Label>
                  <Input
                    type="select"
                    name="educationID"
                    value={org.educationID}
                    id="educationID"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.hreducationLevelType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>درجه مشتری</Label>
                  <Input
                    type="select"
                    name="customerDegreeID"
                    value={org.customerDegreeID}
                    id="customerDegreeID"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.customerDegree.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>سایر</Label>
                  <Input
                    type="select"
                    name="reservedID"
                    value={org.reservedID}
                    id="reservedID"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    <option value="1">سایر 1</option>
                    <option value="2">سایر 2</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>محل خرید *</Label>
                  <Input
                    type="select"
                    name="purchaseLocationID"
                    value={org.purchaseLocationID}
                    id="purchaseLocationID"
                    valid={~~org.purchaseLocationID !== -1}
                    invalid={~~org.purchaseLocationID === -1}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.purchaseLocation.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شرایط فروش</Label>
                  <Input
                    type="select"
                    name="preConditionID"
                    value={org.preConditionID}
                    id="preConditionID"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.salePrecondition.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowLocateInfo(!showLocateInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showLocateInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات جغرافیایی
                </div>
              </Col>
            </Row>
            <Row className={showLocateInfo ? "" : "hidden"}>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> شهر*</Label>
                  <Input
                    name="city"
                    value={org.city}
                    id="city"
                    valid={org.city !== ""}
                    invalid={org.city === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> محله یا روستا*</Label>
                  <Input
                    type="text"
                    name="town"
                    value={org.town}
                    id="town"
                    valid={org.town !== ""}
                    invalid={org.town === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>خیابان اصلی</Label>
                  <Input
                    type="text"
                    name="street1"
                    value={org.street1}
                    id="street1"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>خیابان فرعی</Label>
                  <Input
                    type="text"
                    name="street2"
                    value={org.street2}
                    id="street2"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>پلاک</Label>
                  <Input
                    type="text"
                    name="no"
                    value={org.no}
                    id="no"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>طبقه</Label>
                  <Input
                    type="text"
                    name="floorNo"
                    value={org.floorNo}
                    id="floorNo"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>واحد</Label>
                  <Input
                    type="text"
                    name="unitNo"
                    value={org.unitNo}
                    id="unitNo"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد پستی</Label>
                  <Input
                    type="text"
                    name="postalCode"
                    value={org.postalCode}
                    id="postalCode"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد مالیاتی استان</Label>
                  <Input
                    type="number"
                    name="stateTaxOrganizationCode"
                    value={org.stateTaxOrganizationCode}
                    id="stateTaxOrganizationCode"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد مالیاتی شهر/حوزه</Label>
                  <Input
                    type="number"
                    name="cityTaxOrganizationCode"
                    value={org.cityTaxOrganizationCode}
                    id="cityTaxOrganizationCode"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2} sm={5} lg={1}>
                <FormGroup>
                  <Label> شیفت صبح از</Label>
                  <Input
                    bsSize="sm"
                    type="number"
                    name="fromMorningTime"
                    id="fromMorningTime"
                    value={org.fromMorningTime}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2} sm={5} lg={1}>
                <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                  <Label className="me-sm-2">تا:</Label>
                  <Input
                    bsSize="sm"
                    type="number"
                    name="toMorningTime"
                    id="toMorningTime"
                    value={org.toMorningTime}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2} sm={5} lg={1}>
                <FormGroup>
                  <Label> شیفت عصر از</Label>
                  <Input
                    bsSize="sm"
                    type="number"
                    name="fromEveningTime"
                    id="fromEveningTime"
                    value={org.fromEveningTime}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2} sm={5} lg={1}>
                <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                  <Label className="me-sm-2">تا:</Label>
                  <Input
                    bsSize="sm"
                    type="number"
                    name="toEveningTime"
                    id="toEveningTime"
                    value={org.toEveningTime}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>طول جغرافیایی</Label>
                  <Input
                    type="text"
                    name="lng"
                    value={org.lng}
                    id="lng"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>عرض جغرافیایی</Label>
                  <Input
                    type="text"
                    name="lat"
                    value={org.lat}
                    id="lat"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تعداد درخت</Label>
                  <Input
                    type="text"
                    name="codeBK"
                    value={org.codeBK}
                    id="codeBK"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نوع درخت</Label>
                  <Input
                    type="text"
                    name="codeBetter"
                    value={org.codeBetter}
                    id="codeBetter"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>متراژ</Label>
                  <Input
                    type="text"
                    name="ringNo"
                    value={org.ringNo}
                    id="ringNo"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>آدرس محل سکونت *</Label>
                  <Input
                    type="textarea"
                    name="address"
                    value={
                      org.address
                        ? org.address
                        : org.city +
                          " " +
                          org.town +
                          " " +
                          org.street1 +
                          " " +
                          org.street2 +
                          " " +
                          (org.no ? "پلاک " + org.no : "")
                    }
                    id="address"
                    valid={org.city !== "" || org.address !== ""}
                    invalid={org.city === "" && org.address === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>آدرس زمین * </Label>
                  <Input
                    type="textarea"
                    name="address2"
                    value={org.address2}
                    id="address2"
                    valid={org.address2 !== ""}
                    invalid={org.address2 === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>آدرس تحویل کالا </Label>
                  <Input
                    type="textarea"
                    name="address3"
                    value={org.address3}
                    id="address3"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شرح </Label>
                  <Input
                    type="textarea"
                    name="notes"
                    value={org.notes}
                    id="notes"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Button
                color="success"
                className="m-3"
                onClick={handleSaveOrganization}
              >
                ثبت
              </Button>

              <Button color="info" className="m-3" onClick={newOrg}>
                جدید
              </Button>
            </Row>
            <Row>
              <RenderLoading isLoading={loading} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
