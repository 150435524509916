import * as ActionTypes from '../ActionTypes';

export const SystemGroups = (state = { 
                                isLoading: true,
                                errMess: null,
                                systemGroups:[]},
                             action) => {
    switch (action.type) {
        case ActionTypes.ADD_SYSTEMGROUPS:
            return {...state, isLoading: false, errMess: null, systemGroups: action.payload};

        case ActionTypes.SYSTEMGROUPS_LOADING:
            return {...state, isLoading: true, errMess: null, systemGroups: []}

        case ActionTypes.SYSTEMGROUPS_FAILED:
            return {...state, isLoading: false, errMess: action.payload};

        default:
            return state;
    }
};