import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";

import { baseUrl } from "../../shared/appContact";
import NumberFormat from "react-number-format";
import { Loading } from "../LoadingComponent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { ErrorMsg } from "../../redux/ActionCreators";
import axios from "axios";

function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ModalRecPayPos(props) {
  const {
    buttonLabel,
    className,
    userId,
    poses,
    payedValue,
    //       saleInvoiceId
  } = props;
  console.log({ payedValue });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isLoading, setIsLoading] = useState(false);
  const [bankId, setBankId] = useState(-1);
  const [terminalList, setTerminalList] = useState([]);
  const [transaction, setTransaction] = useState();
  const [terminalID, setTerminalID] = useState(-1);
  const [totalValue, setTotalValue] = useState();
  const [description, setDescription] = useState();
  const [checkPayedValue, setCheckPayedValue] = useState(true);
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const lastBankId = useRef(bankId);
  const lastTotalValue = useRef(totalValue);
  const lastSaleInvoiceId = useRef(props.saleInvoiceId);

  useEffect(() => {
    if (lastSaleInvoiceId.current !== props.saleInvoiceId) {
      var desc = "پرداخت از طریق پوز بابت ش.ر " + props.saleInvoiceId;
      setDescription(desc);
      lastSaleInvoiceId.current = props.saleInvoiceId;
    }
    if (
      (!lastTotalValue.current ||
        lastTotalValue.current !== props.payedValue) &&
      checkPayedValue
    ) {
      setTotalValue(props.payedValue);
      lastTotalValue.current = totalValue;
    }

    if (lastBankId.current !== bankId) {
      if (bankId === "-1") {
        setTerminalList([]);
      } else {
        const k = poses.poses.filter((m) => m.id === ~~bankId)[0].recPayPos;
        setTerminalList(k);
      }
      lastBankId.current = bankId;
    }
  }, [
    props.payedValue,
    bankId,
    totalValue,
    poses.poses,
    description,
    props.saleInvoiceId,
    checkPayedValue,
  ]);

  function newForm() {
    //وقتی دکمه ی جدید را میزنه یعنی ممکنه بخواد برای یک فاکتور دیگه پوز ثبت کنه برای همین مبلغ وارد شده را با مبلغ فاکتور چک نمیکنه
    setCheckPayedValue(false);
    setTotalValue(0);
    setBankId("-1");
    setTransaction(0);
    setTerminalID(-1);
    setDescription("");
  }

  function handleSaveCashPayPose() {
    var payedValue = totalValue;

    if (payedValue.toString().indexOf(",") > -1) {
      payedValue = payedValue.replace(/,/g, "");
    }
    var Errmsg = [];

    if (!payedValue) {
      Errmsg.push(<p>مبلغ را وارد نمایید</p>);
    } else if (
      parseFloat(payedValue) > parseFloat(props.payedValue) &&
      checkPayedValue
    ) {
      Errmsg.push(<p>مبلغ وارد شده از مبلغ فاکتور بیشتر است</p>);
    }
    if (bankId === ~~"-1") {
      Errmsg.push(<p>بانک را انتخاب نمایید!</p>);
    }
    if (terminalID === ~~"-1") {
      Errmsg.push(<p>دستگاه کارتخوان را انتخاب نمایید!</p>);
    }
    if (!transaction) {
      Errmsg.push(<p>شماره تراکنش را وارد نمایید</p>);
    } else if (!transaction.match(/^[0-9]+$/)) {
      Errmsg.push(<p>شماره تراکنش نامعتبر است ! شماره تراکنش عدد میباشد</p>);
    }

    if (Errmsg.length > 0) {
      setNotify({
        color: "error",
        hideDuratuion: 10000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const token = localStorage.token;
    if (token) {
      setIsLoading(true);
      const CashTurnDetail = {
        CashTurnId: ~~props.cashTurnId,
        FarsiTransactionDate: null,
        PayedValue: parseFloat(~~payedValue),
        ObjectId1: null,
        UserId: ~~userId,
        RelatedAccountId: null,
        formtypeid: 205,
        Description: description,
        ClassId1: 8,
        branchId: ~~props.branchId,
      };
      const newModel = {
        CashTurnDetail: CashTurnDetail,
        TerminalId: ~~terminalID,
        RRN: transaction,
      };
      axios
        .post(baseUrl + "Cash/SaveRecPayCashDetail", newModel, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        })
        .then(() => {
          setIsLoading(false);
          setNotify({
            color: "success",
            hideDuratuion: 9000,
            msg: "پرداخت از طریق کارتخوان با موفقیت انجام شد!",
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          newForm();
        })
        .catch((error) => {
          setIsLoading(false);
          var errMsg = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: "پرداخت از طریق کارتخوان" + errMsg,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <NotifyAlert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </NotifyAlert>
      </Snackbar>
      <Button size="sm" color="danger" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggle}>فرم پرداخت از طریق کارتخوان</ModalHeader>
        <ModalBody>
          {/* <Alert color={msg.class} className={msg.text === null ? "hidden" : ""}>
                        <div className="text-right">
                            {msg.text}
                        </div>
                    </Alert> */}

          <Row>
            <FormGroup className="col-lg-4">
              <Label for="totalValue" className="pull-right">
                مبلغ
                <span className="text-success pe-5 pull-left text-left">
                  {" "}
                  مبلغ قابل پرداخت =
                  <NumberFormat
                    value={payedValue}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              </Label>
              <NumberFormat
                max={payedValue}
                thousandSeparator={true}
                className="form-control"
                inputMode="numeric"
                value={totalValue}
                onChange={(e) => setTotalValue(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Label for="FirstName" className="pull-right">
                بانک{" "}
              </Label>
              <Input
                type="select"
                name="bankId"
                value={bankId}
                onChange={(e) => setBankId(e.target.value)}
              >
                <option value="-1">انتخاب نمایید</option>
                {poses.poses.map((gt) => {
                  return (
                    <option key={gt.id} value={gt.id}>
                      {gt.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Label for="terminalID" className="pull-right">
                {" "}
                دستگاه کارت خوان
              </Label>
              <Input
                type="select"
                name="terminalID"
                id="terminalID"
                value={terminalID}
                onChange={(e) => setTerminalID(e.target.value)}
              >
                <option value="-1">انتخاب نمایید</option>
                {terminalList.map((gt) => {
                  return <option value={gt.terminalID}>{gt.name}</option>;
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Label for="transaction" className="pull-right">
                تراکنش
              </Label>
              <Input
                type="text"
                name="transaction"
                id="transaction"
                value={transaction}
                onChange={(e) => setTransaction(e.target.value)}
              />
            </FormGroup>

            <FormGroup className="col-lg-8">
              <Label for="description" className="pull-right">
                توضیحات
              </Label>
              <Input
                type="text"
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Row>
          <RenderLoading loading={isLoading} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveCashPayPose}>
            {" "}
            ثبت
          </Button>{" "}
          <Button color="warning" onClick={newForm}>
            جدید
          </Button>
          <Button color="secondary" onClick={toggle}>
            انصراف
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

function RenderLoading(loading) {
  if (loading.loading) {
    return (
      <Row className="d-flex justify-content-center">
        <Col md={2}>
          <Loading></Loading>
        </Col>
      </Row>
    );
  } else return <></>;
}

export default ModalRecPayPos;
