import React, { useState } from "react";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { baseUrl } from "../../shared/appContact";
import { Loading } from "../LoadingComponent";

//#region for open modal Search with Enter + Shift
document.addEventListener("keypress", logKey);

function logKey(event) {
  if (event.key === "Enter" && event.shiftKey) {
    var el = document.getElementsByClassName("modalsearchProdBtn")[0];
    if (el) el.click();
  }
}
//#endregion
const ModalSearchGoods = (props) => {
  const { disabled, inventoryID, pricingMethodId, IsFormal, addGood } = props;

  //#region States
  const goodTypes = useSelector((state) => state.goodTypes);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [srchProductFilter, setSrchProductFilter] = useState({
    name: "",
    barcode: "",
    code: "",
    goodTypeId: 0,
    isStock: true,
    inventoryId: ~~inventoryID,
    pricingMethodId: ~~pricingMethodId,
    discountPercent: 0,
    qty: 0,
  });
  const [goodListResSrch, setGoodListResSrch] = useState([
    {
      barcode: "",
      code: "",
      name: "",
      retailPrice: 0,
      qty: 0,
      remained: 0,
      discountPercent: 0,
      discountValue: 0,
      totalTaxAddedValue: 0,
    },
  ]);
  //#endregion

  //#region Functions
  const toggle = () => setModal(!modal);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setSrchProductFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cancelTokenSource = axios.CancelToken.source();
  function handleProductSearch() {
    const token = localStorage.token;
    // debugger;
    if (token) {
      setLoading(true);
      let model = {
        ProductSearch: [srchProductFilter],
        IsFormal: IsFormal,
      };

      axios
        .post(baseUrl + "Basic/ProductSearch", model, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          // debugger;
          setLoading(false);
          if (response.data.length === 0) {
            alert("هیچ موردی یافت نشد");
            return;
          }
          setGoodListResSrch(response.data.result);
        })
        .catch((error) => {
          setLoading(false);
          setGoodListResSrch([]);
          if (axios.isCancel(error)) {
            alert("post Request canceled");
            return;
          }
          if (error?.message?.includes("404")) {
            alert("هیچ موردی یافت نشد");

            return;
          }
          alert(error.message);
          return;
        });
    }
  }
  const newSearch = () => {
    setSrchProductFilter({
      name: "",
      barcode: "",
      code: "",
      goodTypeId: -1,
      isStock: true,
      inventoryId: ~~inventoryID,
      pricingMethodId: ~~pricingMethodId,
      discountPercent: 0,
      qty: 0,
    });
    cancelTokenSource.cancel();
  };
  //#endregion
  return (
    <div>
      <Button
        color="info"
        size="sm"
        className={disabled ? "hidden" : "modalsearchProdBtn"}
        onClick={toggle}
      >
        جستجوی کالا
      </Button>
      <Modal
        className="modal-lg dir-rtl"
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggle}>جستجوی کالا</ModalHeader>
        <ModalBody>
          <Row className="text-right">
            <Form inline>
              <FormGroup className="mb-2 ms-sm-2 mb-sm-2">
                <Label htmlFor="name" className="ms-sm-2">
                  نام کالا
                </Label>
                <Input
                  bsSize="sm"
                  type="text"
                  id="name"
                  name="name"
                  value={srchProductFilter.name}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup className="mb-2 ms-sm-2 mb-sm-2">
                <Label htmlFor="barcode " className="ms-sm-2">
                  بارکد
                </Label>
                <Input
                  bsSize="sm"
                  type="text"
                  id="barcode"
                  name="barcode"
                  value={srchProductFilter.barcode}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup className="mb-2 ms-sm-2 mb-sm-2">
                <Label htmlFor="code" className="ms-sm-2">
                  کد کالا
                </Label>
                <Input
                  bsSize="sm"
                  type="text"
                  id="code"
                  name="code"
                  value={srchProductFilter.code}
                  onChange={handleInputChange}
                />
              </FormGroup>

              <FormGroup className="mb-2 ms-sm-2 mb-sm-2">
                <Label htmlFor="goodTypeId" className="ms-sm-2">
                  نوع کالا
                </Label>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setSrchProductFilter((prevState) => ({
                      ...prevState,
                      goodTypeId: newValue ? newValue.id : -1,
                    }));
                  }}
                  name="goodTypeId"
                  id="goodTypeId"
                  options={goodTypes.goodtypes}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 150 }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </FormGroup>
              <FormGroup check className="mb-2 ms-sm-2 mb-sm-2">
                <Label check>
                  موجود{" "}
                  <Input
                    bsSize="sm"
                    type="checkbox"
                    name="isStock"
                    id="isStock"
                    value={srchProductFilter.isStock}
                    onChange={handleInputChange}
                    checked={srchProductFilter.isStock}
                  />
                </Label>
              </FormGroup>
              <FormGroup className="mb-2 ms-sm-2 mb-sm-2">
                <div
                  color="primary"
                  size="sm"
                  className="btn w-100 btn-info m-3"
                  onClick={() => handleProductSearch()}
                >
                  بازیابی کالا
                </div>
              </FormGroup>
              <FormGroup className="mb-2 ms-sm-2 mb-sm-2">
                <div
                  color="seccess"
                  size="sm"
                  className="btn w-100 btn-info m-3"
                  onClick={() => newSearch()}
                >
                  جدید
                </div>
              </FormGroup>
            </Form>
          </Row>
          <Row>
            <Col md={12}>
              <ResultProductSearch
                addGood={addGood}
                loading={loading}
                goodListResSrch={goodListResSrch}
                toggle={toggle}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

function ResultProductSearch({ loading, goodListResSrch, addGood, toggle }) {
  if (loading) {
    return (
      <Row className="justify-content-md-center">
        <Col md={3}>
          <Loading />
        </Col>
      </Row>
    );
  }
  return (
    <Table className="mt-2" hover>
      <thead>
        <tr>
          <th>ردیف</th>
          <th>بارکد</th>
          <th>نام</th>
          <th>کد</th>
          <th>موجود</th>
          <th>قیمت</th>
          <th>درصد تخفیف</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {goodListResSrch.map((m, index) => {
          return (
            <tr
              key={index}
              className="pointer"
              onClick={() => {
                toggle();
                addGood(m, -1);
              }}
            >
              <td>{index + 1}</td>
              <td>{m.barcode}</td>
              <td>{m.name}</td>
              <td>{m.code}</td>
              <td>{m.remained}</td>
              <td>{m.retailPrice}</td>
              <td>{m.discountPercent} %</td>
              <td>
                <span className="btn w-100 btn-info"> انتخاب</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
export default ModalSearchGoods;
