import React from "react";
import "../src/style.css";
import Main from "./components/Main";
import { BrowserRouter } from "react-router-dom";
import { ActiveMenuProvider } from "./components/activeMenuContext";

const App = () => {
  return (
    <BrowserRouter>
      <ActiveMenuProvider>
        <Main className="App" />
      </ActiveMenuProvider>
    </BrowserRouter>
  );
};

export default App;
