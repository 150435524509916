import * as ActionTypes from '../ActionTypes';

export const Pricingmethod = (state = {
    isLoading: true,
    errMess: null,
    pricingmethod: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_PRICINGMETHOD:
            return { ...state, isLoading: false, errMess: null, pricingmethod: action.payload };

        case ActionTypes.PRICINGMETHOD_LOADING:
            return { ...state, isLoading: true, errMess: null, pricingmethod: [] }

        case ActionTypes.PRICINGMETHOD_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};