import * as ActionTypes from "./ActionTypes";
import { baseUrl, authPass, authUser } from "../shared/appContact";

export function ErrorMsg(errStatus) {
  var errmess = "";
  errStatus = parseInt(
    errStatus.replace(":", "").replace("Request failed with status code ", "")
  );
  switch (errStatus) {
    case 400:
      errmess = "خطا : 400-اطلاعات صحیح نمیباشد...";
      break;
    case 401:
      errmess = "خطا : 401-عدم احراز هویت،لطفا دوباره وارد سیستم شوید";
      break;
    case 403:
      errmess = "خطا: 403-شما به این قسمت دسترسی ندارید...";
      break;
    case 404:
      errmess = "خطا : 404-هیج موردی پیدا نشد...";
      break;
    case 405:
      errmess = "خطا : 405-عدم دسترسی به متد، با پشتبانی تماس بگیرید";
      break;
    case 406:
      errmess = "خطا : 406-غیر قابل قبول، هیچ موردی پیدا نشد...";
      break;
    case 407:
      errmess = "خطا : 407-احراز هویت پروکسی مورد نیاز است...";
      break;
    case 409:
      errmess = "خطا : 409-کاربر قبلا در سیستم عضو شده است...";
      break;
    case 500:
      errmess = "خطا : 500-خطا در انجام عملیات...";
      break;
    case 502:
      errmess = "حطا : 502-خطای سمت سرور...";
      break;
    default:
      errmess = "خطا در برقراری ارتباط...";
      break;
  }
  return errmess;
}

//#region Auth
export const postAuth = () => (dispatch) => {
  dispatch(AuthLoading(true));
  const newModel = {
    username: authUser,
    password: authPass,
  };
  return fetch(baseUrl + "auth/login", {
    method: "POST",
    body: JSON.stringify(newModel),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )

    .then((response) => response.json())
    .then((response) => {
      localStorage.setItem("token", response.token);
      dispatch(addAuth(response));
      //    dispatch(fetchComponyInfo())
      dispatch(fetchGoodTypes());
      dispatch(fetchPoses());
      dispatch(fetchSystemSetting());
      dispatch(fetchAccounts());
      dispatch(fetchOrgInfo());
    })
    .catch((error) => dispatch(AuthFailed(ErrorMsg(error.message))));
};
export const AuthFailed = (errmess) => ({
  type: ActionTypes.AUTH_FAILED,
  payload: errmess,
});

export const addAuth = (auth) => ({
  type: ActionTypes.ADD_AUTH,
  payload: auth,
});
export const AuthLoading = () => ({
  type: ActionTypes.AUTH_LOADING,
});

//#endregion

//#region login
export const postLogin = (UserName, Password) => (dispatch) => {
  const token = localStorage.token;

  dispatch(loginLoading(true));
  const newLogin = {
    UserName: UserName,
    Password: Password,
  };
  return fetch(baseUrl + "Basic/Login", {
    method: "POST",
    body: JSON.stringify(newLogin),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : undefined),
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(response.status + ": " + response.statusText);
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )

    .then((response) => response.json())
    .then((response) => {
      if (response.roles.length === 0) {
        dispatch(loginFailed("شما به هیچ سیستمی دسترسی ندارید"));
        return;
      }
      dispatch(addSystemGroups(response.systemGroups));
      dispatch(addRoles(response.roles));
      dispatch(addSalePrecondition(response.salePrecondition));
      dispatch(addUserInfo(response.userInfo));
      dispatch(fetchComponyInfo());
      dispatch(fetchInvoiceType(response.userInfo.userId));
      dispatch(fetchDefaultSetting(response.userInfo.userId));
      dispatch(fetchCashPerm(response.userInfo.userId));
      dispatch(fetchInventories(response.userInfo.userId));
      dispatch(fetchPricingMethod(response.userInfo.userId));
      dispatch(fetchBranches(response.userInfo.userId));
    })
    .catch((error) => dispatch(loginFailed(ErrorMsg(error.message))));
};

export const loginLoading = () => ({
  type: ActionTypes.SYSTEMGROUPS_LOADING,
});

export const loginFailed = (errmess) => ({
  type: ActionTypes.LOGIN_FAILED,
  payload: errmess,
});

//#endregion

//#region LogOut
export const PutlogOut = (sessionId) => (dispatch) => {
  const token = localStorage.token;

  return fetch(baseUrl + "Basic/LogOut/" + sessionId, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          localStorage.removeItem("token");
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .catch((error) => dispatch(logOutFailed(ErrorMsg(error.message))));
};

export const logOutFailed = (errmess) => ({
  type: ActionTypes.LOGOUT_FAILED,
  payload: errmess,
});

//#endregion

//#region  SYSTEM SETTING
export const fetchSystemSetting = () => (dispatch) => {
  const token = localStorage.token;

  dispatch(systemSettingLoading(true));
  return fetch(baseUrl + "Basic/GetSystemSetting", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((setting) => dispatch(addSystemSetting(setting)))
    .catch((error) => dispatch(systemSettingFailed(ErrorMsg(error.message))));
};
export const systemSettingFailed = (errmess) => ({
  type: ActionTypes.SYSTEMSETTING_FAILED,
  payload: errmess,
});

export const addSystemSetting = (setting) => ({
  type: ActionTypes.ADD_SYSTEMSETTING,
  payload: setting,
});
export const systemSettingLoading = () => ({
  type: ActionTypes.SYSTEMSETTING_LOADING,
});

//#endregion

//#region  UserInfo
export const userInfoFailed = (errmess) => ({
  type: ActionTypes.USERINFO_FAILED,
  payload: errmess,
});

export const addUserInfo = (userInfo) => ({
  type: ActionTypes.ADD_USERINFO,
  payload: userInfo,
});
export const userInfoLoading = () => ({
  type: ActionTypes.USERINFO_LOADING,
});

//#endregion

//#region  Roles
export const rolesFailed = (errmess) => ({
  type: ActionTypes.ROLES_FAILED,
  payload: errmess,
});

export const addRoles = (roles) => ({
  type: ActionTypes.ADD_ROLES,
  payload: roles,
});
export const rolesLoading = () => ({
  type: ActionTypes.ROLES_LOADING,
});

//#endregion

//#region SystemGroups

export const systemGroupsFailed = (errmess) => ({
  type: ActionTypes.SYSTEMGROUPS_FAILED,
  payload: errmess,
});

export const addSystemGroups = (systemGrops) => ({
  type: ActionTypes.ADD_SYSTEMGROUPS,
  payload: systemGrops,
});
export const systemGroupsLoading = () => ({
  type: ActionTypes.SYSTEMGROUPS_LOADING,
});

//#endregion

//#region InventoryUser
export const fetchInventories = (userId) => (dispatch) => {
  const token = localStorage.token;

  dispatch(inventoryUserLoading(true));
  return fetch(baseUrl + "Inventory/GetInventory/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((Inventory) => dispatch(addInventoryUser(Inventory)))
    .catch((error) => dispatch(InventoryUserFailed(ErrorMsg(error.message))));
};

export const InventoryUserFailed = (errmess) => ({
  type: ActionTypes.INVENTORYUSER_FAILED,
  payload: errmess,
});

export const addInventoryUser = (inventory) => ({
  type: ActionTypes.ADD_INVENTORYUSER,
  payload: inventory,
});
export const inventoryUserLoading = () => ({
  type: ActionTypes.INVENTORYUSER_LOADING,
});
//#endregion

//#region GetDefaultSetting
export const fetchDefaultSetting = (userId) => (dispatch) => {
  const token = localStorage.token;

  dispatch(DefaultOrgUserLoading(true));
  return fetch(baseUrl + "Basic/GetDefaultSetting/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      console.log({ response: response.defaultPromoterUser });
      dispatch(addDefaultOrgUserUser(response.defaultOrgUser));
      dispatch(addDefaultPoromterUser(response.defaultPromoterUser));
      dispatch(addDefaultIsFormal(!!+response?.defaultIsFormal?.value));
      dispatch(addDefaultIsCash(!!+response?.defaultIsCash?.value));
    })
    .catch((error) => dispatch(DefaultOrgUserLoading(ErrorMsg(error.message))));
};
//#endregion

//#region DefaultOrgUser

export const DefaultOrgUserFailed = (errmess) => ({
  type: ActionTypes.DEFAULTORGUSER_FAILED,
  payload: errmess,
});

export const addDefaultOrgUserUser = (orgUser) => ({
  type: ActionTypes.ADD_DEFAULTORGUSER,
  payload: orgUser,
});
export const DefaultOrgUserLoading = () => ({
  type: ActionTypes.DEFAULTORGUSER_LOADING,
});
//#endregion

//#region DefaultPoromterUser

export const DefaultPoromterUserFailed = (errmess) => ({
  type: ActionTypes.DEFAULTPROMETERUSER_FAILED,
  payload: errmess,
});

export const addDefaultPoromterUser = (DefaultPoromterUser) => ({
  type: ActionTypes.ADD_DEFAULTPROMETERUSER,
  payload: DefaultPoromterUser,
});

export const DefaultPoromterUserLoading = () => ({
  type: ActionTypes.DEFAULTPROMETERUSER_LOADING,
});

//#endregion

export const addDefaultIsFormal = (DefaultIsFormal) => ({
  type: ActionTypes.ADD_DEFAULT_IS_FORMAL,
  payload: DefaultIsFormal,
});

export const addDefaultIsCash = (DefaultIsCash) => ({
  type: ActionTypes.ADD_DEFAULT_IS_CASH,
  payload: DefaultIsCash,
});

//#region PricingMethod
export const fetchPricingMethod = (userId) => (dispatch) => {
  const token = localStorage.token;

  dispatch(pricingMethodLoading(true));
  return fetch(baseUrl + "Basic/GetPricingMethod/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((branches) => dispatch(addPricingMethod(branches)))
    .catch((error) => dispatch(PricingMethodFailed(ErrorMsg(error.message))));
};

export const PricingMethodFailed = (errmess) => ({
  type: ActionTypes.PRICINGMETHOD_FAILED,
  payload: errmess,
});

export const addPricingMethod = (pricingMethod) => ({
  type: ActionTypes.ADD_PRICINGMETHOD,
  payload: pricingMethod,
});
export const pricingMethodLoading = () => ({
  type: ActionTypes.PRICINGMETHOD_LOADING,
});
//#endregion

//#region SalePrecondition

export const SalePreconditionFailed = (errmess) => ({
  type: ActionTypes.SALEPRECONDITION_FAILED,
  payload: errmess,
});

export const addSalePrecondition = (salePrecondition) => ({
  type: ActionTypes.ADD_SALEPRECONDITION,
  payload: salePrecondition,
});

export const SalePreconditionLoading = () => ({
  type: ActionTypes.SALEPRECONDITION_LOADING,
});
//#endregion

//#region Branches
export const fetchBranches = (userId) => (dispatch) => {
  const token = localStorage.token;

  dispatch(BranchLoading(true));
  return fetch(baseUrl + "Basic/GetBranches/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((branches) => dispatch(addBranch(branches)))
    .catch((error) => dispatch(BranchesFailed(ErrorMsg(error.message))));
};

export const BranchesFailed = (errmess) => ({
  type: ActionTypes.BRANCHES_FAILED,
  payload: errmess,
});

export const addBranch = (Branch) => ({
  type: ActionTypes.ADD_BRANCHES,
  payload: Branch,
});

export const BranchLoading = () => ({
  type: ActionTypes.BRANCHES_LOADING,
});
//#endregion

//#region GoodTypes
export const fetchGoodTypes = () => (dispatch) => {
  const token = localStorage.token;

  dispatch(goodTypesLoading(true));
  return fetch(baseUrl + "Basic/GetGoodTypes", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((goodTypes) => dispatch(addGoodTypes(goodTypes)))
    .catch((error) => dispatch(goodTypesFailed(ErrorMsg(error.message))));
};
export const goodTypesFailed = (errmess) => ({
  type: ActionTypes.GOODTYPES_FAILED,
  payload: errmess,
});

export const addGoodTypes = (goodTypes) => ({
  type: ActionTypes.ADD_GOODTYPES,
  payload: goodTypes,
});

export const goodTypesLoading = () => ({
  type: ActionTypes.GOODTYPES_LOADING,
});
//#endregion

//#region CashPerm
export const fetchCashPerm = (userId) => (dispatch) => {
  const token = localStorage.token;

  dispatch(BranchLoading(true));
  return fetch(baseUrl + "Cash/GetCashInfo/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => {
      dispatch(addCashPerm(response.cashPermission.cashes));
      dispatch(addcashInfo(response.cashPermission.openCash));
    })
    .catch((error) => dispatch(cashPermFailed(ErrorMsg(error.message))));
};

export const cashPermFailed = (errmess) => ({
  type: ActionTypes.CASHPERM_FAILED,
  payload: errmess,
});

export const addCashPerm = (cashPerm) => ({
  type: ActionTypes.ADD_CASHPERM,
  payload: cashPerm,
});

export const cashPermLoading = () => ({
  type: ActionTypes.CASHPERM_LOADING,
});
//#endregion

//#region CashInfo

export const cashInfoFailed = (errmess) => ({
  type: ActionTypes.CASHINFO_FAILED,
  payload: errmess,
});

export const addcashInfo = (cashInfo) => ({
  type: ActionTypes.ADD_CASHINFO,
  payload: cashInfo,
});

export const cashInfoLoading = () => ({
  type: ActionTypes.CASHINFO_LOADING,
});
//#endregion

//#region SaleInvoiceSreach

// export const postSaleInvoiceSearch = (
//     PageNumber,
//     PageSize,
//     IsDefault,
//     Filters
// ) => (dispatch) => {

//     const token = localStorage.token;
//     dispatch(SaleInvoiceSearchLoading(true));
//     const newModel = {
//         PageNumber: PageNumber,
//         PageSize: PageSize,
//         IsDefault: IsDefault,
//         Filters: {
//             FromId: ~~Filters.FromId,
//             ToId: ~~Filters.ToId,
//             FromCode: Filters.FromCode,
//             ToCode: Filters.ToCode,
//             FromFarsiDate: Filters.FromFarsiDate,
//             ToFarsiDate: Filters.ToFarsiDate,
//             CreatorId: ~~Filters.CreatorId,
//             CustomerId: ~~Filters.CustomerId,
//             PricingMethodId: ~~Filters.PricingMethodId,
//             Notes: Filters.Notes,
//             SumQty: ~~Filters.SumQty,
//             RowDiscountValue: -1,
//             DiscountPercent: ~~Filters.DiscountPercent,
//             DiscountValue: ~~Filters.DiscountValue,
//             FromTotalValue: ~~Filters.FromTotalValue,
//             ToTotalValue: ~~Filters.ToTotalValue,
//             FromCalcTotalValue: ~~Filters.FromCalcTotalValue,
//             ToCalcTotalValue: -1,
//             PreConditionId: -1,
//             IsTemprory: Filters.IsTemprory === "null" ? null : (Filters.IsTemprory === "true" ? true : false)
//         }
//     }
//     return fetch(baseUrl + 'Sale/SaleInvoiceSearch', {
//         method: 'POST',
//         body: JSON.stringify(newModel),
//         headers: {
//             'Content-Type': 'application/json',
//             Accept: 'application/json',
//             'Authorization': `Bearer ${token}`
//         },
//         credentials: 'same-origin'
//     })
//         .then(response => {
//             if (response.ok) {
//                 return response;
//             }

//             else {
//                 var error = new Error('Error ' + response.status + ': ' + response.statusText);
//                 error.response = response;
//                 throw error;
//             }
//         },
//             error => {
//                 var errmess = new Error(error.message);
//                 throw errmess;
//             })

//         .then(response => response.json())
//         .then(response => {
//             dispatch(addSaleInvoiceSearch(response))
//         })
//         .catch(error => dispatch(SaleInvoiceSearchFailed(ErrorMsg(error.message))));

// }
export const SaleInvoiceSearchFailed = (errmess) => ({
  type: ActionTypes.SALEINVOICESRCHRES_FAILED,
  payload: errmess,
});

export const addSaleInvoiceSearch = (res) => ({
  type: ActionTypes.ADD_SALEINVOICESRCHRES,
  payload: res,
});

export const SaleInvoiceSearchLoading = () => ({
  type: ActionTypes.SALEINVOICESRCHRES_LOADING,
});
//#endregion

//#region ComponyInfo
export const fetchComponyInfo = () => (dispatch) => {
  const token = localStorage.token;

  dispatch(ComponyInfoLoading(true));
  return fetch(baseUrl + "Basic/GetCompanyInfo", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((ComponyInfo) => {
      dispatch(addComponyInfo(ComponyInfo));
    })
    .catch((error) => dispatch(ComponyInfoFailed(ErrorMsg(error.message))));
};
export const ComponyInfoFailed = (errmess) => ({
  type: ActionTypes.COMPINFO_FAILED,
  payload: errmess,
});

export const addComponyInfo = (ComponyInfo) => ({
  type: ActionTypes.ADD_COMPINFO,
  payload: ComponyInfo,
});

export const ComponyInfoLoading = () => ({
  type: ActionTypes.COMPINFO_LOADING,
});

//#endregion

//#region GetInvoiceType

export const fetchInvoiceType = (userId) => (dispatch) => {
  const token = localStorage.token;
  dispatch(ComponyInfoLoading(true));
  return fetch(baseUrl + "Sale/GetInvoiceType/" + userId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((response) => dispatch(addInvoiceType(response.invoiceTypes)))
    .catch((error) => dispatch(InvoiceTypeFailed(ErrorMsg(error.message))));
};
export const InvoiceTypeFailed = (errmess) => ({
  type: ActionTypes.INVOICETYPE_FAILED,
  payload: errmess,
});

export const addInvoiceType = (InvoiceType) => ({
  type: ActionTypes.ADD_INVOICETYPE,
  payload: InvoiceType,
});

export const InvoiceTypeLoading = () => ({
  type: ActionTypes.INVOICETYPE_LOADING,
});
//#endregion

//#region POSES
export const fetchPoses = () => (dispatch) => {
  const token = localStorage.token;

  dispatch(PosesLoading(true));
  return fetch(baseUrl + "Cash/GetPoses", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((Poses) => dispatch(addPoses(Poses)))
    .catch((error) => dispatch(PosesFailed(ErrorMsg(error.message))));
};
export const PosesFailed = (errmess) => ({
  type: ActionTypes.POSES_FAILED,
  payload: errmess,
});

export const addPoses = (Poses) => ({
  type: ActionTypes.ADD_POSES,
  payload: Poses,
});

export const PosesLoading = () => ({
  type: ActionTypes.POSES_LOADING,
});

//#endregion

//#region ACCOUNTS
export const fetchAccounts = () => (dispatch) => {
  const token = localStorage.token;

  dispatch(AccountsLoading(true));
  return fetch(baseUrl + "AccountDocument/GetAccounts", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((Accounts) => {
      dispatch(addAccounts(Accounts));
    })
    .catch((error) => dispatch(AccountsFailed(ErrorMsg(error.message))));
};
export const AccountsFailed = (errmess) => ({
  type: ActionTypes.ACCOUNTS_FAILED,
  payload: errmess,
});

export const addAccounts = (Accounts) => ({
  type: ActionTypes.ADD_ACCOUNTS,
  payload: Accounts,
});

export const AccountsLoading = () => ({
  type: ActionTypes.ACCOUNTS_LOADING,
});

//#endregion

//#region orginfo
export const fetchOrgInfo = () => (dispatch) => {
  const token = localStorage.token;

  dispatch(OrginfoLoading(true));
  return fetch(baseUrl + "Basic/GetOrgInfo", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "same-origin",
  })
    .then(
      (response) => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((response) => response.json())
    .then((info) => {
      dispatch(addOrginfo(info));
    })
    .catch((error) => dispatch(OrginfoFailed(ErrorMsg(error.message))));
};
export const OrginfoFailed = (errmess) => ({
  type: ActionTypes.ORGINFO_FAILED,
  payload: errmess,
});

export const addOrginfo = (info) => ({
  type: ActionTypes.ADD_ORGINFO,
  payload: info,
});

export const OrginfoLoading = () => ({
  type: ActionTypes.ORGINFO_LOADING,
});

//#endregion
