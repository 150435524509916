import * as ActionTypes from '../ActionTypes';

export const GoodTypes = (state = {
    isLoading: true,
    errMess: null,
    goodtypes: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_GOODTYPES:
            return { ...state, isLoading: false, errMess: null, goodtypes: action.payload };

        case ActionTypes.GOODTYPES_LOADING:
            return { ...state, isLoading: true, errMess: null, goodtypes: [] }

        case ActionTypes.GOODTYPES_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};