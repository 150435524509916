import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input,
  Label,
  CardBody,
  FormGroup,
} from "reactstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { PersianCalender } from "../persianCalender";
import { Link } from "react-router-dom";
import { baseUrl } from "../../shared/appContact";
import { ErrorMsg } from "../../redux/ActionCreators";
import { PersianToday } from "../../shared/persianToday";
import { Loading } from "../LoadingComponent";
function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}
export default function Emploee(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const orgInfo = useSelector((state) => state.orgInfo);

  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [showBaseInfo, setShowBaseInfo] = useState(true);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [emp, setEmp] = useState({
    id: 0,
    code: "",
    identificationCode: "",
    firstName: "",
    sureName: "",
    fatherName: "",
    ssn: "",
    farsiBirthDate: "",
    birthLocationId: -1,
    farsiIssueDate: "",
    issueLocationId: -1,
    nationalCode: "",
    nationalityId: -1,
    phone: "",
    address: "",
    email: "",
    isMale: true,
    housingTypeId: -1,
    marriagetypeId: -1,
    childrenNum: 0,
    transportTypeId: -1,
    workStatusId: -1,
    MilitaryServiceId: -1,
    educationLevelId: -1,
    educationFieldId: -1,
    bankAccountNumber: "",
    userId: -1,
    jobId: -1,
    jobBaseId: -1,
    jobBranchId: -1,
    jobCategoryId: -1,
    jobClassId: -1,
    jobGroupId: -1,
    rankId: 0,
    isLostLife: false,
    bankId: -1,
    parentId: -1,
    preSalaryTypeId: -1,
    accountBranchId: -1,
    pathId: -1,
    canBePromoter: false,
    pricingMethodId: -1,
    tag: "",
    farsiLeaveDate: "",
    farsiStartDate: "",
    isTreatmentLeave: false,
    postalCode: "",
    incomingSalePercent: 0,
    cellPhone: "",
    deviceId: "",
  });
  //handle Change emp
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setEmp((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const newEmp = () => {
    setEmp({
      id: 0,
      code: "",
      identificationCode: "",
      firstName: "",
      sureName: "",
      fatherName: "",
      ssn: "",
      farsiBirthDate: "",
      birthLocationId: -1,
      farsiIssueDate: "",
      issueLocationId: -1,
      nationalCode: "",
      nationalityId: -1,
      phone: "",
      address: "",
      email: "",
      isMale: true,
      housingTypeId: -1,
      marriagetypeId: -1,
      childrenNum: 0,
      transportTypeId: -1,
      workStatusId: -1,
      MilitaryServiceId: -1,
      educationLevelId: -1,
      educationFieldId: -1,
      bankAccountNumber: "",
      userId: -1,
      jobId: -1,
      jobBaseId: -1,
      jobBranchId: -1,
      jobCategoryId: -1,
      jobClassId: -1,
      jobGroupId: -1,
      rankId: 0,
      isLostLife: false,
      bankId: -1,
      parentId: -1,
      preSalaryTypeId: -1,
      accountBranchId: -1,
      pathId: -1,
      canBePromoter: false,
      pricingMethodId: -1,
      tag: "",
      farsiLeaveDate: "",
      farsiStartDate: "",
      isTreatmentLeave: false,
      postalCode: "",
      incomingSalePercent: 0,
      cellPhone: "",
      deviceId: "",
    });
  };
  function handleSaveEmploee() {
    var Errmsg = [""];
    if (!emp.code) {
      Errmsg.push(<p>کد را وارد نمایید !</p>);
    }

    if (!emp.firstName) {
      Errmsg.push(<p>نام را وارد نمایید !</p>);
    }
    if (!emp.sureName) {
      Errmsg.push(<p>نام خانوادگی را وارد نمایید !</p>);
    }
    if (!emp.fatherName) {
      Errmsg.push(<p>نام پدر را وارد نمایید !</p>);
    }
    if (!emp.nationalCode) {
      Errmsg.push(<p>کد ملی را وارد نمایید !</p>);
    } else if (!/^\d{10}$/.test(emp.nationalCode)) {
      Errmsg.push(<p>کد ملی صحیح نمیباشد !</p>);
    }
    if (emp.phone === "") {
      Errmsg.push(<p>تلفن را وارد نمایید</p>);
    }
    if (!emp.cellPhone.match(/^[0-9]+$/) || emp.cellPhone.length !== 11) {
      Errmsg.push(<p>تلفن همراه را بدرستی وارد نمایید</p>);
    }

    if (Errmsg.length > 1) {
      setNotify({
        color: "error",
        hideDuratuion: 9000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });

      return;
    }
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const newModel = {
        emploee: {
          code: emp.code,
          identificationCode:
            emp.identificationCode === "" ? null : emp.identificationCode,
          firstName: emp.firstName,
          sureName: emp.sureName,
          fatherName: emp.fatherName,
          ssn: emp.ssn === "" ? null : emp.ssn,
          farsiBirthDate: emp.farsiBirthDate,
          birthLocationId:
            ~~emp.birthLocationId === -1 ? null : ~~emp.birthLocationId,
          farsiIssueDate: emp.farsiIssueDate,
          issueLocationId:
            ~~emp.issueLocationId === -1 ? null : ~~emp.issueLocationId,
          nationalCode: emp.nationalCode === "" ? null : emp.nationalCode,
          nationalityId:
            ~~emp.nationalityId === -1 ? null : ~~emp.nationalityId,
          phone: emp.phone === "" ? null : emp.phone,
          address: emp.address === "" ? null : emp.address,
          email: emp.email === "" ? null : emp.email,
          isMale: emp.isMale,
          housingTypeId:
            ~~emp.housingTypeId === -1 ? null : ~~emp.housingTypeId,
          marriagetypeId:
            ~~emp.marriagetypeId === -1 ? null : ~~emp.marriagetypeId,
          childrenNum: ~~emp.childrenNum,
          transportTypeId:
            ~~emp.transportTypeId === -1 ? null : ~~emp.transportTypeId,
          workStatusId: ~~emp.workStatusId === -1 ? null : ~~emp.workStatusId,
          MilitaryServiceId:
            ~~emp.MilitaryServiceId === -1 ? null : ~~emp.MilitaryServiceId,
          educationLevelId:
            ~~emp.educationLevelId === -1 ? null : ~~emp.educationLevelId,
          educationFieldId:
            ~~emp.educationFieldId === -1 ? null : ~~emp.educationFieldId,
          bankAccountNumber:
            emp.bankAccountNumber === "" ? null : emp.bankAccountNumber,
          userId: ~~emp.userId === -1 ? null : ~~emp.userId,
          jobId: ~~emp.jobId === -1 ? null : ~~emp.jobId,
          jobBaseId: ~~emp.jobBaseId === -1 ? null : ~~emp.jobBaseId,
          jobBranchId: ~~emp.jobBranchId === -1 ? null : ~~emp.jobBranchId,
          jobCategoryId:
            ~~emp.jobCategoryId === -1 ? null : ~~emp.jobCategoryId,
          jobClassId: ~~emp.jobClassId === -1 ? null : ~~emp.jobClassId,
          jobGroupId: ~~emp.jobGroupId === -1 ? null : ~~emp.jobGroupId,
          rankId: ~~emp.rankId === -1 ? null : ~~emp.rankId,
          isLostLife: emp.isLostLife,
          bankId: ~~emp.bankId === -1 ? null : ~~emp.bankId,
          parentId: ~~emp.parentId === -1 ? null : ~~emp.parentId,
          preSalaryTypeId:
            ~~emp.preSalaryTypeId === -1 ? null : ~~emp.preSalaryTypeId,
          accountBranchId:
            ~~emp.accountBranchId === -1 ? null : ~~emp.accountBranchId,
          pathId: ~~emp.pathId === -1 ? null : ~~emp.pathId,
          canBePromoter: emp.canBePromoter,
          pricingMethodId:
            ~~emp.pricingMethodId === -1 ? null : ~~emp.pricingMethodId,
          tag: emp.tag === "" ? null : emp.tag,
          farsiLeaveDate: emp.farsiLeaveDate,
          farsiStartDate: emp.farsiStartDate,
          isTreatmentLeave: emp.isTreatmentLeave,
          postalCode: emp.postalCode === "" ? null : emp.postalCode,
          incomingSalePercent: ~~emp.incomingSalePercent,
          cellPhone: emp.cellPhone === "" ? null : emp.cellPhone,
          deviceId: null,
        },
      };
      fetch(baseUrl + "Basic/SaveEmploee", {
        method: "POST",
        body: JSON.stringify(newModel),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          if (response.message !== "") {
            setNotify({
              color: "error",
              hideDuratuion: 9000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //have user by this cellphone
          else {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات ثبت با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            newEmp();
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <NotifyAlert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </NotifyAlert>
      </Snackbar>

      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/1">اطلاعات پایه</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>کارمندان</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid={true}>
        <Card>
          <CardHeader>
            <strong>کارمندان</strong>
            <Col md={4} sm={10} lg={2} className="order-8 order-lg-6"></Col>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowBaseInfo(!showBaseInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showBaseInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات اصلی
                </div>
              </Col>
            </Row>
            <Row className={showBaseInfo ? "" : "hidden"}>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شماره رایانه ای</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="creator"
                    id="creator"
                    value={orgInfo.orgInfo.emploeeID + 1}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="code"
                    id="code"
                    value={emp.code}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="firstName"
                    id="firstName"
                    valid={emp.firstName !== ""}
                    invalid={emp.firstName === ""}
                    value={emp.firstName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام خانوادگی *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="sureName"
                    id="sureName"
                    value={emp.sureName}
                    valid={emp.sureName !== ""}
                    invalid={emp.sureName === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام پدر </Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="fatherName"
                    id="fatherName"
                    valid={emp.fatherName !== ""}
                    invalid={emp.fatherName === ""}
                    value={emp.fatherName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> شماره شناسنامه </Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="ssn"
                    id="sssn"
                    value={emp.ssn}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ تولد</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(farsiBirthDate) => {
                      setEmp((prevState) => ({
                        ...prevState,
                        farsiBirthDate: farsiBirthDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>محل تولد</Label>
                  <Input
                    type="select"
                    name="birthLocationId"
                    value={emp.birthLocationId}
                    id="birthLocationId"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.externalLocation.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ صدور</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(farsiIssueDate) => {
                      setEmp((prevState) => ({
                        ...prevState,
                        farsiIssueDate: farsiIssueDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>محل صدور</Label>
                  <Input
                    type="select"
                    name="issueLocationId"
                    value={emp.issueLocationId}
                    id="issueLocationId"
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید... </option>
                    {orgInfo.orgInfo.externalLocation.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> کد ملی *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="nationalCode"
                    id="nationalCode"
                    valid={emp.nationalCode !== ""}
                    invalid={emp.nationalCode === ""}
                    value={emp.nationalCode}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> ملیت </Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="nationalityId"
                    id="nationalityId"
                    value={emp.nationalityId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.national.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> تلفن </Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="phone"
                    id="phone"
                    value={emp.phone}
                    valid={emp.phone !== ""}
                    invalid={emp.phone === ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> تلفن همراه</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="cellPhone"
                    id="cellPhone"
                    valid={
                      emp.cellPhone.match(/^[0-9]+$/) &&
                      emp.cellPhone.length === 11
                    }
                    invalid={
                      !emp.cellPhone.match(/^[0-9]+$/) ||
                      emp.cellPhone.length !== 11
                    }
                    value={emp.cellPhone}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>پست الکترونیک</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="email"
                    id="email"
                    value={emp.email}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> </Label>
                  <div>
                    <FormGroup check className="pull-right">
                      <Label>
                        مذکر{" "}
                        <Input
                          bsSize="sm"
                          disabled={disabled}
                          type="checkbox"
                          name="isMale"
                          id="isMale"
                          value={emp.isMale}
                          onChange={handleInputChange}
                          checked={emp.isMale}
                        />
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> وضعیت مسکن</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="housingTypeId"
                    id="housingTypeId"
                    value={emp.housingTypeId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.housingType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> وضعیت تاهل</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="marriagetypeId"
                    id="marriagetypeId"
                    value={emp.marriagetypeId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.marriageType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تعداد فرزندان</Label>
                  <Input
                    bsSize="sm"
                    type="number"
                    name="childrenNum"
                    id="childrenNum"
                    value={emp.childrenNum}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نحوه رفت و آمد</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="transportTypeId"
                    id="transportTypeId"
                    value={emp.transportTypeId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.transportType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>آدرس</Label>
                  <Input
                    type="textarea"
                    name="address"
                    value={emp.address}
                    id="address"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowMoreInfo(!showMoreInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showMoreInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات پرسنلی
                </div>
              </Col>
            </Row>
            <Row className={showMoreInfo ? "" : "hidden"}>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد پرسنلی</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="identificationCode"
                    id="identificationCode"
                    value={emp.identificationCode}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>وضعیت کار</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="workStatusId"
                    id="workStatusId"
                    value={emp.workStatusId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.workStatus.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>وضعیت نظام وظیفه</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="MilitaryServiceId"
                    id="MilitaryServiceId"
                    value={emp.MilitaryServiceId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.militaryServiceType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تحصیلات</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="educationLevelId"
                    id="educationLevelId"
                    value={emp.educationLevelId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.hreducationLevelType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>رشته تحصیلی</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="educationFieldId"
                    id="educationFieldId"
                    value={emp.educationFieldId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.educationFieldType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شماره حساب بانکی</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="bankAccountNumber"
                    id="bankAccountNumber"
                    value={emp.bankAccountNumber}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کاربر</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="userId"
                    id="userId"
                    value={emp.userId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.user.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.username}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شغل</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="jobId"
                    id="jobId"
                    value={emp.jobId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.jobs.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>پایه شغلی</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="jobBaseId"
                    id="jobBaseId"
                    value={emp.jobBaseId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.jobBase.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>رشته شغلی</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="jobBranchId"
                    id="jobBranchId"
                    value={emp.jobBranchId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.jobBranch.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>طبقه شغلی</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="jobCategoryId"
                    id="jobCategoryId"
                    value={emp.jobCategoryId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.jobCategory.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>رسته شغلی</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="jobClassId"
                    id="jobClassId"
                    value={emp.jobClassId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.jobClass.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>گروه شغلی</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="jobGroupId"
                    id="jobGroupId"
                    value={emp.jobGroupId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.jobGroup.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>رتبه</Label>
                  <Input
                    type="text"
                    name="rankId"
                    value={emp.rankId}
                    id="rankId"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <div>
                  <FormGroup check className="pull-right">
                    <Label>
                      جانباز{" "}
                      <Input
                        bsSize="sm"
                        disabled={disabled}
                        type="checkbox"
                        name="isLostLife"
                        id="isLostLife"
                        value={emp.isLostLife}
                        onChange={handleInputChange}
                        checked={emp.isLostLife}
                      />
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> بانک</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="bankId"
                    id="bankId"
                    value={emp.bankId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.hrBank.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> کارمند مادر</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="parentId"
                    id="parentId"
                    value={emp.parentId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.parent.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.firstName + " " + item.SureName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> نوع مساعده</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="preSalaryTypeId"
                    id="preSalaryTypeId"
                    value={emp.preSalaryTypeId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.salaryType.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> شعبه</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="accountBranchId"
                    id="accountBranchId"
                    value={emp.accountBranchId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.branches.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> مسیر</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="pathId"
                    id="pathId"
                    value={emp.pathId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.path.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <div>
                  <FormGroup check className="pull-right">
                    <Label>
                      مجاز به بازاریاب بودن{" "}
                      <Input
                        bsSize="sm"
                        disabled={disabled}
                        type="checkbox"
                        name="canBePromoter"
                        id="canBePromoter"
                        value={emp.canBePromoter}
                        onChange={handleInputChange}
                        checked={emp.canBePromoter}
                      />
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label> گروه قیمت گذاری</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    name="pricingMethodId"
                    id="pricingMethodId"
                    value={emp.pricingMethodId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">انتخاب نمایید...</option>
                    {orgInfo.orgInfo.pricingMethod.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>برچسب</Label>
                  <Input
                    type="text"
                    name="tag"
                    value={emp.tag}
                    id="tag"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ ترک کار</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(farsiLeaveDate) => {
                      setEmp((prevState) => ({
                        ...prevState,
                        farsiLeaveDate: farsiLeaveDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ شروع کار</Label>
                  <PersianCalender
                    defaultValue={null}
                    onChange={(farsiStartDate) => {
                      setEmp((prevState) => ({
                        ...prevState,
                        farsiStartDate: farsiStartDate,
                      }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <div>
                  <FormGroup check className="pull-right">
                    <Label>
                      مرخصی استعلاجی{" "}
                      <Input
                        bsSize="sm"
                        disabled={disabled}
                        type="checkbox"
                        name="isTreatmentLeave"
                        id="isTreatmentLeave"
                        value={emp.isTreatmentLeave}
                        onChange={handleInputChange}
                        checked={emp.isTreatmentLeave}
                      />
                    </Label>
                  </FormGroup>
                </div>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>کد پستی</Label>
                  <Input
                    type="text"
                    name="postalCode"
                    value={emp.postalCode}
                    id="postalCode"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>درصد سهم از فروش</Label>
                  <Input
                    type="number"
                    name="incomingSalePercent"
                    value={emp.incomingSalePercent}
                    id="incomingSalePercent"
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Button
                color="success"
                className="m-3"
                onClick={handleSaveEmploee}
              >
                ثبت
              </Button>

              <Button color="info" className="m-3" onClick={newEmp}>
                جدید
              </Button>
            </Row>
            <Row>
              <RenderLoading isLoading={loading} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
