import React from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ProgressBars from './progress';
import { Chart } from 'react-charts';
const LineChart = () => {
  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [
          { primary: 1, secondary: 10 },
          { primary: 2, secondary: 150 },
          { primary: 3, secondary: 110 },
          { primary: 4, secondary: 120 },
          { primary: 5, secondary: 130 },
        ],
      },
      {
        label: 'Series 2',
        data: [
          { primary: 1, secondary: 50 },
          { primary: 2, secondary: 350 },
          { primary: 3, secondary: 310 },
          { primary: 4, secondary: 210 },
          { primary: 5, secondary: 260 },
        ],
      },
      {
        label: 'Series 3',
        data: [
          { primary: 1, secondary: 120 },
          { primary: 2, secondary: 130 },
          { primary: 3, secondary: 210 },
          { primary: 4, secondary: 110 },
          { primary: 5, secondary: 190 },
        ],
      },
    ],
    []
  )
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' },
    ],
    []
  )

  return (

    <Container fluid={true}>
      <Card>
        <CardHeader>
          <strong>گزارش آخرین فعالیت ها</strong>
        </CardHeader>
        <CardBody className="text-right">
          <Row>
            <Col md={4}>
              <ProgressBars />
            </Col>
            <Col md={8}>
              <div
                style={{
                  width: '100%',
                  height: '300px',
                }}
              >
                <Chart data={data} axes={axes} />
              </div >
            </Col>

          </Row>
        </CardBody>

      </Card>
    </Container>

  )
}

export default LineChart;