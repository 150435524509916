import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import LineChart from './dashboard/lineChart/index';
import LastEvents from './dashboard/lastEvents';
import { Loading } from './LoadingComponent';
import BarChart from './dashboard/barChart';
import CustomizedSnackbars from './Tools/snackBar';

const Home = props => {
    if (props.roles.isLoading) {
        return (
            <div className="continer">
                <div className="row">
                    <Loading></Loading>
                </div>
            </div>
        );
    }
    else if (props.roles.errMess) {
        return (
            <div className="continer">
                <div className="row">
                    <h4>{props.roles.errMess}</h4>
                </div>
            </div>
        )
    }
    else
        return (
            <Container fluid={true}>
                <Row>
                    <Col md={12} lg={12} className="mb-5">
                        <CustomizedSnackbars />
                        <LineChart />
                    </Col>
                    <Col md={6} lg={6}> 
                        <LastEvents />
                    </Col>
                    <Col md={6} lg={6}>
                        {/* <BarChart /> */}
                    </Col>
                </Row>
            </Container>
        );

}
export default Home;