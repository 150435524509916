import { env } from "../env"
console.log({ env })
// export const baseUrl = 'http://api.jahanaraco.com/api/';
//export const baseUrl = 'https://api2.hamyaransystem.com/api/';
//export const baseUrl = 'http://192.168.100.10:8282/api/';//helium
//export const baseUrl = 'http://172.16.1.144:44396/api/';//hasani pc
// export const baseUrl = "https://localhost:44396/api/"; //hasani pc
// export const baseUrl = "http://armanapi.snowtower.ir:80/api/";
export const baseUrl = env.NODE_ENV === "development" ? env.REACT_APP_DEV_BASE_URL : env.REACT_APP_PROD_BASE_URL
//export const baseUrl = 'https://api.fani.hamyaransystem.com/api/';//razavi

//#region version
export const version = "v 1.1.7";

//#region api for report
//export const reportUrl = 'http://api.jahanaraco.com/api/report';//win1
//export const reportUrl = 'http://172.16.1.144:44396/api/report';//win1
export const reportUrl = "https://localhost:44396/api/report"; //helium
//export const reportUrl = 'http://93.115.144.14:5488/api/report';//vila.jahanArra
//#endregion

//#region for login to get token
export const authUser = "ebztmlkjckl";
export const authPass = "dg@!%yellow";
//#endregion
