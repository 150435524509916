import * as ActionTypes from '../ActionTypes';

export const CompanyInfo = (state = {
    isLoading: true,
    errMess: null,
    companyInfo: null
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_COMPINFO:
            return { ...state, isLoading: false, errMess: null, companyInfo: action.payload };

        case ActionTypes.COMPINFO_LOADING:
            return { ...state, isLoading: true, errMess: null, companyInfo: null }

        case ActionTypes.COMPINFO_FAILED:
            return { ...state, isLoading: false, errMess: action.payload, companyInfo: null };

        default:
            return state;
    }
};