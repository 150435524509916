import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { fetchCashPerm, fetchBranches } from "../../redux/ActionCreators";
import { PersianCalender } from "../persianCalender";
import { Loading } from "../LoadingComponent";
import { PersianToday, defaultValueToday } from "../../shared/persianToday";
import { baseUrl } from "../../shared/appContact";
import { ErrorMsg } from "../../redux/ActionCreators";

function RenderLoading(loading) {
  if (loading.loading) {
    return (
      <Row>
        <Col md={12}>
          <Loading></Loading>
        </Col>
      </Row>
    );
  } else return <></>;
}

function CashTurn(props) {
  const userInfo = useSelector((state) => state.userInfo);
  const cashInfo = useSelector((state) => state.cashInfo);
  const cashPerm = useSelector((state) => state.cashPerm);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    cashId: cashInfo.cashInfo === null ? -1 : cashInfo.cashInfo.cashId,
    cashTurn: cashInfo.cashInfo === null ? -1 : cashInfo.cashInfo.cashTurn,
    FarsiDate: PersianToday,
  });
  const [errmsg, setErrmsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };
  const handleSaveCashTurn = () => {
    if (data.cashId === -1) {
      setErrmsg("صندوق را وارد نمایید!");
      document.getElementById("cashId").focus();
      return;
    }

    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const newModel = {
        CashId: ~~data.cashId,
        UserId: ~~userInfo.userInfo.userId,
        CashTurn: ~~data.cashTurn,
        FarsiDate: data.FarsiDate,
      };
      fetch(baseUrl + "Cash/SaveCashTurn", {
        method: "POST",
        body: JSON.stringify(newModel),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 204) {
            setErrmsg(response.message);
            setLoading(false);
            return;
          }
          dispatch(fetchCashPerm(userInfo.userInfo.userId));
          dispatch(fetchBranches(userInfo.userInfo.userId));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          var k = ErrorMsg(error.message.replace("Error ", ""));
          return alert(k);
        });
    }
  };

  return (
    <div className="fgnonmb">
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/8">مالی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>نوبت صندوق</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid={true}>
        <Card>
          <CardHeader>
            <small>فرم </small>
            <strong>نوبت صندوق</strong>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Alert color="danger" className={errmsg !== "" ? "" : "hidden"}>
              {errmsg}
            </Alert>
            <Row className="text-right">
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="Name">کاربر</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    id="username"
                    name="userame"
                    value={userInfo.userInfo.userName}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="cashTurnID ">آخرین نوبت</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    id="cashTurn"
                    name="cashTurn"
                    value={data.cashTurn}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="cashId">صندوق مجاز</Label>
                  <Input
                    type="select"
                    id="cashId"
                    name="cashId"
                    value={data.cashId}
                    onChange={handleInputChange}
                  >
                    <option value="-1">--انتخاب نمایید--</option>
                    {cashPerm.cashPerm.map((cash) => {
                      return (
                        <option key={cash.id} value={cash.id}>
                          {cash.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="cashDate">تاریخ</Label>
                  <PersianCalender
                    defaultValue={defaultValueToday}
                    onChange={(FromFarsiDate) => {
                      setData({ ...data, FarsiDate: FromFarsiDate });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <Button
                  color="primary"
                  className="btn btn-info m-3"
                  onClick={() => {
                    handleSaveCashTurn();
                  }}
                >
                  {data.cashTurn === -1 ? "جدید" : "پایان"}
                </Button>
                <Link
                  className="btn btn-info m-3"
                  to="/9/saleInvoice"
                  onClick={() => props.selectedFormP("9/saleInvoice")}
                >
                  فاکتور فروش
                </Link>
              </Col>

              <RenderLoading loading={loading} />
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
export default CashTurn;
