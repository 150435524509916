import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  Input,
  Label,
  FormGroup,
  Alert,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { PersianCalender } from "../persianCalender";
import SelectUser from "../select/SelectUser";
import SelectCustomer from "../select/SelectCustomer";
import { baseUrl } from "../../shared/appContact";
import { Loading } from "../LoadingComponent";
import { ErrorMsg } from "../../redux/ActionCreators";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
function Miulert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}

function Search(props) {
  const [saleInvoiceSrchRes, setSaleInvoiceSrchRes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(1);
  const [showFilterList, setShowFilterList] = useState([]);
  //const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isOpen, setIsOpen] = useState({ isOpen: false, name: "" });
  const [cancelFilter, setCancelFilter] = useState(false);
  const [defaultValuePerCal] = useState(null);
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [filterList, setFilterList] = useState({
    fromId: -1,
    toId: -1,
    fromCode: "",
    toCode: "",
    fromFarsiDate: "",
    toFarsiDate: "",
    creatorId: -1,
    customerId: -1,
    pricingMethodId: -1,
    notes: "",
    sumQty: -1,
    rowdiscountValue: -1,
    discountPercent: -1,
    discountValue: -1,
    fromTotalValue: -1,
    toTotalValue: -1,
    fromCalcTotalValue: -1,
    toCalcTotalValue: -1,
    preConditionId: -1,
    isTemprory: -1,
  });

  //Fetch Data
  const handleSaleInvoiceSearch = useCallback(
    (pageNumber) => {
      const token = localStorage.token;
      if (token) {
        setIsLoading(true);
        setSaleInvoiceSrchRes([]);
        setPageNumber(pageNumber);

        const newModel = {
          PageNumber: ~~pageNumber,
          PageSize: ~~pageSize,
          IsDefault: showFilterList.length === 0 ? true : false,
          UserId: ~~props.userInfo.userId,
          Filters: {
            fromId: ~~filterList.fromId,
            toId: ~~filterList.toId,
            fromCode: filterList.fromCode === -1 ? "" : filterList.fromCode,
            toCode: filterList.toCode === -1 ? "" : filterList.toCode,
            fromFarsiDate:
              filterList.fromFarsiDate === -1 ? "" : filterList.fromFarsiDate,
            toFarsiDate:
              filterList.toFarsiDate === -1 ? "" : filterList.toFarsiDate,
            creatorId: ~~filterList.creatorId,
            customerId: ~~filterList.customerId,
            pricingMethodId: ~~filterList.pricingMethodId,
            notes: filterList.notes === -1 ? "" : filterList.notes,
            sumQty: ~~filterList.sumQty,
            rowdiscountValue: -1,
            discountPercent: ~~filterList.discountPercent,
            discountValue: ~~filterList.discountValue,
            fromTotalValue: ~~filterList.fromTotalValue,
            toTotalValue: ~~filterList.toTotalValue,
            fromCalcTotalValue: ~~filterList.fromCalcTotalValue,
            toCalcTotalValue: -1,
            preConditionId: -1,
            isTemprory:
              filterList.isTemprory === -1
                ? null
                : filterList.isTemprory === "true"
                ? true
                : false,
          },
        };
        console.log(JSON.stringify(newModel));
        axios
          .post(baseUrl + "Sale/SaleInvoiceSearch", newModel, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            credentials: "same-origin",
          })
          .then((response) => {
            if (response.data.length > 0) {
              setTotalRows(response.data[0].totalRows);
              setSaleInvoiceSrchRes(response.data);
            }

            //Scroll to Top Page
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            var errorMsg = ErrorMsg(error.message.replace("Error ", ""));
            setNotify({
              color: "warning",
              hideDuratuion: 10000,
              msg: errorMsg,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
            return;
          });

        setIsOpen({
          isOpen: false,
          name: "",
        });
      }
    },
    [
      filterList.creatorId,
      filterList.customerId,
      filterList.discountPercent,
      filterList.discountValue,
      filterList.fromCalcTotalValue,
      filterList.fromCode,
      filterList.fromFarsiDate,
      filterList.fromId,
      filterList.fromTotalValue,
      filterList.isTemprory,
      filterList.notes,
      filterList.pricingMethodId,
      filterList.sumQty,
      filterList.toCode,
      filterList.toFarsiDate,
      filterList.toId,
      filterList.toTotalValue,
      showFilterList.length,
      pageSize,
      props.userInfo.userId,
    ]
  );

  const isFirstRun = useRef(true);
  const lastpagesize = useRef({
    pageSize,
  });
  useEffect(() => {
    //fetch data for first load
    if (isFirstRun.current) {
      isFirstRun.current = false;
      handleSaleInvoiceSearch(pageNumber);
      return;
    }
    if (cancelFilter === true) {
      handleSaleInvoiceSearch(pageNumber);
      setCancelFilter(false);
    }
    if (lastpagesize.current.pageSize !== pageSize) {
      handleSaleInvoiceSearch(pageNumber);
      lastpagesize.current.pageSize = pageSize;
    }
  }, [cancelFilter, pageSize, pageNumber, handleSaleInvoiceSearch]);

  const [visible] = useState(true);
  const onDismiss = (item) => {
    var name = item.split("|")[1];

    setFilterList((prevState) => ({
      ...prevState,
      [name]: -1,
    }));
    setCancelFilter(true);

    var rep = showFilterList.filter((e) => !e.includes(name));

    setShowFilterList(rep);
  };
  const changePageSize = (event) => {
    setPageSize(event.target.value);
  };
  //Open and Close FilterBox
  const toggle = (name) =>
    setIsOpen({
      isOpen: isOpen.name === name ? !isOpen.isOpen : true,
      name: name,
    });

  //handle Change FilterList Search
  const handleInputChange = (enName, enValue, faName, faValue) => {
    setFilterList((prevState) => ({
      ...prevState,
      [enName]: enValue,
    }));

    var rep = showFilterList.filter((e) => !e.includes(enName));
    var newFilter = faName + "=" + faValue + "|" + enName;
    setShowFilterList([...rep, newFilter]);
  };

  //for selected or unselected All
  // const handleSelectedAll = (event) => {
  //     var target = event.target;
  //     if (target.checked) {
  //         var val = [];
  //         saleInvoiceSrchRes.forEach(item => {
  //             val.push(item.id);
  //         });
  //         setSelectedRowIds(val);

  //     }
  //     else {
  //         setSelectedRowIds([]);
  //     }
  // }

  //excel
  // function handleReport() {
  //     setNotify({
  //         color: "info",
  //         hideDuratuion: 10000,
  //         msg: "لطفا منتظر بمانید...",
  //         open: true,
  //         vertical: 'top',
  //         horizontal: 'center'
  //     })
  //     var newModel = {
  //         template: { name: "/hamyaranReport/saleInvoice/sale-invoice-search" },
  //         data: {
  //             detail: saleInvoiceSrchRes
  //         }
  //     }
  //     fetch(reportUrl, {
  //         method: 'POST',
  //         body: JSON.stringify(newModel),
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         credentials: 'same-origin'
  //     }).then(response => {

  //         if (response.ok) {
  //             return response;
  //         }

  //         else {
  //             var error = new Error('Error ' + response.status + ': ' + response.statusText);
  //             error.response = response;
  //             throw error;
  //         }
  //     },
  //         error => {
  //             var errmess = new Error(error.message);
  //             throw errmess;
  //         })
  //         .then(response => response.blob())
  //         .then(blob => {
  //             var url = window.URL.createObjectURL(blob);
  //             var a = document.createElement('a');
  //             a.href = url;
  //             a.download = "filename.xlsx";
  //             document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //             a.click();
  //             a.remove();  //afterwards we remove the element again
  //         })
  //         .catch(error => {
  //             var errMsg = ErrorMsg(error.message.replace("Error ", ""));
  //             setNotify({
  //                 color: "error",
  //                 hideDuratuion: 10000,
  //                 msg: errMsg,
  //                 open: true,
  //                 vertical: 'top',
  //                 horizontal: 'center'
  //             })
  //             return;
  //         });
  // }

  // pdf
  // function handleReport1() {
  //     setNotify({
  //         color: "info",
  //         hideDuratuion: 10000,
  //         msg: "لطفا منتظر بمانید...",
  //         open: true,
  //         vertical: 'top',
  //         horizontal: 'center'
  //     })
  //     var data = {
  //         number: "123",
  //         seller: {
  //             "name": "Next Step Webs, Inc.",
  //             "road": "12345 Sunny Road",
  //             "country": "Sunnyville, TX 12345"
  //         },
  //         buyer: {
  //             "name": "Acme Corp.",
  //             "road": "16 Johnson Road",
  //             "country": "Paris, France 8060"
  //         },
  //         items: [{
  //             "name": "Website design",
  //             "price": 300
  //         }, {
  //             "name": "Website design",
  //             "price": 300
  //         }, {
  //             "name": "Website design",
  //             "price": 300
  //         }]
  //     }
  //     var newModel = {
  //         template: {
  //             name: "/samples/Invoice/invoice-main"
  //         },
  //         data: data,
  //         staticPdf: {
  //             rawContent: "<...base64 PDF...>",
  //         },
  //         language: "en"
  //     }

  //     fetch(reportUrl, {
  //         method: 'POST',
  //         body: JSON.stringify(newModel),
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         credentials: 'same-origin'
  //     }).then(response => {

  //         if (response.ok) {
  //             return response;
  //         }

  //         else {
  //             var error = new Error('Error ' + response.status + ': ' + response.statusText);
  //             error.response = response;
  //             throw error;
  //         }
  //     },
  //         error => {
  //             var errmess = new Error(error.message);
  //             throw errmess;
  //         })
  //         .then(response => response.blob())
  //         .then(blob => {
  //             var url = window.URL.createObjectURL(blob);
  //             var a = document.createElement('a');
  //             a.href = url;
  //             a.download = "filename.pdf";
  //             document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  //             a.click();
  //             a.remove();  //afterwards we remove the element again
  //         })
  //         .catch(error => {
  //             var errMsg = ErrorMsg(error.message.replace("Error ", ""));
  //             setNotify({
  //                 color: "error",
  //                 hideDuratuion: 10000,
  //                 msg: errMsg,
  //                 open: true,
  //                 vertical: 'top',
  //                 horizontal: 'center'
  //             })
  //             return;
  //         });

  // }

  return (
    <div className="fgnonmb">
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <Miulert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </Miulert>
      </Snackbar>
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/9">بازرگانی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>جستجوی فاکتور فروش</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid={true}>
        <Card>
          <CardHeader>
            <small>فرم </small>
            <strong> جستجوی فاکتور فروش</strong>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            {/* <Row className='text-right'>
                            <Col md={3}>
                                <button className='btn btn-info' onClick={() => { handleReport() }}><i className='fa fa-file-excel-o '></i></button>
                            </Col>
                        </Row> */}
            <Row>
              {showFilterList.map((item, index) => {
                return (
                  <Col key={index} md={3}>
                    <div>
                      <Alert
                        color="primary"
                        isOpen={visible}
                        toggle={() => onDismiss(item)}
                        fade="false"
                      >
                        {item.split("|")[0]}
                      </Alert>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col md={12} className="table-responsive min-h-500">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th className="text-right">
                        {/* <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" name="all" className='form-check-input-table' onClick={(event) => { handleSelectedAll(event) }} />{' '}
                                                    </Label>
                                                </FormGroup> */}
                      </th>
                      <th>ردیف</th>
                      <th>
                        وضعیت
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("isTemprory")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "isTemprory"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="select"
                            name="isTemprory"
                            id="isTemprory"
                            title="وضعیت"
                            value={filterList.isTemprory}
                            onChange={(e) => {
                              var val = e.target.value.split("|");
                              var enVal = val[0];
                              var faVal = val[1];
                              handleInputChange(
                                "isTemprory",
                                enVal,
                                "وضعیت",
                                faVal
                              );
                            }}
                          >
                            <option value="-1">همه</option>
                            <option value="true|ثبت موقت">ثبت موقت</option>
                            <option value="false|ثبت نهایی">ثبت نهایی</option>
                          </Input>
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        شماره رایانه ای
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("id")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "id"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="text"
                            name="fromId"
                            id="fromId"
                            title="از شماره رایانه ای"
                            value={filterList.fromId}
                            onChange={(e) =>
                              handleInputChange(
                                "fromId",
                                e.target.value,
                                "از شماره رایانه ای",
                                e.target.value
                              )
                            }
                          ></Input>
                          تا
                          <Input
                            bsSize="sm"
                            type="text"
                            name="toId"
                            id="toId"
                            title=" تا شماره رایانه ای"
                            value={filterList.toId}
                            onChange={(e) =>
                              handleInputChange(
                                "toId",
                                e.target.value,
                                "تا شماره رایانه ای",
                                e.target.value
                              )
                            }
                          ></Input>
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        شماره تایید شده
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("code")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "code"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="text"
                            name="fromCode"
                            id="fromCode"
                            title="از شماره تایید شده"
                            value={filterList.fromCode}
                            onChange={(e) =>
                              handleInputChange(
                                "fromCode",
                                e.target.value,
                                "از شماره تایید شده",
                                e.target.value
                              )
                            }
                          ></Input>
                          تا
                          <Input
                            bsSize="sm"
                            type="text"
                            name="toCode"
                            id="toCode"
                            title="تا شماره تایید شده"
                            value={filterList.toCode}
                            onChange={(e) =>
                              handleInputChange(
                                "toCode",
                                e.target.value,
                                "تا شماره تایید شده",
                                e.target.value
                              )
                            }
                          ></Input>
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        تاریخ
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("date")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "date"}
                          className="filter-box"
                        >
                          <PersianCalender
                            defaultValue={defaultValuePerCal}
                            onChange={(FromFarsiDate) =>
                              handleInputChange(
                                "fromFarsiDate",
                                FromFarsiDate,
                                "از تاریخ",
                                FromFarsiDate
                              )
                            }
                          />
                          تا
                          <PersianCalender
                            defaultValue={defaultValuePerCal}
                            onChange={(ToFarsiDate) =>
                              handleInputChange(
                                "toFarsiDate",
                                ToFarsiDate,
                                "از تاریخ",
                                ToFarsiDate
                              )
                            }
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        تهیه کننده
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("user")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "user"}
                          className="filter-box"
                        >
                          <SelectUser
                            value={{ value: filterList.creatorId }}
                            onChange={(CreatorId, CreatorUser) => {
                              handleInputChange(
                                "creatorId",
                                CreatorId,
                                "تهیه کننده",
                                CreatorUser
                              );
                            }}
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        مشتری
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("customer")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "customer"}
                          className="filter-box"
                        >
                          <SelectCustomer
                            value={{ value: filterList.customerId }}
                            onChange={(CustomerId, CustomerUser) => {
                              handleInputChange(
                                "customerId",
                                CustomerId,
                                "مشتری",
                                CustomerUser
                              );
                            }}
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        نوع قیمت گذاری
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("pricingM")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "pricingM"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="select"
                            name="pricingMethodId"
                            id="pricingMethodId"
                            value={filterList.pricingMethodId}
                            onChange={(e) => {
                              var val = e.target.value.split("|");
                              var enVal = val[0];
                              var faVal = val[1];
                              handleInputChange(
                                "pricingMethodId",
                                enVal,
                                "نوع قیمت گذاری",
                                faVal
                              );
                            }}
                          >
                            <option value="-1">همه</option>
                            {props.pricingmethod.map((m, index) => {
                              return (
                                <option value={m.id | m.name} key={index}>
                                  {m.name}
                                </option>
                              );
                            })}
                          </Input>
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        جمع کل فاکتور
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("totalVal")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "totalVal"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="text"
                            name="fromTotalValue"
                            id="fromTotalValue"
                            value={filterList.fromTotalValue}
                            onChange={(e) =>
                              handleInputChange(
                                "fromTotalValue",
                                e.target.value,
                                "جمع فاکتور از ",
                                e.target.value
                              )
                            }
                          />
                          تا
                          <Input
                            bsSize="sm"
                            type="text"
                            name="toTotalValue"
                            id="toTotalValue"
                            value={filterList.toTotalValue}
                            onChange={(e) =>
                              handleInputChange(
                                "toTotalValue",
                                e.target.value,
                                "جمع فاکتور تا",
                                e.target.value
                              )
                            }
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        تعداد کل
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("sumQty")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "sumQty"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="number"
                            name="sumQty"
                            id="sumQty"
                            value={filterList.sumQty}
                            onChange={(e) =>
                              handleInputChange(
                                "sumQty",
                                e.target.value,
                                "تعداد کل",
                                e.target.value
                              )
                            }
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        درصد تخفیف کلی
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("DiscountPer")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={
                            isOpen.isOpen && isOpen.name === "DiscountPer"
                          }
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="number"
                            name="discountPercent"
                            id="discountPercent"
                            value={filterList.discountPercent}
                            onChange={(e) =>
                              handleInputChange(
                                "discountPercent",
                                e.target.value,
                                "درصد تخفیف کلی",
                                e.target.value
                              )
                            }
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>
                        {" "}
                        مبلغ تخفیف کلی
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("DiscountVal")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={
                            isOpen.isOpen && isOpen.name === "DiscountVal"
                          }
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="number"
                            name="discountValue"
                            id="discountValue"
                            value={filterList.discountValue}
                            onChange={(e) =>
                              handleInputChange(
                                "discountValue",
                                e.target.value,
                                "مبلغ تخفیف کلی",
                                e.target.value
                              )
                            }
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                      <th>بازاریاب</th>
                      <th>
                        ملاحظات
                        <div className="pull-right pointer">
                          <span onClick={() => toggle("notes")}>
                            <i className="fa fa-filter "></i>{" "}
                          </span>
                        </div>
                        <Collapse
                          isOpen={isOpen.isOpen && isOpen.name === "notes"}
                          className="filter-box"
                        >
                          <Input
                            bsSize="sm"
                            type="text"
                            name="notes"
                            id="notes"
                            value={filterList.notes}
                            onChange={(e) =>
                              handleInputChange(
                                "notes",
                                e.target.value,
                                "ملاحضات",
                                e.target.value
                              )
                            }
                          />
                          <div
                            className="btn btn-primary w-100 mt-3 mb-3"
                            onClick={() => handleSaleInvoiceSearch(1)}
                          >
                            تایید
                          </div>
                        </Collapse>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {saleInvoiceSrchRes.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">
                            {/* <FormGroup check className='pull-right'>
                                                                <Label check>
                                                                    <Input type="checkbox" name={"ch-" + item.id} className="form-check-input-table"
                                                                        onChange={(event) => { handleSelectedRows(item.id, event) }}
                                                                        checked={selectedRowIds.filter(m => m === item.id).length > 0} />

                                                                </Label>
                                                            </FormGroup> */}
                            <Link
                              id="btnCashTurn"
                              className="me-2"
                              to="/9/saleInvoice"
                              onClick={() => {
                                localStorage.setItem("saleInvoiceId", item.id);
                                props.selectedFormP("9/saleInvoice");
                              }}
                            >
                              <i className="fa fa-pencil-square-o text-warning pointer"></i>
                            </Link>
                          </th>
                          <td>{index + 1}</td>
                          <td>{item.isTemprory ? "موقت" : "نهایی"}</td>
                          <td>{item.id}</td>
                          <td>{item.code}</td>
                          <td>{item.farsiCreateDate}</td>
                          <td>{item.creatorName}</td>
                          <td className="mw-200">{item.customerName}</td>
                          <td>{item.pricingGroup}</td>
                          <td>{item.calcTotalValue}</td>
                          <td>{item.sumQty}</td>
                          <td>{item.discountPercent}</td>
                          <td>{item.discountValue}</td>
                          <td>{item.promoterName}</td>
                          <td>{item.notes}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <RenderLoading isLoading={isLoading} />
              <Col md={5} className={props.isLoading === true ? "hidden" : ""}>
                <div className="pull-right">
                  <Form inline>
                    <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                      <Label className="ms-sm-2">تعداد رکورد های هر صفحه</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={pageSize}
                        onChange={changePageSize}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
              <Col md={7} className={props.isLoading === true ? "hidden" : ""}>
                <div className="pull-right  pe-5">
                  نمایش صفحه ی {pageNumber} از مجموع{" "}
                  {Math.floor(totalRows / pageSize)} صفحه
                </div>

                <div className="pull-left">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={pageNumber === 1 ? true : false}>
                      <PaginationLink
                        onClick={() => handleSaleInvoiceSearch(1)}
                      >
                        اولین
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={pageNumber === 1 ? true : false}>
                      <PaginationLink
                        previous
                        onClick={() => handleSaleInvoiceSearch(pageNumber - 1)}
                      >
                        قبلی
                      </PaginationLink>
                    </PaginationItem>

                    <div className={pageNumber < 3 ? "hidden" : ""}>
                      <PaginationItem
                        className={pageNumber < 3 ? "hidden" : ""}
                      >
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() =>
                            handleSaleInvoiceSearch(pageNumber - 2)
                          }
                        >
                          {pageNumber - 2}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div className={pageNumber < 2 ? "hidden" : ""}>
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() =>
                            handleSaleInvoiceSearch(pageNumber - 1)
                          }
                        >
                          {pageNumber - 1}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <PaginationItem active>
                      <PaginationLink>{pageNumber}</PaginationLink>
                    </PaginationItem>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 1
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem
                        disabled={
                          Math.floor(totalRows / pageSize) < pageNumber + 1
                        }
                      >
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() =>
                            handleSaleInvoiceSearch(pageNumber + 1)
                          }
                        >
                          {pageNumber + 1}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 2
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() =>
                            handleSaleInvoiceSearch(pageNumber + 2)
                          }
                        >
                          {pageNumber + 2}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 3
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() =>
                            handleSaleInvoiceSearch(pageNumber + 3)
                          }
                        >
                          {pageNumber + 3}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 4
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() =>
                            handleSaleInvoiceSearch(pageNumber + 4)
                          }
                        >
                          {pageNumber + 4}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <PaginationItem
                      disabled={
                        Math.floor(totalRows / pageSize) < pageNumber + 1
                      }
                    >
                      <PaginationLink
                        className="btn btn-link"
                        onClick={() => handleSaleInvoiceSearch(pageNumber + 1)}
                      >
                        بعدی
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      disabled={
                        Math.floor(totalRows / pageSize) === pageNumber
                          ? true
                          : false
                      }
                    >
                      <PaginationLink
                        className="btn btn-link"
                        last="true"
                        onClick={() =>
                          handleSaleInvoiceSearch(
                            Math.floor(totalRows / pageSize)
                          )
                        }
                      >
                        آخرین
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default Search;
