import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input,
  Label,
  CardBody,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

export default function rolepage(props) {
  // Modal open state

  return (
    <div>
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/1">اطلاعات پایه</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>انواع کالا</BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
}
