import * as ActionTypes from "../ActionTypes";

export const DefaultPoromterUser = (
  state = {
    isLoading: false,
    errMess: null,
    defaultPoromterUser: null,
  },
  action
) => {
  console.log({ action });
  switch (action.type) {
    case ActionTypes.ADD_DEFAULTPROMETERUSER:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        defaultPoromterUser: action.payload,
      };

    case ActionTypes.DEFAULTPROMETERUSER_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        defaultPoromterUser: null,
      };

    case ActionTypes.DEFAULTPROMETERUSER_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
