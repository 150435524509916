import React, { useState, useEffect, useRef, useCallback } from "react";
//import MapOrganization from 'mapOrganization'
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  Input,
  Label,
  FormGroup,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Loading } from "../LoadingComponent";
import { Link } from "react-router-dom";
import { baseUrl } from "../../shared/appContact";
import { ErrorMsg } from "../../redux/ActionCreators";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";

function Miulert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}

export default function EmploeeSearch() {
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [cancelFilter, setCancelFilter] = useState(false);
  const [empList, setEmpList] = useState([]);
  const isFirstRun = useRef(true);
  const lastpagesize = useRef({
    pageSize,
  });
  const handleEmpSearch = useCallback(
    (pageNumber) => {
      const token = localStorage.token;
      if (token) {
        setIsLoading(true);
        setEmpList([]);
        setPageNumber(pageNumber);
        var newModel = {
          pageSize: ~~pageSize,
          pageNumber: pageNumber,
          isDefault: filter ? false : true,
          srcval: filter,
        };
        newModel = JSON.stringify(newModel);

        axios({
          method: "post",
          url: baseUrl + "Basic/GetEmploee",
          data: newModel,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((customers) => {
            setIsLoading(false);
            if (customers.data.length > 0) {
              setTotalRows(customers.data[0].totalRows);
              setEmpList(customers.data);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            var errorMsg = ErrorMsg(
              error.message.replace(
                "Request failed with status code 404",
                "404"
              )
            );
            setNotify({
              color: "warning",
              hideDuratuion: 3000,
              msg: errorMsg,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          });
      }
    },
    [filter, pageSize]
  );

  useEffect(() => {
    //fetch data for first load
    if (isFirstRun.current) {
      isFirstRun.current = false;
      handleEmpSearch(pageNumber);
      return;
    }
    if (cancelFilter) {
      handleEmpSearch(pageNumber);
      setCancelFilter(false);
    }
    if (lastpagesize.current.pageSize !== pageSize) {
      handleEmpSearch(pageNumber);
      lastpagesize.current.pageSize = pageSize;
    }
  }, [cancelFilter, pageSize, pageNumber, handleEmpSearch]);
  const changePageSize = (event) => {
    setPageSize(event.target.value);
  };
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleInputChange = (event) => {
    var value = event.target.value;
    setFilter(value);

    if (!value) {
      setCancelFilter(true);
    }
    handleEmpSearch(1);
  };

  return (
    <div className="fgnonmb">
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <Miulert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </Miulert>
      </Snackbar>
      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/1">اطلاعات پایه</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>جستجو افراد و سازمان ها</BreadcrumbItem>
      </Breadcrumb>
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <small>فرم </small>
            <strong> جستجو افراد </strong>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Row>
              <Col md={1} className="text-left">
                <Label>جستجو:</Label>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Input
                    bsSize="sm"
                    name="filter"
                    id="filter"
                    value={filter}
                    onChange={handleInputChange}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="table-responsive min-h-500 mt-3">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>ردیف</th>
                      <th>کد</th>
                      <th>نام و نام خانوادگی</th>
                      <th>تلفن همراه</th>
                      <th>آدرس</th>
                      <th>عملیات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empList.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                          <td>{item.cellPhone}</td>
                          <td>{item.address}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <RenderLoading isLoading={isLoading} />
              <Col md={5}>
                <div className="pull-right">
                  <Form inline>
                    <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                      <Label className="ms-sm-2">تعداد رکورد های هر صفحه</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={pageSize}
                        onChange={changePageSize}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </Col>
              <Col md={7}>
                <div className="pull-right  pe-5">
                  نمایش صفحه ی {pageNumber} از مجموع{" "}
                  {Math.floor(totalRows / pageSize)} صفحه
                </div>

                <div className="pull-left">
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem disabled={pageNumber === 1 ? true : false}>
                      <PaginationLink onClick={() => handleEmpSearch(1)}>
                        اولین
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={pageNumber === 1 ? true : false}>
                      <PaginationLink
                        previous
                        onClick={() => handleEmpSearch(pageNumber - 1)}
                      >
                        قبلی
                      </PaginationLink>
                    </PaginationItem>

                    <div className={pageNumber < 3 ? "hidden" : ""}>
                      <PaginationItem
                        className={pageNumber < 3 ? "hidden" : ""}
                      >
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleEmpSearch(pageNumber - 2)}
                        >
                          {pageNumber - 2}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div className={pageNumber < 2 ? "hidden" : ""}>
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleEmpSearch(pageNumber - 1)}
                        >
                          {pageNumber - 1}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <PaginationItem active>
                      <PaginationLink>{pageNumber}</PaginationLink>
                    </PaginationItem>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 1
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem
                        disabled={
                          Math.floor(totalRows / pageSize) < pageNumber + 1
                        }
                      >
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleEmpSearch(pageNumber + 1)}
                        >
                          {pageNumber + 1}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 2
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleEmpSearch(pageNumber + 2)}
                        >
                          {pageNumber + 2}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 3
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleEmpSearch(pageNumber + 3)}
                        >
                          {pageNumber + 3}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <div
                      className={
                        Math.floor(totalRows / pageSize) < pageNumber + 4
                          ? "hidden"
                          : ""
                      }
                    >
                      <PaginationItem>
                        <PaginationLink
                          className="btn btn-link"
                          onClick={() => handleEmpSearch(pageNumber + 4)}
                        >
                          {pageNumber + 4}
                        </PaginationLink>
                      </PaginationItem>
                    </div>
                    <PaginationItem
                      disabled={
                        Math.floor(totalRows / pageSize) < pageNumber + 1
                      }
                    >
                      <PaginationLink
                        className="btn btn-link"
                        onClick={() => handleEmpSearch(pageNumber + 1)}
                      >
                        بعدی
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      disabled={
                        Math.floor(totalRows / pageSize) === pageNumber
                          ? true
                          : false
                      }
                    >
                      <PaginationLink
                        className="btn btn-link"
                        last="true"
                        onClick={() =>
                          handleEmpSearch(Math.floor(totalRows / pageSize))
                        }
                      >
                        آخرین
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
