//دریافت توکن
export const ADD_AUTH = "ADD_AUTH";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOADING = "AUTH_LOADING";

//لاگین
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_LOADING = "LOGIN_LOADING";

//نقشهای کاربر
export const ADD_ROLES = "ADD_ROLES";
export const ROLES_FAILED = "ROLES_FAILED";
export const ROLES_LOADING = "ROLES_LOADING";

//سیستم ها و سطح دسترسی های کاربر
export const ADD_SYSTEMGROUPS = "ADD_SYSTEMGROUPS";
export const SYSTEMGROUPS_FAILED = "SYSTEMGROUPS_FAILED";
export const SYSTEMGROUPS_LOADING = "SYSTEMGROUPS_LOADING";

//انبارهای مجاز کاربر
export const ADD_INVENTORYUSER = "ADD_INVENTORYUSER";
export const INVENTORYUSER_FAILED = "INVENTORYUSER_FAILED";
export const INVENTORYUSER_LOADING = "INVENTORYUSER_LOADING";

//نوع قیمت گذاری
export const ADD_PRICINGMETHOD = "ADD_PRICINGMETHOD";
export const PRICINGMETHOD_FAILED = "PRICINGMETHOD_FAILED";
export const PRICINGMETHOD_LOADING = "PRICINGMETHOD_LOADING";

//َشرایط پرداخت
export const ADD_SALEPRECONDITION = "ADD_SALEPRECONDITION";
export const SALEPRECONDITION_FAILED = "SALEPRECONDITION_FAILED";
export const SALEPRECONDITION_LOADING = "SALEPRECONDITION_LOADING";

//لیست کالاها
export const ADD_GOODS = "ADD_GOODS";
export const GOODS_FAILED = "GOODS_FAILED";
export const GOODS_LOADING = "GOODS_LOADING";

//لیست مشتریان
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const CUSTOMERS_FAILED = "CUSTOMERS_FAILED";
export const CUSTOMERS_LOADING = "CUSTOMERS_LOADING";

//لیست کارمندان
export const ADD_PROMOTERS = "ADD_PROMOTERS";
export const PROMOTERS_FAILED = "PROMOTERS_FAILED";
export const PROMOTERS_LOADING = "PROMOTERS_LOADING";

//لیست شعب
export const ADD_BRANCHES = "ADD_BRANCHES";
export const BRANCHES_FAILED = "BRANCHES_FAILED";
export const BRANCHES_LOADING = "BRANCHES_LOADING";

//لیست گروههای کالا GoodTypes
export const ADD_GOODTYPES = "ADD_GOODTYPES";
export const GOODTYPES_FAILED = "GOODTYPES_FAILED";
export const GOODTYPES_LOADING = "GOODTYPES_LOADING";

//صندوق و حساب های مجاز کاربر
export const ADD_CASHPERM = "ADD_CASHPERM";
export const CASHPERM_FAILED = "CASHPERM_FAILED";
export const CASHPERM_LOADING = "CASHPERM_LOADING";

// اطلاعات صندوق
export const ADD_CASHINFO = "ADD_CASHINFO";
export const CASHINFO_FAILED = "CASHINFO_FAILED";
export const CASHINFO_LOADING = "CASHINFO_LOADING";

//اطلاعات کاربر
export const ADD_USERINFO = "ADD_USERINFO";
export const USERINFO_FAILED = "USERINFO_FAILED";
export const USERINFO_LOADING = "USERINFO_LOADING";

//مشتری پیش فرض
export const ADD_DEFAULTORGUSER = "ADD_DEFAULTORGUSER";
export const DEFAULTORGUSER_FAILED = "DEFAULTORGUSER_FAILED";
export const DEFAULTORGUSER_LOADING = "DEFAULTORGUSER_LOADING";

//بازاریاب پیش فرض
export const ADD_DEFAULTPROMETERUSER = "ADD_DEFAULTPROMETERUSER";
export const DEFAULTPROMETERUSER_FAILED = "DEFAULTPROMETERUSER_FAILED";
export const DEFAULTPROMETERUSER_LOADING = "DEFAULTPROMETERUSER_LOADING";

//default pay by cash or pay by none-cash
export const ADD_DEFAULT_IS_CASH = "ADD_DEFAULT_IS_CASH";

//default is formal
export const ADD_DEFAULT_IS_FORMAL = "ADD_DEFAULT_IS_FORMAL";

//نتیجه ی جستجوی فاکتورهای فروش
export const ADD_SALEINVOICESRCHRES = "ADD_SALEINVOICESRCHRES";
export const SALEINVOICESRCHRES_FAILED = "SALEINVOICESRCHRES_FAILED";
export const SALEINVOICESRCHRES_LOADING = "SALEINVOICESRCHRES_LOADING";

//اطلاعات شرکت
export const ADD_COMPINFO = "ADD_COMPINFO";
export const COMPINFO_FAILED = "COMPINFO_FAILED";
export const COMPINFO_LOADING = "COMPINFO_LOADING";

//تنظیمات
export const ADD_SYSTEMSETTING = "ADD_SYSTEMSETTING";
export const SYSTEMSETTING_FAILED = "SYSTEMSETTING_FAILED";
export const SYSTEMSETTING_LOADING = "SYSTEMSETTING_LOADING";

//نوع فاکتور فروش
export const ADD_INVOICETYPE = "ADD_INVOICETYPE";
export const INVOICETYPE_FAILED = "INVOICETYPE_FAILED";
export const INVOICETYPE_LOADING = "INVOICETYPE_LOADING";

//اطلاعات دستگاههای کارتخوان
export const ADD_POSES = "ADD_POSES";
export const POSES_FAILED = "POSES_FAILED";
export const POSES_LOADING = "POSES_LOADING";

//اطلاعات حساب
export const ADD_ACCOUNTS = "ADD_ACCOUNTS";
export const ACCOUNTS_FAILED = "ACCOUNTS_FAILED";
export const ACCOUNTS_LOADING = "ACCOUNTS_LOADING";

//اطلاعات لازم برای ثبت نام افراد و سازمانها و کارمندان
export const ADD_ORGINFO = "ADD_ORGINFO";
export const ORGINFO_FAILED = "ORGINFO_FAILED";
export const ORGINFO_LOADING = "ORGINFO_LOADING";

//خروج
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
