import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ActiveMenuContext } from "./activeMenuContext";
import { Container, Row } from "reactstrap";
import { Loading } from "./LoadingComponent";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
const SystemHome = () => {
  const [activeMenu] = useContext(ActiveMenuContext);

  var systemGroups = useSelector((state) => state.systemGroups);
  systemGroups = systemGroups.systemGroups.filter(
    (systemGId) => systemGId.id === activeMenu.systemGId
  )[0];

  const SubSystemList = systemGroups?.systems.map((m) => {
    return (
      <div key={m.id} className="col col-md-5 m-1">
        <Card>
          <CardBody>
            <CardTitle>{m.name}</CardTitle>

            <ListGroup>
              {m.rolePermissions.map((submenu) => {
                if (!submenu.isButton) {
                  return (
                    <ListGroupItem key={submenu.id}>
                      <span>{submenu.name} </span>
                    </ListGroupItem>
                  );
                } else {
                  return <></>;
                }
              })}
            </ListGroup>
          </CardBody>
        </Card>
      </div>
    );
  });
  if (systemGroups.isLoading) {
    return (
      <div className="continer">
        <div className="row">
          <Loading></Loading>
        </div>
      </div>
    );
  } else if (systemGroups.errMess) {
    return (
      <div className="continer">
        <div className="row">
          <h4>{systemGroups.errMess}</h4>
        </div>
      </div>
    );
  } else
    return (
      <Container fluid={true}>
        <Row className="dir-rtl text-right">{SubSystemList}</Row>
      </Container>
    );
};
export default SystemHome;
