import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-modern-calendar-datepicker";

export const PersianCalender = (props) => {


    const [selectedDay, setSelectedDay] = useState(props.defaultValue);

    const minimumDate = props.minimumDate;

    const maximumDate = props.maximumDate;
    const setDay = (props, value) => {

        setSelectedDay(value);
        var newVal = value.year + "/" + (value.month < 10 ? "0" + value.month : value.month) + "/" + (value.day < 10 ? "0" + value.day : value.day)

        props.onChange(newVal);
    }
    const oldselectedDay = useRef(selectedDay);
    useEffect(() => {
        if (oldselectedDay.current !== props.defaultValue) {
            setSelectedDay(props.defaultValue)
            oldselectedDay.current = props.defaultValue;
        }
    }, [selectedDay, props.defaultValue]);

    return (
        <DatePicker
            value={selectedDay}
            onChange={(value) => {
                setDay(props, value)
            }}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            shouldHighlightWeekends
            locale="fa" // add this
        />
    );
};

