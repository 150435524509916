import React, { useState, useEffect, useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles({
    root: {
        // height: 110,
        flexGrow: 1,
        maxWidth: 400,
    },
});

function RecursiveTreeView(props) {

    const mainData = props.data;
    const [filter, setFilter] = useState("");
    const [dataSet, setDataSet] = useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [data, setData] = useState({
        id: 'root',
        name: 'نمایش درخت',
        children: props.data
    })
    const classes = useStyles();

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };
    //این کد فقط توی پرنت ها سرچ میکنه
    // function rgetroot1(arrayg, filter) {
    //     if (filter) {
    //         var x = [];
    //         var n1 = 0;
    //         n1 = arrayg.filter(item => item.name.includes(filter));
    //         

    //         for (var m = 0; m < n1.length; m++) {
    //             x.push(n1[m])
    //             if (n1[m].children.length > 0) {
    //                 rgetroot1(n1[m].children, filter)
    //             }

    //         }
    //         const res = {
    //             id: 'root',
    //             name: 'نمایش درخت',
    //             children: x
    //         }
    //         
    //         setData(res);

    //     }
    //     else {
    //         setData({
    //             id: 'root',
    //             name: 'نمایش درخت',
    //             children: props.data
    //         })
    //     }
    // }


    //این کد فقط توی آخرین بچه ها سرچ میکنه
    var x = [];
    const srchInChild = useCallback(
        (arrayg, filter) => {

            if (filter) {
                for (var m = 0; m < arrayg.length; m++) {

                    if (arrayg[m].children.length > 0) {
                        srchInChild(arrayg[m].children, filter)
                    }
                    else if (arrayg[m].name.includes(filter)) {
                        x.push(arrayg[m])
                    }
                }
                const res = {
                    id: 'root',
                    name: (x.length > 0 ? 'نمایش درخت' : 'هیچ موردی پیدا نشد'),
                    children: (x ? x : [])
                }


                setData(res);

            }
            else {
                setData({
                    id: 'root',
                    name: 'نمایش درخت',
                    children: props.data
                })
            }
        },
        [props.data, x],
    )

    const lastFilter = useRef(filter);


    useEffect(() => {
        if (lastFilter.current !== filter) {
            x = [];
            // debugger
            if (filter && !dataSet) {
                handleToggle(null, ['root'])
            }
            else if (filter && dataSet) {
                handleToggle(null, [])

            }
            else {
                handleToggle(null, [])
                props.selectedId(-1)
                setDataSet(false)
            }
            srchInChild(mainData, filter);
            lastFilter.current = filter;
        }
    }, [filter, x]);


    function handleChangeFilter(e) {
        setFilter(e.target.value);
    }

    function handleOnClick(node, children) {

        if (children.length === 0) {
            //alert(id)
            props.selectedId(node.id)
            setFilter(node.name)
            setDataSet(true);
            return true;
        }
        return true;
    }
    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={String(nodes.id)} label={nodes.name} onClick={() => handleOnClick(nodes, nodes.children)}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <TreeView
            className={classes.root}
            expanded={expanded}
            selected={selected}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}>
            <input className="form-control" id="search" value={filter} onChange={handleChangeFilter}></input>
            {
                renderTree(data)
            }
        </TreeView>
    );
}
export default React.memo(RecursiveTreeView);