import * as ActionTypes from '../ActionTypes';

export const Auth = (state = {
    isLoading: false,
    errMess: null,
    auth: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_AUTH:
            return { ...state, isLoading: false, errMess: null, auth: action.payload };

        case ActionTypes.AUTH_LOADING:
            return { ...state, isLoading: true, errMess: null, auth: [] }

        case ActionTypes.AUTH_FAILED:
            return { ...state, isLoading: false, errMess: action.payload, auth: [] };

        default:
            return state;
    }
} 