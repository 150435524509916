import React, { useState } from 'react';
import {
    Container, Button,
    Col, Row, TabContent, TabPane,
    Nav, NavItem, NavLink, Card, CardTitle, CardText,
} from 'reactstrap';
import classnames from 'classnames';
import SaleReturnRef from './SaleReturnRef';
function SaleReturn(props) {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <div className='fgnonmb'>


            <Container fluid={true} >
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            با عطف
                               </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            بدون عطف
                                </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <SaleReturnRef
                            userInfo={props.userInfo}
                            cashInfo={props.cashInfo}
                            formPerms={props.formPerms}
                            selectedFormP={props.selectedFormP}
                            oldCashTurn={props.oldCashTurn}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>


            </Container>
        </div>
    )
}
export default SaleReturn;