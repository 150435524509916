import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input,
  Label,
  CardBody,
  FormGroup,
} from "reactstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import { baseUrl } from "../../shared/appContact";
import { ErrorMsg, fetchGoodTypes } from "../../redux/ActionCreators";
import { PersianToday } from "../../shared/persianToday";
import { Loading } from "../LoadingComponent";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTable, usePagination, useSortBy } from "react-table";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function NotifyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function RenderLoading(props) {
  if (props.isLoading) {
    return (
      <Col md={12}>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <Loading></Loading>
          </Col>
        </Row>
      </Col>
    );
  } else return <></>;
}
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead className="thead-light">
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default function GoodTypes(props) {
  // Modal open state
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [isEdit, setEdit] = React.useState(false);
  const dispatch = useDispatch();
  //main
  const userInfo = useSelector((state) => state.userInfo);

  const data = React.useMemo(() => props.goodTypes, [props.goodTypes]);
  const [notify, setNotify] = useState({
    color: "success",
    hideDuratuion: 6000,
    msg: "",
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [showBaseInfo, setShowBaseInfo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [goodtyp, setGoodtyp] = useState({
    id: 0,
    name: "",
    tabletvisible: null,
  });
  const [goodtypbase, setGoodtypbase] = useState({
    id: 0,
    name: "",
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "شماره یارانه ای",
        accessor: "id",
      },
      {
        Header: "نوع کالا",
        accessor: "name",
      },
      {
        Header: "حذف",
        id: "delete",
        accessor: (str) => "id",
        Cell: (tableProps) => (
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => {
              const value = tableProps.row.original.id;
              const name = tableProps.row.original.name;

              setGoodtyp((state) => ({
                id: value,
                name: name,
              }));
              setEdit(false);
              toggle();
            }}
          >
            حذف
          </span>
        ),
      },
      {
        Header: "ویرایش",
        id: "edit",
        accessor: (str) => "id",
        Cell: (tableProps) => (
          <span
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => {
              const value = tableProps.row.original.id;
              const name = tableProps.row.original.name;

              setGoodtyp((state) => ({
                id: value,
                name: name,
              }));
              setGoodtypbase((state) => ({
                id: value,
                name: name,
              }));
              setEdit(true);
              toggle();
            }}
          >
            ویرایش
          </span>
        ),
      },
    ],
    []
  );
  const newgoodtyp = () => {
    setGoodtyp({
      id: 0,
      name: "",
    });
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setGoodtyp((state) => ({
      ...state,
      [name]: value,
    }));
  };
  function handleSavegoodtype() {
    var Errmsg = [""];
    if (goodtyp.name === "") {
      Errmsg.push(<p>نوع کالا را وارد نمایید!</p>);
    }
    if (goodtyp.id === "") {
      Errmsg.push(<p>شماره رایانه ای را وارد نمایید!</p>);
    }

    if (Errmsg.length > 1) {
      setNotify({
        color: "error",
        hideDuratuion: 9000,
        msg: Errmsg,
        open: true,
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const newModel = {
        Id: ~~goodtyp.id,
        Name: goodtyp.name,
        TabletVisible: null,
      };
      fetch(baseUrl + "Basic/SaveGoodType", {
        method: "POST",
        body: JSON.stringify(newModel),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )

        .then((response) => response.json())
        .then((response) => {
          if (response.message !== "") {
            setNotify({
              color: "error",
              hideDuratuion: 9000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //have user by this cellphone
          else {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات ثبت با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          setLoading(false);
          newgoodtyp();
          dispatch(fetchGoodTypes());
        })
        .catch((error) => {
          setLoading(false);

          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  function handleDeletegoodtype(id) {
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      fetch(baseUrl + "Basic/DeleteGoodType/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )
        .then((response) => response.json())
        .then((response) => {
          if (response.message !== "") {
            setNotify({
              color: "error",
              hideDuratuion: 9000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //have user by this cellphone
          else {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات حذف با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          setLoading(false);
          toggle();
          newgoodtyp();
          dispatch(fetchGoodTypes());
        })
        .catch((error) => {
          setLoading(false);

          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  function handleUpdategoodtype(id) {
    const token = localStorage.token;
    if (token) {
      setLoading(true);
      const newModel = {
        Id: ~~goodtyp.id,
        Name: goodtyp.name,
        TabletVisible: null,
      };
      fetch(baseUrl + "Basic/UpdateGoodType", {
        method: "POST",
        body: JSON.stringify({ id, newModel }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "same-origin",
      })
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )
        .then((response) => response.json())
        .then((response) => {
          if (response.message !== "") {
            setNotify({
              color: "error",
              hideDuratuion: 9000,
              msg: response.message,
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          //have user by this cellphone
          else {
            setNotify({
              color: "success",
              hideDuratuion: 9000,
              msg: "علیات حذف با موفقیت انجام شد",
              open: true,
              vertical: "top",
              horizontal: "center",
            });
          }
          setLoading(false);
          toggle();
          newgoodtyp();
          dispatch(fetchGoodTypes());
        })
        .catch((error) => {
          setLoading(false);

          var k = ErrorMsg(error.message.replace("Error ", ""));
          setNotify({
            color: "error",
            hideDuratuion: 9000,
            msg: k,
            open: true,
            vertical: "top",
            horizontal: "center",
          });
          return;
        });
    }
  }
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} modalTransition={{ timeout: 1000 }}>
        <ModalHeader toggle={toggle}>اصلاح و ویرایش</ModalHeader>
        <ModalBody>
          <row>
            {isEdit ? (
              <div>
                <Col md={4} sm={4} lg={4}>
                  <FormGroup>
                    <Label>نوع کالا *</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="name"
                      id="name"
                      valid={goodtyp.name !== ""}
                      invalid={goodtyp.name === ""}
                      value={goodtyp.name}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} sm={4} lg={4}>
                  <FormGroup>
                    <Label>شماره رایانه ای *</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="id"
                      id="id"
                      value={goodtyp.id}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </div>
            ) : (
              <div>
                <row>
                  <p>
                    آیا مطمین به حذف کالای <span>{goodtyp.name}</span> هستید؟
                  </p>
                </row>
              </div>
            )}
          </row>
        </ModalBody>
        <ModalFooter>
          {isEdit ? (
            <div>
              <Button
                color="success"
                className="m-3"
                onClick={() => handleUpdategoodtype(goodtypbase.id)}
              >
                اصلاح
              </Button>

              <Button color="info" className="m-3" onClick={toggle}>
                انصراف
              </Button>
            </div>
          ) : (
            <div>
              <Button
                color="success"
                className="m-3"
                onClick={() => handleDeletegoodtype(goodtyp.id)}
              >
                بله
              </Button>

              <Button color="info" className="m-3" onClick={toggle}>
                خیر
              </Button>
            </div>
          )}
        </ModalFooter>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: notify.vertical,
          horizontal: notify.horizontal,
        }}
        open={notify.open}
        autoHideDuration={notify.hideDuratuion}
        onClose={() =>
          setNotify((prevState) => ({ ...prevState, open: false }))
        }
      >
        <NotifyAlert
          onClose={() =>
            setNotify((prevState) => ({ ...prevState, open: false }))
          }
          severity={notify.color}
        >
          {notify.msg}
        </NotifyAlert>
      </Snackbar>

      <Breadcrumb>
        {/* if remove this empty BreadcrumbItem then the breadcrumb gonna miss a "/" between its items */}
        <BreadcrumbItem></BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/home">صفحه اصلی</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/1">اطلاعات پایه</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>انواع کالا</BreadcrumbItem>
      </Breadcrumb>

      <Container fluid={true}>
        <Card>
          <CardHeader>
            <strong>انواع کالا</strong>
            <Col md={4} sm={10} lg={2} className="order-8 order-lg-6"></Col>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowBaseInfo(!showBaseInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showBaseInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات اصلی
                </div>
              </Col>
            </Row>
            <Row className={showBaseInfo ? "" : "hidden"}>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تهیه کننده</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="creator"
                    id="creator"
                    value={userInfo.userInfo.userName}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>تاریخ</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="farsiCreatDate"
                    id="farsiCreatDate"
                    value={PersianToday}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>نام نوع کالا *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="name"
                    id="name"
                    valid={goodtyp.name !== ""}
                    invalid={goodtyp.name === ""}
                    value={goodtyp.name}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={10} lg={2}>
                <FormGroup>
                  <Label>شماره رایانه ای *</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    name="id"
                    id="id"
                    value={goodtyp.id}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Button
                color="success"
                className="m-3"
                onClick={handleSavegoodtype}
              >
                ثبت
              </Button>

              <Button color="info" className="m-3" onClick={newgoodtyp}>
                جدید
              </Button>
            </Row>
            <Row>
              <RenderLoading isLoading={loading} />
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <strong>مشاهده لیست انواع کالا</strong>
            <Col md={4} sm={10} lg={2} className="order-8 order-lg-6"></Col>
          </CardHeader>
          <CardBody className="dir-rtl text-right">
            <Row>
              <Col md={12} sm={12} lg={12} className="order-1">
                <div
                  onClick={() => setShowBaseInfo(!showBaseInfo)}
                  className="ui horizontal divider pointer "
                >
                  <span
                    className={
                      showBaseInfo
                        ? "fa fa-chevron-circle-down"
                        : "fa fa-chevron-circle-up"
                    }
                  ></span>{" "}
                  مشخصات اصلی
                </div>
              </Col>
            </Row>
            <Row className={showBaseInfo ? "" : "hidden"}>
              <Col md={8} sm={8} lg={8}>
                <CssBaseline />
                <Table columns={columns} data={data} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
