import { combineReducers } from "redux";

import { Roles } from "./roles";
import { SystemGroups } from "./systemGroups";
import { Login } from "./login";
import { InventoryUser } from "./inventoryUser";
import { Pricingmethod } from "./pricingmethod";
import { SalePrecondition } from "./salePrecondition";
import { Branches } from "./branches";
import { GoodTypes } from "./goodTypes";
import { CashInfo } from "./cashInfo";
import { CashPerm } from "./cashPerm";
import { UserInfo } from "./userInfo";
import { DefaultOrgUser } from "./defaultOrgUser";
import { DefaultPoromterUser } from "./defaultPoromterUser";
import { SaleInvoiceSrchRes } from "./saleInvoiceSrchRes";
import { CompanyInfo } from "./companyInfo";
import { SystemSetting } from "./systemSetting";
import { Auth } from "./auth";
import { InvoiceType } from "./invoiceType";
import { Poses } from "./poses";
import { Accounts } from "./accounts";
import { OrgInfo } from "./orgInfo";
import { DefaultIsFormal } from "./defaultIsFormal";
import { DefaultIsCash } from "./defaultIsCash";

export const rootReducer = combineReducers({
  auth: Auth,
  orgInfo: OrgInfo,
  accounts: Accounts,
  poses: Poses,
  invoiceType: InvoiceType,
  systemSetting: SystemSetting,
  saleInvoiceSrchRes: SaleInvoiceSrchRes,
  defaultPoromterUser: DefaultPoromterUser,
  defaultOrgUser: DefaultOrgUser,
  userInfo: UserInfo,
  cashPerm: CashPerm,
  cashInfo: CashInfo,
  goodTypes: GoodTypes,
  salePrecondition: SalePrecondition,
  pricingmethod: Pricingmethod,
  login: Login,
  roles: Roles,
  branches: Branches,
  systemGroups: SystemGroups,
  inventoryUser: InventoryUser,
  companyInfo: CompanyInfo,
  defaultIsFormal: DefaultIsFormal,
  defaultIsCash: DefaultIsCash,
});
