import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { baseUrl } from "../../shared/appContact";

const loadOptions = (inputValue, callback) => {
  const limit = isNaN(+inputValue) ? 3 : 7;
  if (inputValue.length > limit) {
    const token = localStorage.token;
    const typeId = localStorage.TypeId;
    if (token) {
      fetch(
        baseUrl +
        "Sale/GetObjects/" +
        ~~typeId +
        "/" +
        encodeURIComponent(inputValue),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "same-origin",
        }
      )
        .then(
          (response) => {
            if (response.ok) {
              return response;
            } else {
              var error = new Error(
                "Error " + response.status + ": " + response.statusText
              );
              error.response = response;
              throw error;
            }
          },
          (error) => {
            var errmess = new Error(error.message);
            throw errmess;
          }
        )
        .then((response) => response.json())
        .then((customers) => {
          callback(
            customers.map((m) => {
              return {
                value: m.id,
                label: m.name + " - " + m.cellPhone + " - " + m.address,
              };
            })
          );
        })
        .catch((error) => {
          callback([])

        });
    }
  }
};

class SelectObjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value.value : 0,
      label: this.props.value ? this.props.value.label : "",
    };
    this.gotoUser = this.gotoUser.bind(this);
  }
  state = { inputValue: "" };

  gotoUser(value) {
    this.setState({
      value: value.value,
      label: value.label,
    });
    this.props.onChange(value.value, value.label);
  }

  render() {
    return (
      <div>
        {/* <pre>inputValue: "{this.state.inputValue}"</pre> */}
        <AsyncSelect
          cacheOptions
          valueKey="value"
          labelKey="label"
          value={{ value: this.state.value, label: this.state.label }}
          loadOptions={loadOptions}
          onChange={this.gotoUser}
          onInputChange={this.props.onInputChange ? this.props.onInputChange : undefined}
          defaultOnchange={
            this.props.defaultOnchange
              ? this.props.defaultOnchange.value !== this.state.value
                ? this.gotoUser(this.props.defaultOnchange)
                : null
              : null
          }
          onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : undefined}
        />
      </div>
    );
  }
}
export default SelectObjects;
