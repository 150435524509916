import * as ActionTypes from '../ActionTypes';

export const DefaultOrgUser = (state = {
    isLoading: false,
    errMess: null,
    defaultOrgUser: null
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_DEFAULTORGUSER:
            return { ...state, isLoading: false, errMess: null, defaultOrgUser: action.payload };

        case ActionTypes.DEFAULTORGUSER_LOADING:
            return { ...state, isLoading: true, errMess: null, defaultOrgUser: null }

        case ActionTypes.DEFAULTORGUSER_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};