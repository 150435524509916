import React, { Component } from "react";
import { baseUrl } from "../../shared/appContact";
import AsyncSelect from "react-select/async";

const loadOptions = (inputValue, callback) => {
  const token = localStorage.token;
  if (token) {
    fetch(baseUrl + "Basic/GetPromoters/" + encodeURIComponent(inputValue), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "same-origin",
    })
      .then(
        (response) => {
          if (response.ok) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then((response) => response.json())
      .then((customers) => {
        callback(
          customers.map((m) => {
            return {
              value: m.id,
              label: m.code + " - " + m.firstName + " - " + m.sureName,
            };
          })
        );
      })
      .catch((error) => {
        console.log({ error });
      });
  }
};

class SelectPromoter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value ? this.props.value.value : 0,
      label: this.props.value ? this.props.value.label : "متفرقه",
    };
    this.gotoUser = this.gotoUser.bind(this);
  }
  state = { inputValue: "" };

  gotoUser(value) {
    this.setState({
      value: value.value,
      label: value.label,
    });
    this.props.onChange(value.value, value.label);
  }

  render() {
    return (
      <div>
        {/* <pre>Value:"{this.state.value} - Label:"{this.state.label} "</pre> */}
        <AsyncSelect
          cacheOptions
          valueKey="value"
          labelKey="label"
          value={{ value: this.state.value, label: this.state.label }}
          loadOptions={loadOptions}
          onChange={this.gotoUser}
          // onInputChange={this.handleInputChange}
          defaultOnchange={
            this.props.defaultOnchange
              ? this.props.defaultOnchange.value !== this.state.value
                ? this.gotoUser(this.props.defaultOnchange)
                : null
              : null
          }
        />
      </div>
    );
  }
}
export default SelectPromoter;
