import React, { Component } from 'react';
import { baseUrl } from '../../shared/appContact';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
const loadOptions = (inputValue, callback) => {
  const token = localStorage.token;
  if (token) {
    var newModel = {
      pageSize: 0,
      pageNumber: 0,
      isDefault: false,
      srcval: inputValue
    }
    newModel = JSON.stringify(newModel);

    axios({
      method: 'post',
      url: baseUrl + 'Basic/GetCustomers',
      data: newModel,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(customers => {
      // setIsLoading(false);
      callback(customers.data.map((m) => {
        return { "value": m.id, "label": (m.name + " - " + m.cellPhone + " - " + m.address) };
      }));
      // if (customers.data.length > 0) {
      //     setTotalRows(customers.data[0].totalRows)
      //     setOrgList(customers.data)
      // }
    })
      .catch(error => {
        //  
        //  setIsLoading(false);
        //  var errorMsg = ErrorMsg(error.message.replace("Request failed with status code 404", "404"))

      });
  };
  //   if (token) {
  //     fetch(baseUrl + 'Basic/GetCustomers/' + encodeURIComponent(inputValue), {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         'Authorization': `Bearer ${token}`
  //       },
  //       credentials: 'same-origin'
  //     })
  //       .then(response => {
  //         if (response.ok) {
  //           return response;
  //         } else {
  //           var error = new Error('Error ' + response.status + ': ' + response.statusText);
  //           error.response = response;
  //           throw error;
  //         }
  //       },
  //         error => {
  //           var errmess = new Error(error.message);
  //           throw errmess;
  //         })
  //       .then(response => response.json())
  //       .then(customers => {
  //         callback(customers.map((m) => {
  //           return { "value": m.id, "label": (m.name + " - " + m.cellPhone + " - " + m.address) };
  //         }));
  //       })
  //       .catch(error => {

  //       });

  //   };
}

class SelectCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value.value : 0,
      label: this.props.value ? this.props.value.label : '',
    };
    this.gotoUser = this.gotoUser.bind(this);
  }
  state = { inputValue: '' };


  gotoUser(value) {
    this.setState({
      value: value.value,
      label: value.label,
    });
    this.props.onChange(value.value, value.label);
  }



  render() {
    return (
      <div>
        {/* <pre>inputValue: "{this.state.inputValue}"</pre> */}
        <AsyncSelect
          cacheOptions
          valueKey="value"
          labelKey="label"
          value={{ value: this.state.value, label: this.state.label }}
          loadOptions={loadOptions}
          onChange={this.gotoUser}
          defaultOnchange={this.props.defaultOnchange ? (this.props.defaultOnchange.value !== this.state.value ? this.gotoUser(this.props.defaultOnchange) : null) : null}
        />
      </div>
    );
  }
}
export default SelectCustomer;