import * as ActionTypes from '../ActionTypes';

export const SaleInvoiceSrchRes = (state = {
    isLoading: false,
    errMess: null,
    saleInvoiceSrchRes: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_SALEINVOICESRCHRES:
            return { ...state, isLoading: false, errMess: null, saleInvoiceSrchRes: action.payload };

        case ActionTypes.SALEINVOICESRCHRES_LOADING:
            return { ...state, isLoading: true, errMess: null, saleInvoiceSrchRes: [] }

        case ActionTypes.SALEINVOICESRCHRES_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};