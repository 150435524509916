import * as ActionTypes from '../ActionTypes';

export const Poses = (state = {
    isLoading: true,
    errMess: null,
    poses: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_POSES:
            return { ...state, isLoading: false, errMess: null, poses: action.payload };

        case ActionTypes.POSES_LOADING:
            return { ...state, isLoading: true, errMess: null, poses: [] }

        case ActionTypes.POSES_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};