import * as ActionTypes from '../ActionTypes';

export const SystemSetting = (state = {
    isLoading: true,
    errMess: null,
    systemSetting: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_SYSTEMSETTING:
            return { ...state, isLoading: false, errMess: null, systemSetting: action.payload };

        case ActionTypes.SYSTEMSETTING_LOADING:
            return { ...state, isLoading: true, errMess: null, systemSetting: [] }

        case ActionTypes.SYSTEMSETTING_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};