function toEnglishDigits(str) {
    var e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
    });
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
    });
    return str;
}
function today() {
    const today = new Date().toLocaleDateString('fa-IR');
    const ddd = today.split("/");

    const defaultValue = {
        year: ~~toEnglishDigits(ddd[0]),
        month: ~~toEnglishDigits(ddd[1]),
        day: ~~toEnglishDigits(ddd[2]),
    };
    return defaultValue.year + "/" + (~~defaultValue.month < 10 ? "0" + defaultValue.month : defaultValue.month) + "/" + (~~defaultValue.day < 10 ? "0" + defaultValue.day : defaultValue.day);
}
function defaultValue() {
    const today = new Date().toLocaleDateString('fa-IR');
    const ddd = today.split("/");

    const defaultValue = {
        year: ~~toEnglishDigits(ddd[0]),
        month: ~~toEnglishDigits(ddd[1]),
        day: ~~toEnglishDigits(ddd[2]),
    };
    return defaultValue;
}

function DayName() {
    const days = ["يكشنبه", "دوشنبه", "سه شنبه", "چهارشنبه", "پنج شنبه", "جمعه", "شنبه"]
    const d = new Date();
    const dayName = days[d.getDay()];
    return dayName;
}
export const PersianDayName = DayName();
export const PersianToday = today();
export const defaultValueToday = defaultValue();