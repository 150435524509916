import React, { useState } from 'react'
//Active Menu
export const ActiveMenuContext = React.createContext();
export const ActiveMenuProvider = (props) => {
    const [activeMenu, setActiveMenu] = useState({
        systemGId: 0,
        systemId: null,
        rolePermId: null,
        formPermId: null
    });
    return (
        <ActiveMenuContext.Provider value={[activeMenu, setActiveMenu]}>
            {props.children}
        </ActiveMenuContext.Provider>
    )
} 