import * as ActionTypes from '../ActionTypes';

export const SalePrecondition = (state = {
    isLoading: true,
    errMess: null,
    salePrecondition: []
},
    action) => {
    switch (action.type) {
        case ActionTypes.ADD_SALEPRECONDITION:
            return { ...state, isLoading: false, errMess: null, salePrecondition: action.payload };

        case ActionTypes.SALEPRECONDITION_LOADING:
            return { ...state, isLoading: true, errMess: null, salePrecondition: [] }

        case ActionTypes.SALEPRECONDITION_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};